import React, { useEffect, useState, useContext } from 'react';
import { EmptyData } from '../../../../shared/components/EmptyData';
import { TicketTaskCard } from './TicketTaskCard';
import EmptySVG from '../../../../../svg/empty.svg';
import { platformService } from '../../../../shared/services/platform.service';
import { TicketTasksListSkeleton } from '../../../../shared/components/TicketTasks/Skeletons/TicketTasksSkeleton';
import { getUser } from '../../../../AuthConfig';
import { useHistory, useLocation } from 'react-router-dom';
import { FEATURE_NOT_AVAILABLE, LICENSE_FEATURE, MyWorkListViewType, STATUS_GUID, TASK_STATUS_GUID } from '../../../../shared/utils/constants';
import { getCachedFeature } from '../../../../shared/cache/FeatureCache';
import { getShowClosedFromStorage } from '../../../MyWork/MyWorkList/myWorkListHelper';
import { MyWorkContext } from '../../../MyWork/MyWorkContextProvider';
import { Route } from '../../../../../route-constants';
import { useSetState } from '../../../../AppState';
import { UpgradeNeeded } from '../../../../shared/components/UpgradeNeeded';
import { useTranslation } from 'react-i18next';

export const TicketTasksTemplate = () => {
  const api = new platformService();
  const history = useHistory();
  const userName: string = getUser().toLowerCase();
  const [ticketTasksList, setTicketTasksList] = useState<TicketTask[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isFeatureAvailable, setIsFeatureAvailable] = useState<boolean>(false);
  const { myWorkStates } = useContext(MyWorkContext);
  const location = useLocation();
  const { t } = useTranslation();

  const isMyGroupWork = location.pathname.includes(Route.myGroupWork.pagePath);
  const isMyWork = location.pathname.includes(Route.myWork.pagePath);

  const isClosed = getShowClosedFromStorage(isMyGroupWork) ?? myWorkStates.showClosed;
  const setAppState = useSetState();

  const loadMyTasks = async () => {
    setIsLoading(true);
    try {
      const [isTicketTaskEnabled, isLifecycleEnabled] = await Promise.all([
        getCachedFeature(LICENSE_FEATURE.TicketTasks),
        getCachedFeature(LICENSE_FEATURE.TicketLifecycle)
      ]);
      const phaseFilter = `(TicketLifecyclePhaseId eq null ${isLifecycleEnabled ? ' or TicketLifecyclePhase/IsCurrent eq true' : ''})`;
      const taskFilter = isClosed ?
        `(Ticket/Closed eq true or Ticket/Status/Guid eq ${STATUS_GUID.RESOLVED} or Status/Guid eq ${TASK_STATUS_GUID.COMPLETED} or Status/Guid eq ${TASK_STATUS_GUID.FAILED})` :
        `(Ticket/Closed eq false and Ticket/Status/Guid ne ${STATUS_GUID.RESOLVED} and Status/Guid ne ${TASK_STATUS_GUID.COMPLETED} and Status/Guid ne ${TASK_STATUS_GUID.FAILED})`;
      const params: string = `?$filter=Assignee/UserName eq '${userName}' and ${taskFilter} and ${phaseFilter} &$orderby=CreatedDate desc&$expand=Status,Assignee,Ticket($select=Title,Id)`;
      const tasksList = isTicketTaskEnabled ? await api.getTicketTasks(params) : [];
      setTicketTasksList(tasksList);
      setIsLoading(false);
      setIsFeatureAvailable(isTicketTaskEnabled);
    } catch (error) {
      setIsLoading(false);
      console.log('Error: ', error);
    }
  };

  useEffect(() => {
    loadMyTasks();
  }, [isClosed])

  const onTaskClick = (ticketId: number, taskId: number) => {
    setAppState((prev) => ({
      ...prev,
      isRouteFromMyWork: (isMyGroupWork || isMyWork),
      selectedTaskOrApproval: { type: MyWorkListViewType.Tasks, id: taskId },
    }));
    const route = isMyGroupWork ? Route.myGroupWork.editTicket : Route.myWork.editTicket;
    const path = `${route}/${ticketId}`;
    const from = isMyGroupWork ? 'mygroupwork' : 'mywork';
    history.push(path, { from });
  }

  return (
    <>
      {isLoading ? <TicketTasksListSkeleton /> : <>
        {isFeatureAvailable && (ticketTasksList.length > 0 ? (
          ticketTasksList.map((ticketTask: TicketTask) => (
            <div className={`mb-2 cursor-pointer`} title={`${t('my-work.task-template.ticket-title')}: ${ticketTask.TicketId}`} onClick={() => { onTaskClick(ticketTask.TicketId, ticketTask.Id) }}>
              <TicketTaskCard ticketTask={ticketTask} />
            </div>
          ))
        ) : (
          <EmptyData headerText={t('my-work.my-ticket.no-task')} SVGIcon={<EmptySVG width={75} height={75} />} />
        ))}
        {!isFeatureAvailable && <div className='flex flex-col justify-around h-full w-full'>
          <UpgradeNeeded
            headerText={FEATURE_NOT_AVAILABLE.TaskHeader}
            subheaderText={FEATURE_NOT_AVAILABLE.TaskSubHeader}
          />
        </div>}
      </>}
    </>
  );
};

import React, { useState, useEffect, useRef } from 'react';
import Styles from '../../Automation.module.css';
import {
  AddIcon,
  Button,
  Dropdown,
  Flex,
  FormLabel,
  Loader,
  FormCheckbox,
  Text,
  ProviderConsumer,
  Dialog,
  CloseIcon, 
  Checkbox, 
  RadioGroup
} from '@fluentui/react-northstar';
import {
  AUTOMATION_ACTION_TYPE,
  DEFAULT_AUTOMATION_PROPERTY,
  AUTOMATION_LABELS,
  LICENSE_FEATURE,
  ENTITY_TYPE,
  CardList,
  AUTOMATION_EMAIL,
  BUTTONS,
  AutomationEditorStyle,
  toolbarOptions,
  AutomationToggleStyle
} from '../../../shared/utils/constants';
import { FormGroup } from '../../Automation';
import { Arrow } from './_/Arrow/Arrow';
import { GlobalActions } from './_/Arrow/GlobalActions';
import { AutomationFilter } from '../Shared/AutomationFilter/AutomationFilter';
import { TextInputField } from '../../../shared/common/TextInputField';
import { cloneDeep, uniqueId } from 'lodash';
import { Toggle } from '@fluentui/react';
import { dementionify, mentionify, removeHtmlTags, removeWhiteSpaces } from '../../../shared/utils/helper';
import { getCachedFeature } from '../../../shared/cache/FeatureCache';
import { useTranslation } from 'react-i18next';
import {
  EditorState
} from 'draft-js';
import { platformService } from '../../../shared/services/platform.service';
import { HtmlEditorComponent } from '../../../shared/components/Wysiwyg/Editor';
import { PeoplePicker, PersonType, UserType } from '@microsoft/mgt-react';

import { LabelElement } from '../../../token/TokenModal';
import { AdaptiveCardElement } from '../../../templates/AdaptiveCardElement';
import { Action } from 'adaptivecards';
import { ConvertTokenValue, getTicketTokenMentionList } from '../../../shared/common/CommonHelper';
import { ThemeColorScheme } from '../../../shared/common/TeamsTheme';
import MgtCss from '../../../../../src/components/mgt.module.css';
import { themeNames } from '@fluentui/react-teams';

interface Props {
  propertiesList: DropdownDataModel[];
  doData: AutomationDoTickets;
  updateDoData: (data: AutomationDoTickets) => void;
  setIsEmailValid
}
export const ARROW_HEIGHT = 100;

export const Do = ({
  propertiesList,
  doData,
  doData: { actionName, actionType },
  updateDoData,
  setIsEmailValid
}: Props) => {
  const api = new platformService();
  const peoplePickerRef = useRef<any>();
  const noReplyConstant = 'no-reply';
  const { t } = useTranslation();
  const [globalAction, setGlobalAction] = useState('');
  const [actionTypes, setActionTypes] = useState({});
  const [enableNotificationAction, setEnableNotificationAction] = useState(false);
  const [emails, setEmails] = useState([noReplyConstant]);
  const [defaultFromEmail, setDefaultFromEmail] = useState(noReplyConstant);
  const [ticketProps, setTicketProps] = useState<string[]>([]);
  const [emailError, setEmailError] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [isDialogTVA, setIsDialogTVA] = useState(false);
  const [mentionList, setMentionList] = useState({
    separator: ' ',
    trigger: '{',
    suggestions: []
  });

  const [selectedCard, setSelectedCard] = useState("");
  const [selectedCardData, setSelectedCardData] = useState([]);
  const [cardWidth, setCardWidth] = useState({});
  const [cardList, setCardList] = useState([]);
  const [templateList, setTemplateList] = useState([]);
  const [multiInbox, setMultiInbox] = useState(false);
  const [isTVA, setIsTVA] = useState(false);
  const [sendCustomForm, setSendCustomForm] =  useState(false);


  useEffect(() => {
    getTemplateListData();
    (async () => {
      var enableFeature = false;
      try {
        enableFeature = await getCachedFeature(LICENSE_FEATURE.NotificationAction);
        setEnableNotificationAction(enableFeature);

        const [emailConfig,ticketProperty,teamsSafe,emailConnector,multiEmailInbox,customForms] 
        = await Promise.all([
          api.getEmailConnectorConfiguration(),
          api.getEntityProperties(),
          api.getTeamsSafe(`?$filter=SubscriptionId ne null and SubscriptionId ne ''`),
          getCachedFeature(LICENSE_FEATURE.EmailConnector),
          getCachedFeature(LICENSE_FEATURE.MultiEmailInbox),
          getCachedFeature(LICENSE_FEATURE.CustomForms)
        ]);

        const { data } = emailConfig;
        setTicketProps(ticketProperty["data"]["value"]);
        setMultiInbox(multiEmailInbox);
        setSendCustomForm(customForms);

        if (emailConnector) { // check if email connector feature is checked
          if (!multiEmailInbox) {
            setDefaultFromEmail(data.EmailAddress ?? noReplyConstant);
            setEmails([...emails, data.EmailAddress]);
          } else {
            setDefaultFromEmail(t('automation.ticket-team'));
            setEmails([...emails, t('automation.ticket-team'), ...teamsSafe.map(o => o.EmailAddress)]);
          }
        }
      } catch {
        setEnableNotificationAction(false);
      }
      setActionTypes(AUTOMATION_ACTION_TYPE.map(o => {
        if (!enableFeature && o.key == "notification") {
          o["disabled"] = true;
          o["content"] = AUTOMATION_LABELS.FEATURE_RESTRICTED;
          o["className"] = Styles.DisabledDropdownItem;
        }
      }
      ));
    })();

  }, [])

  useEffect(()=>{
     if (isTVA && selectedCardData.some(x=>x.description=="approver") && doData.entitySelected==ENTITY_TYPE.Approval)
       setCardWidth({width:'1095px'});
     else setCardWidth({width:'587px'});
  },[selectedCardData]);

  useEffect(() => {
    (async () => {
      let suggestions:MensionSuggestion[] = [];
      switch (doData.entitySelected){
        case ENTITY_TYPE.Ticket:
          suggestions = suggestions.concat([
            { text: 'Merged to ticket Id', value: 'MergedToTicketId' },
            { text: 'Relate to ticket Id', value: 'RelateToTicketId' }
          ]);
          break;
        case ENTITY_TYPE.SLA:
          suggestions = suggestions.concat([
            { text: 'SLA Name', value: 'SLAName'},
            { text: 'SLA State', value: 'SLAState' },
            { text: 'SLA Time Left', value: 'SLATimeLeft' }
          ]);
          break;
        case ENTITY_TYPE.Approval:
          suggestions = suggestions.concat([
            { text: 'Approval Title', value: 'ApprovalTitle'},
            { text: 'Approval State', value: 'ApprovalState' },
            { text: 'Approval Details', value: 'ApprovalDetails' },
            { text: 'Approval Created Date', value: 'ApprovalCreatedDate' },
            { text: 'Approval Modified Date', value: 'ApprovalModifiedDate' },
            { text: 'Approvers', value: 'Approvers' },
            { text: 'Requires a response from all recipients', value: 'RequiresResponseFromAllRecipients' }
          ]);
          break;
        case ENTITY_TYPE.Task:
          suggestions = suggestions.concat([
            { text: 'Task Title', value: 'TaskTitle' },
            { text: 'Task Status', value: 'TaskStatus'},
            { text: 'Task Assignee', value: 'Task.Assignee' },
            { text: 'Task Created Date', value: 'TaskCreatedDate' },
            { text: 'Task Modified Date', value: 'TaskModifiedDate' }
          ]);
          break;
          case ENTITY_TYPE.Lifecycle:
            suggestions = suggestions.concat([
              { text: 'Lifecycle Name', value: 'Lifecycle.Name' },
              { text: 'Lifecycle State', value: 'Lifecycle.Status' },
              { text: 'Phase Name', value: 'Phase.Name' },
              { text: 'Phase Is Current', value: 'Phase.IsCurrent'},
              { text: 'Phase Name(s)', value: 'Phase.Names'},
            ]);
            break;
      }
  
      
      if (ticketProps.length>0){
        let mentionList = await getTicketTokenMentionList({additionSuggestions:suggestions,ticketPropertyList:ticketProps});
        setMentionList(mentionList);
      }

    })();
    
    
  },[doData.entitySelected,ticketProps]);

  useEffect(() => {
    //necessary to get mailbox saved as default value even without change dropdown value
    if (defaultFromEmail !== noReplyConstant || (doData.actionNotification?.MessageSender ?? "").length == 0) {
      updateDoData({
        ...doData,
        actionNotification: {
          ...doData?.actionNotification,
          MessageSender: defaultFromEmail
        }
      });
    }
  }, [defaultFromEmail])

  useEffect(()=>{
    
    let isTikitValid = true;
    let isTVAValid = true;
    let isEmailMessageValid = true;
    if (doData?.actionNotification?.SendTeamsNotification &&
      (doData?.actionNotification?.NotifyAssignee
      || doData?.actionNotification?.NotifyTaskAssignee
      || doData?.actionNotification?.NotifyTeam
      || doData?.actionNotification?.NotifyGroup))
      {
        
        if ((doData.actionNotification?.NotifyTikitMessageHeader!=undefined && removeWhiteSpaces(doData.actionNotification?.NotifyTikitMessageHeader?.trim())==''))
           isTikitValid = false;
      }


    if (doData?.actionNotification?.SendTeamsNotification &&
      (doData?.actionNotification?.NotifyRequester
      || doData?.actionNotification?.NotifyApprovers
      || doData?.actionNotification?.NotifySelectedUsers)
    )
      {
        if ((doData.actionNotification?.NotifyTVAMessageHeader!=undefined && removeWhiteSpaces(doData.actionNotification?.NotifyTVAMessageHeader?.trim())==''))
        isTVAValid = false;
      }

      if (doData.actionNotification?.SendEmailNotification && 
        (doData.actionNotification?.EmailRequester
          || doData.actionNotification?.EmailAssignee
          || doData.actionNotification?.EmailGroup
          || doData.actionNotification?.EmailTeam
          || doData.actionNotification?.EmailApprovers
          || doData.actionNotification?.EmailTaskAssignee
          || doData.actionNotification?.EmailCollaborator
          || doData.actionNotification?.EmailAffectedUser
          || removeWhiteSpaces(doData.actionNotification?.DefinedEmails?.trim())!='')){
        if ((doData.actionNotification?.MessageSubject!=undefined && removeWhiteSpaces(doData.actionNotification?.MessageSubject?.trim())==''))
        isEmailMessageValid = false;
      }

      updateDoData({
        ...doData,
        actionNotification: {
          ...doData?.actionNotification,
          NotifyMessageValid: isTikitValid,
          NotifyTVAMessageValid: isTVAValid,
          EmailMessageValid: isEmailMessageValid
        }
      });

  },[doData.actionNotification?.NotifyTikitMessageHeader,doData.actionNotification?.NotifyTikitMessage,
    doData.actionNotification?.NotifyTVAMessageHeader,doData.actionNotification?.NotifyTVAMessage,
    doData.actionNotification?.SendTeamsNotification,
    doData.actionNotification?.NotifyRequester,
    doData.actionNotification?.NotifyChannel,
    doData.actionNotification?.NotifyAssignee,
    doData.actionNotification?.NotifyGroup,
    doData.actionNotification?.NotifyTeam,
    doData.actionNotification?.NotifyApprovers,
    doData.actionNotification?.NotifyTaskAssignee,
    doData.actionNotification?.NotifySelectedUsers,
    doData.actionNotification?.NotifyUsers,
    doData.actionNotification?.SendEmailNotification,
    doData.actionNotification?.EmailAssignee,
    doData.actionNotification?.EmailRequester,
    doData.actionNotification?.EmailGroup,
    doData.actionNotification?.EmailTeam,
    doData.actionNotification?.EmailApprovers,
    doData.actionNotification?.EmailTaskAssignee,
    doData.actionNotification?.EmailAffectedUser,
    doData.actionNotification?.EmailCollaborator,
    doData.actionNotification?.ExcludeFooter,
    doData.actionNotification?.DefinedEmails,
    doData.actionNotification?.Message,
    doData.actionNotification?.MessageSender,
    doData.actionNotification?.MessageSubject,
    doData.actionNotification?.CardNameTikit,
    doData.actionNotification?.CardNameTVA,
    doData.actionNotification?.UpdateCardTikit,
    doData.actionNotification?.UpdateCardTVA,
    doData.actionNotification?.NotifyMessageValid,
    doData.actionNotification?.NotifyTVAMessageValid,
    doData.actionNotification?.NotifyAffectedUser,
    doData.actionNotification?.NotifyCollaborator,
    doData.actionType, doData.actionName
  ]);

  useEffect(()=>{
    if (peoplePickerRef.current && doData?.actionNotification?.NotifyUsers){
      peoplePickerRef.current.selectedPeople = doData?.actionNotification?.NotifyUsers.map(x => ({
        displayName: x.FullName,
        userPrincipalName:  x.UserName,
        id: x.AadObjectId
      }));
    }
  },[peoplePickerRef.current]);

  const copyGlobalAction = () => {
    const ticketsClone = cloneDeep(doData);

    ticketsClone.items = [];
    ticketsClone.height = 0;
    ticketsClone.items.push({
      key: uniqueId('id'),
      Id: 0,
      Property: 'Resolution Date',
      Value: 'today-0',
      ActionId: 0
    });
    ticketsClone.height = 36;

    updateDoData(ticketsClone);
    setGlobalAction('');
  };

  const addNewProperty = (
    data: AutomationDoTicketInfo = DEFAULT_AUTOMATION_PROPERTY
  ) => {
    updateDoData({
      ...doData,
      height: doData.height + ARROW_HEIGHT,
      items: [...doData.items, { ...data }]
    });
  };

  const updatePropertyData = (
    propertyIndex: number,
    data: AutomationDoTicketInfo
  ) => {
    const ticketsClone = { ...doData };
    ticketsClone.items[propertyIndex] = { ...data };
    updateDoData(ticketsClone);
  };

  const removeProperty = (groupIndex: number, propertyIndex: number) => {
    const ticketsClone = { ...doData };
    ticketsClone.items.splice(propertyIndex, 1);
    ticketsClone.height = doData.height > 0 ? doData.height - ARROW_HEIGHT : 36;
    updateDoData({ ...ticketsClone });
  };

  const [editorState, setEditorState] = useState(
    () => EditorState.createEmpty(),
  );




  const GetCardName = (cardName:string) => t(`automation.automation-cards.${(cardName == undefined || cardName=="" ? "AutomationNotificationCard" : cardName)}`);


  const onSelectedUser = async (e: any) => {
    const selectedUsers = e.detail;
    let selectedUser = [];
    selectedUsers.forEach(user => {
      const userToMap = (user?.userPrincipalName) ? user.userPrincipalName : user.displayName
      selectedUser.push({
        displayName: user.displayName, 
        userPrincipalName: userToMap,
        aadObjectId: user.id
      });
    });

    updateDoData({
      ...doData,
      actionNotification: {
        ...doData?.actionNotification,
        NotifyUsers: selectedUser
      }
    });
  };

  
  

  const openCardDialog = (isTVA:boolean) => {
    let cardName = isTVA ? doData.actionNotification?.CardNameTVA : doData.actionNotification?.CardNameTikit;
    if (cardName==undefined || cardName=="") cardName="AutomationNotificationCard";
    setIsTVA(isTVA);
    setIsDialogTVA(isTVA);
    setSelectedCard(cardName);
    setOpenDialog(true);
  }

  useEffect(()=>{
    loadCardList(selectedCard);
  },[selectedCard,isTVA,openDialog]);

  const loadCardList = (cardName:string) => {
    let headerData = "";
    let bodyData = "";

    if (isTVA) 
    {
      headerData = ConvertTokenValue(mentionList.suggestions, doData?.actionNotification.NotifyTVAMessageHeader ?? AUTOMATION_EMAIL.DEFAULT_SUBJECT);
      bodyData = ConvertTokenValue(mentionList.suggestions, doData?.actionNotification.NotifyTVAMessage ?? AUTOMATION_EMAIL.DEFAULT_BODY);
    }
    else
    {
      headerData = ConvertTokenValue(mentionList.suggestions, doData?.actionNotification.NotifyTikitMessageHeader ?? AUTOMATION_EMAIL.DEFAULT_SUBJECT);
      bodyData = ConvertTokenValue(mentionList.suggestions, doData?.actionNotification.NotifyTikitMessage ?? AUTOMATION_EMAIL.DEFAULT_BODY);
    }

    mentionList.suggestions.forEach(item=>{
      headerData = headerData.replaceAll(`{${item.url}`,`{{${item.text}}}`);
      bodyData = bodyData.replaceAll(`{${item.url}`,`{{${item.text}}}`);
    });

    let simpleCardList = [
      {
        name: "AutomationNotificationCard",
        data: [
                {
                  type:["tikit"], 
                  description: "ticket-card-hdear", 
                  card: JSON.stringify(CardList.AutomationNotificationCard).replaceAll('#Body#',bodyData).replaceAll("#Title#",headerData)
                },
                {
                  type:["tva"], 
                  description: "tva-card-hdear",  
                  card: JSON.stringify(CardList.AutomationNotificationCard).replaceAll('#Body#',bodyData).replaceAll("#Title#",headerData)
                }
              ]
      }
    ];

    switch(doData.entitySelected)
    {
      case ENTITY_TYPE.Task:
        simpleCardList.push({
          name: "TicketTaskCardJson",
          data: [
                  {
                    type:["tikit"], 
                    description: "ticket-card-hdear", 
                    card: JSON.stringify(CardList.TicketTaskCardJson).replaceAll("#Body#",bodyData).replaceAll("#Title#",headerData)
                  },
                  {
                    type:["tva"], 
                    description: "tva-card-hdear", 
                    card: JSON.stringify(CardList.TicketTaskCardJson_tva).replaceAll("#Body#",bodyData).replaceAll("#Title#",headerData)
                  }
                ]
        });
        break;
      case ENTITY_TYPE.Approval:


        simpleCardList = [
          {
            name: "AutomationNotificationCard",
            data: [
                {
                  type:["tikit"], 
                  description: "ticket-card-hdear", 
                  card: JSON.stringify(CardList.AutomationNotificationCard_tikit).replaceAll("#Body#",bodyData).replaceAll("#Title#",headerData)
                },
                {
                  type:["tva"], 
                  description: "tva-card-hdear", 
                  card: JSON.stringify(CardList.AutomationNotificationCard_tikit).replaceAll("#Body#",bodyData).replaceAll("#Title#",headerData)
                }
              ]
          },
          {
            name: "ApprovalTicketNotificationCard",
            data: [
              {
                type:["tikit"], 
                description: "ticket-card-hdear", 
                card : JSON.stringify(CardList.ApprovalTicketNotificationCard_tikit).replaceAll("#Body#",bodyData).replaceAll("#Title#",headerData)
              },
              {
                type:["tva"], 
                description: "requester-card", 
                card : JSON.stringify(CardList.ApprovalTicketNotificationCard_tva_requester).replaceAll("#Body#",bodyData).replaceAll("#Title#",headerData)
              },
              {
                type:["tva"], 
                description: "approver", 
                card : JSON.stringify(CardList.ApprovalTicketNotificationCard_tva_approver).replaceAll("#Body#",bodyData).replaceAll("#Title#",headerData)
              }
            ]
          }
        ];

        break;
      default:
        simpleCardList.push({
          name: "CommentCardJson",
          data: [{
                    type:["tikit"], 
                    description: "ticket-card-hdear", 
                    card : JSON.stringify(CardList.CommentCardJson).replaceAll("#Body#",bodyData).replaceAll("#Title#",headerData)
                  },
                  {
                    type:["tva"], 
                    description: "tva-card-hdear", 
                    card : JSON.stringify(CardList.CommentCardJson_tva).replaceAll("#Body#",bodyData).replaceAll("#Title#",headerData)
                  }
                ]
        });

        simpleCardList.push({
          name: "AnalystCardJson",
          data: [
                  {
                    type:["tikit"], 
                    description: "ticket-card-hdear", 
                    card : JSON.stringify(CardList.AnalystCardJson_tikit).replaceAll("#Body#",bodyData).replaceAll("#Title#",headerData)
                  },
                  {
                    type:["tva"], 
                    description: "tva-card-hdear", 
                    card : JSON.stringify(CardList.AnalystCardJson_tva).replaceAll("#Body#",bodyData).replaceAll("#Title#",headerData)
                  }
                ]
        });
        break;
    }


    let cardList = simpleCardList.map((x, index)=>{
      if (x.name==cardName) {
        setSelectedCardData(x.data);
      }

      return {
        name: "card",
        key: x.name,
        label: GetCardName(x.name),
        value: x.name,
        data:  x.data
      };
    });

    setCardList(cardList);

  }

  const onExecuteAction = (action: Action) => {
    //This is just to make the component compiled/build.
  }
  const getFilterProperties = () => {
    const excludeProperties = ["relatedtickets","template","closed"];
    return propertiesList.filter(x=>!excludeProperties.includes(x.key));
  }

  const getTemplateListData = async () => {
    try {
      const templateList:any[] = (await api.getTicketTemplates('?$filter=(IsDeleted%20eq%20false%20)&$select=Id,Name,NoCustomForm')).data.value;
      templateList.unshift({Id: 0, Name: AUTOMATION_LABELS.current_selected_template, NoCustomForm: false});
      setTemplateList(templateList.map(item => {
        return {
          key: item.Name,
          label: item.Name,
          header: item.Name,
          Id: item.Id,
          NoCustomForm: item.NoCustomForm
        };
      }));
    } catch (error) {
      console.error(error);
    }
  };

  const templateHasCustomForm = () =>{
    const template = templateList.find(x=>x.Id==doData?.actionApplyTemplate?.TemplateId);
    if (template!=undefined) return template.NoCustomForm;
    return false;
    
  }


   const cardLoaded = ()=>{
    let customBody = document.querySelectorAll("[id=customBody]");
    for(let item of customBody){
      item.classList.add("markdown-body");
    }
    
  }

  const isTikitEditorEnabled = () => 
  !(doData?.actionNotification?.SendTeamsNotification &&
    (doData?.actionNotification?.NotifyAssignee
    || doData?.actionNotification?.NotifyTaskAssignee
    || doData?.actionNotification?.NotifyTeam
    || doData?.actionNotification?.NotifyGroup
    || doData?.actionNotification?.NotifyCollaborator
    || doData?.actionNotification?.NotifyMentioned)
  );

  const isTVAEditorEnabled = () => 
  !(doData?.actionNotification?.SendTeamsNotification &&
    (doData?.actionNotification?.NotifyRequester
    || doData?.actionNotification?.NotifyApprovers
    || doData?.actionNotification?.NotifySelectedUsers
    || doData?.actionNotification?.NotifyAffectedUser)
  );


  return (
    (<ProviderConsumer
      render={(globalTheme) => (
        <div className={Styles.WhenSection}>
          <h2 className={Styles.SubHeading2}>{t('automation.do-text')}</h2>

          <FormGroup>
            <GlobalActions
              copyGlobalAction={copyGlobalAction}
              globalAction={globalAction}
              updateGlobalAction={setGlobalAction}
            />

            <div className={Styles.marginY}>
              <FormLabel content={t('automation.automation-action-type.title')} styles={{ fontSize: '12px' }} />
              <Dropdown
                inverted
                fluid
                items={AUTOMATION_ACTION_TYPE}
                placeholder="Select Action Type"
                value={t(`automation.automation-action-type.${actionType.toLowerCase()}`)}
                onChange={(e, p) => {
                  const data = p.value as DropdownDataModel;
                  updateDoData({
                    ...doData,
                    actionType: data.key.toUpperCase()
                  });
                }}
                style={{ backgroundColor: globalTheme.siteVariables.colorScheme.default.background }}
              />
            </div>
          </FormGroup>
          {doData.loading ? (
            <Loader label={t('automation.do.loading')} />
          ) : (
            <>
              {(doData.actionType.toLowerCase() == 'update' || doData.actionType.toLowerCase() == 'create') && (
                <>
                  <div className={Styles.marginY}>
                    <span className={Styles.TicketLabel}>{t('automation.do.tickets')}</span>
                  </div>
                  <div className={`${Styles.TicketsContainer}`}>
                    {doData.height > 0 && (
                      <div className={Styles.ArrowContainer}>
                        <Arrow height={doData.height} />
                      </div>
                    )}

                    {React.Children.toArray(
                      doData.items.map(
                        (doItem: AutomationDoTicketInfo, index: number) => (
                          <AutomationFilter
                            propertiesList={getFilterProperties()}
                            section="do"
                            propertyData={doItem}
                            filterIndex={index}
                            updatePropertyData={updatePropertyData}
                            removeFilter={removeProperty}
                            whenData={undefined}
                          />
                        )
                      )
                    )}
                  </div>

                  <Flex styles={{ margin: '1.5rem 0' }}>
                    <Button
                      icon={<AddIcon styles={{ color: globalTheme.siteVariables.colorScheme.brand.foreground }} />}
                      text
                      primary
                      content={<span style={{ color: globalTheme.siteVariables.colorScheme.brand.foreground }}>{t('automation.do.add-property')}</span>}
                      onClick={() => {
                        addNewProperty();
                      }}
                    />
                  </Flex>
                </>
              )}
              {doData.actionType.toLowerCase() == 'applytemplate' && (
                <>
                  <div className={Styles.marginY}>
                    <Text content={AUTOMATION_LABELS.DELIVERY_OPTIONS} className="mr-2" color="brand" weight="semibold" />
                  </div>
                  <div className={`${Styles.TicketsContainer}`}>
                  <FormLabel content={AUTOMATION_LABELS.Template} styles={{ fontSize: '12px' }} />
                    <Dropdown
                      inverted
                      fluid
                      items={templateList}
                      placeholder={AUTOMATION_LABELS.Select_template}
                      value={templateList.find(x=>x.Id==doData?.actionApplyTemplate?.TemplateId)}
                      onChange={(e, p) => {
                        const data = p.value as DropdownDataModel;
                        updateDoData({
                          ...doData,
                          actionApplyTemplate: {
                            ...doData?.actionApplyTemplate,
                            TemplateId: data.Id ?? 0,
                            SendCustomFormToRequester: !data["NoCustomForm"]
                          }
                        });
                      }}
                      style={{ backgroundColor: globalTheme.siteVariables.colorScheme.default.background }}
                    />
                    {sendCustomForm && 
                      <FormCheckbox className="ml-0"
                        label={<>
                          {AUTOMATION_LABELS.SendCustomForm}
                        </>}
                        disabled={templateHasCustomForm()}
                        checked={templateHasCustomForm() ? false : doData?.actionApplyTemplate?.SendCustomFormToRequester}
                        onChange={(_, checked) => {
                          updateDoData({
                            ...doData,
                            actionApplyTemplate: {
                              ...doData?.actionApplyTemplate,
                              SendCustomFormToRequester: checked.checked
                            }
                          });
                        }}
                      />
                    }
                    
                  </div>
                </>
              )}
              {doData.actionType.toLowerCase() == AUTOMATION_LABELS.NOTIFICATION.toLowerCase() && enableNotificationAction === true && (
                <>
                  <div className={Styles.marginY}>
                    <Text content={AUTOMATION_LABELS.DELIVERY_OPTIONS} className="mr-2" color="brand" weight="semibold" />
                  </div>
                  <div style={{height:'820px'}} className={`${Styles.marginY}`}>
                    <Flex>
                    <Toggle
                      defaultChecked={doData?.actionNotification?.SendTeamsNotification}
                      style={doData?.actionNotification?.SendTeamsNotification ? AutomationToggleStyle.Enabled : AutomationToggleStyle.Disabled}
                        onChange={(_ev: React.MouseEvent<HTMLElement>, checked?: boolean) => {
                          updateDoData({
                            ...doData,
                            actionNotification: {
                              ...doData?.actionNotification,
                              SendTeamsNotification: checked
                            }
                          });
                        }}
                      />
                      <Text content={AUTOMATION_LABELS.TEAMS_NOTIFICATION} className="mr-2" color="brand" />
                    </Flex>
                    <Flex style={{fontWeight:600}}>{AUTOMATION_LABELS.NotificationRecipients}</Flex>
                    <FormGroup>
                      <FormCheckbox
                        disabled={!(doData?.actionNotification?.SendTeamsNotification ?? false)}
                        label={AUTOMATION_LABELS.REQUESTER}
                        checked={doData?.actionNotification?.NotifyRequester}
                        onChange={(_, checked) => {
                          updateDoData({
                            ...doData,
                            actionNotification: {
                              ...doData?.actionNotification,
                              NotifyRequester: checked.checked
                            }
                          });
                        }}
                      />
                      <FormCheckbox
                        disabled={!(doData?.actionNotification?.SendTeamsNotification ?? false)}
                        label={AUTOMATION_LABELS.ASSIGNEE}
                        checked={doData?.actionNotification?.NotifyAssignee}
                        onChange={(_, checked) => {
                          updateDoData({
                            ...doData,
                            actionNotification: {
                              ...doData?.actionNotification,
                              NotifyAssignee: checked.checked
                            }
                          });
                        }}
                      />
                      <FormCheckbox
                        disabled={!(doData?.actionNotification?.SendTeamsNotification ?? false)}
                        label={AUTOMATION_LABELS.GROUP}
                        checked={doData?.actionNotification?.NotifyGroup}
                        onChange={(_, checked) => {
                          updateDoData({
                            ...doData,
                            actionNotification: {
                              ...doData?.actionNotification,
                              NotifyGroup: checked.checked
                            }
                          });
                        }}
                      />
                      <FormCheckbox
                        disabled={!(doData?.actionNotification?.SendTeamsNotification ?? false)}
                        label={AUTOMATION_LABELS.TEAM}
                        checked={doData?.actionNotification?.NotifyTeam}
                        onChange={(_, checked) => {
                          updateDoData({
                            ...doData,
                            actionNotification: {
                              ...doData?.actionNotification,
                              NotifyTeam: checked.checked
                            }
                          });
                        }}
                      />
                      <FormCheckbox
                        disabled={!(doData?.actionNotification?.SendTeamsNotification ?? false)}
                        label={AUTOMATION_LABELS.Approvers}
                        checked={doData?.actionNotification?.NotifyApprovers}
                        onChange={(_, checked) => {
                          updateDoData({
                            ...doData,
                            actionNotification: {
                              ...doData?.actionNotification,
                              NotifyApprovers: checked.checked
                            }
                          });
                        }}
                      />
                      <FormCheckbox
                        disabled={!(doData?.actionNotification?.SendTeamsNotification ?? false)}
                        label={AUTOMATION_LABELS.TaskAssignee}
                        checked={doData?.actionNotification?.NotifyTaskAssignee}
                        onChange={(_, checked) => {
                          updateDoData({
                            ...doData,
                            actionNotification: {
                              ...doData?.actionNotification,
                              NotifyTaskAssignee: checked.checked
                            }
                          });
                        }}
                      />
                      <FormCheckbox
                        disabled={!(doData?.actionNotification?.SendTeamsNotification ?? false)}
                        label={AUTOMATION_LABELS.Collaborator}
                        checked={doData?.actionNotification?.NotifyCollaborator}
                        onChange={(_, checked) => {
                          updateDoData({
                            ...doData,
                            actionNotification: {
                              ...doData?.actionNotification,
                              NotifyCollaborator: checked.checked
                            }
                          });
                        }}
                      />
                      <FormCheckbox
                        disabled={!(doData?.actionNotification?.SendTeamsNotification ?? false)}
                        label={AUTOMATION_LABELS.AffectedUser}
                        checked={doData?.actionNotification?.NotifyAffectedUser}
                        onChange={(_, checked) => {
                          updateDoData({
                            ...doData,
                            actionNotification: {
                              ...doData?.actionNotification,
                              NotifyAffectedUser: checked.checked
                            }
                          });
                        }}
                      />
                      <FormCheckbox
                        disabled={!(doData?.actionNotification?.SendTeamsNotification ?? false)}
                        label={AUTOMATION_LABELS.Mentioned}
                        checked={doData?.actionNotification?.NotifyMentioned}
                        onChange={(_, checked) => {
                          updateDoData({
                            ...doData,
                            actionNotification: {
                              ...doData?.actionNotification,
                              NotifyMentioned: checked.checked
                            }
                          });
                        }}
                      />
                      <FormCheckbox
                        disabled={!(doData?.actionNotification?.SendTeamsNotification ?? false)}
                        label={AUTOMATION_LABELS.NotifySelectedUsers}
                        checked={doData?.actionNotification?.NotifySelectedUsers}
                        onChange={(_, checked) => {
                          updateDoData({
                            ...doData,
                            actionNotification: {
                              ...doData?.actionNotification,
                              NotifySelectedUsers: checked.checked
                            }
                          });
                        }}
                      />
                      <div style={{
                        display: 'block',
                        ...{ '--input-background-color': 'var(--mgt-theme-background)' },
                        ...ThemeColorScheme(globalTheme.siteVariables)}}>
                        <div className="mr-2" style={{marginTop:"8px"}}>{AUTOMATION_LABELS.NotifySelectUsers}</div>
                        <PeoplePicker ref={peoplePickerRef} userType={UserType.user} type={PersonType.person} 
                          disabled={!(doData?.actionNotification?.NotifySelectedUsers ?? false)} 
                          className={`width-med height-small ${MgtCss.groupsPicker}`} 
                          selectionChanged={onSelectedUser} 
                        />
                      </div>
                      <br />
                    <Text content={t('automation.automation-labels.teams-notification-message')} className="mr-2" color="brand" weight="semibold" />
                    <div style={{minWidth:'1001px'}}>

                      <div className={Styles.notificationContainer + ' ' + Styles.notificationContainerTikit}>
                        <div className={Styles.notifiationAreaTitle}>
                          <LabelElement label={t('automation.automation-labels.tikit-notification-header')} weight="bold" styles={{ fontSize: '12px', fontWeight:'bold' }} className={"pt-8 pb-2"} />
                        </div>
                        <div className={`${Styles.notificationMessage} ${globalTheme.siteVariables.theme === themeNames.Dark && Styles.notificationMessageDarkmode}` }>
                          <div className={Styles.marginY}>
                            <LabelElement label={t('automation.automation-labels.tikit-card-header')} required={true} weight="bold" styles={{ fontSize: '12px', fontWeight:'bold' }} className="pt-8 pb-2" />
                            {mentionList.suggestions.length>0 && 
                              <HtmlEditorComponent
                                readOnly={isTikitEditorEnabled()}
                                htmlString={mentionify(doData.actionNotification?.NotifyTikitMessageHeader ?? AUTOMATION_EMAIL.DEFAULT_SUBJECT, mentionList.suggestions)}
                                mention={mentionList}
                                oneLine={true}
                                toolbarOptions={[]}
                                toolbarInline={{
                                  inDropdown: false,
                                  className: undefined,
                                  component: undefined,
                                  dropdownClassName: undefined,
                                  options: []
                                }}
                                onChange={(data: any) => {
                                  //add when subject changed
                                  updateDoData({
                                    ...doData,
                                    actionNotification: {
                                      ...doData?.actionNotification,
                                      NotifyTikitMessageHeader: removeHtmlTags(dementionify(data,mentionList.suggestions))
                                    }
                                  });

                                }}
                                wrapperClassName="editor--no-toolbar"
                                editorClassName={'editor--one-liner editor--with-mention ' + (globalTheme.siteVariables.theme === themeNames.Dark && ' editor--with-mention--darkmode')}
                                
                              />
                            }
                            
                          </div>
                          <div className={Styles.marginY}>
                          <LabelElement label={t('automation.automation-labels.card-message')} weight="bold" styles={{ fontSize: '12px', fontWeight:'bold' }} className="pt-8 pb-2" />
                          {mentionList.suggestions.length>0 && 
                            <HtmlEditorComponent
                              readOnly={isTikitEditorEnabled()}
                              htmlString={mentionify(doData.actionNotification?.NotifyTikitMessage ?? AUTOMATION_EMAIL.DEFAULT_BODY, mentionList.suggestions)}
                              mention={mentionList}
                              toolbarOptions={toolbarOptions.options}
                              toolbarInline={toolbarOptions.inlineOption}
                              onChange={(data: any) => {
                                //add when message body change
                                updateDoData({
                                  ...doData,
                                  actionNotification: {
                                    ...doData?.actionNotification,
                                    NotifyTikitMessage: dementionify(data,mentionList.suggestions)
                                  }
                                });
                              }}
                              wrapperClassName="htmleditor-wrapper"
                              editorClassName={"editor--with-mention automation-editor-height " + (globalTheme.siteVariables.theme === themeNames.Dark && ' editor--with-mention--darkmode')}
                            />
                          }
                            
                            <div style={{marginTop:"10px"}}>
                              <Button style={{boxShadow:"none"}} disabled={!(doData?.actionNotification?.SendTeamsNotification &&
                                            (doData?.actionNotification?.NotifyAssignee
                                            || doData?.actionNotification?.NotifyTaskAssignee
                                            || doData?.actionNotification?.NotifyTeam
                                            || doData?.actionNotification?.NotifyGroup
                                            || doData?.actionNotification?.NotifyCollaborator
                                            || doData?.actionNotification?.NotifyMentioned)
                                          )} 
                                          content={`${GetCardName("SelectedCard")} ${GetCardName(doData.actionNotification?.CardNameTikit)}`} 
                                          onClick={()=>{openCardDialog(false)}} 
                              />
                              <Checkbox 
                                checked={doData?.actionNotification?.UpdateCardTikit} 
                                label={t('automation.automation-labels.update-message-header')} 
                                disabled={!(doData?.actionNotification?.SendTeamsNotification &&
                                  (doData?.actionNotification?.NotifyAssignee
                                  || doData?.actionNotification?.NotifyTaskAssignee
                                  || doData?.actionNotification?.NotifyTeam
                                  || doData?.actionNotification?.NotifyGroup
                                  || doData?.actionNotification?.NotifyCollaborator
                                  || doData?.actionNotification?.NotifyMentioned)
                                )}
                                onChange={(e, { checked }) => {
                                  updateDoData({
                                    ...doData,
                                    actionNotification: {
                                      ...doData?.actionNotification,
                                      UpdateCardTikit: checked
                                    }
                                  });
                                }}
                              />
                            </div>
                            
                          </div>
                        </div>
                        
                      </div>

                      
                      <div className={Styles.notificationContainer + ' ' + Styles.notificationContainerTVA}>
                        <div className={Styles.notifiationAreaTitle}>
                          <LabelElement label={t('automation.automation-labels.tva-notification-header')} weight="bold" styles={{ fontSize: '12px', fontWeight:'bold' }} className={"pt-8 pb-2"} />
                        </div>
                        <div className={`${Styles.notificationMessage} ${globalTheme.siteVariables.theme === themeNames.Dark && Styles.notificationMessageDarkmode}` }>
                          <div className={Styles.marginY}>
                            <LabelElement label={t('automation.automation-labels.tva-card-header')} required={true} weight="bold" styles={{ fontSize: '12px', fontWeight:'bold' }} className="pt-8 pb-2" />
                            {mentionList.suggestions.length>0 && 
                              <HtmlEditorComponent
                                readOnly={isTVAEditorEnabled()}
                                htmlString={mentionify(doData.actionNotification?.NotifyTVAMessageHeader ?? AUTOMATION_EMAIL.DEFAULT_SUBJECT, mentionList.suggestions)}
                                mention={mentionList}
                                oneLine={true}
                                toolbarOptions={[]}
                                toolbarInline={{
                                  inDropdown: false,
                                  className: undefined,
                                  component: undefined,
                                  dropdownClassName: undefined,
                                  options: []
                                }}
                                onChange={(data: any) => {
                                  
                                  //add when subject changed
                                  updateDoData({
                                    ...doData,
                                    actionNotification: {
                                      ...doData?.actionNotification,
                                      NotifyTVAMessageHeader: removeHtmlTags(dementionify(data,mentionList.suggestions))
                                    }
                                  });

                                }}
                                wrapperClassName="editor--no-toolbar"
                                editorClassName={"editor--one-liner editor--with-mention " + (globalTheme.siteVariables.theme === themeNames.Dark && ' editor--with-mention--darkmode')}
                              />
                            }
                            
                          </div>
                          <div className={Styles.marginY}>
                          <LabelElement label={t('automation.automation-labels.card-message')} weight="bold" styles={{ fontSize: '12px', fontWeight:'bold' }} className="pt-8 pb-2" />
                          {mentionList.suggestions.length>0 && 
                            <HtmlEditorComponent
                              readOnly={isTVAEditorEnabled()}
                              htmlString={mentionify(doData.actionNotification?.NotifyTVAMessage ?? AUTOMATION_EMAIL.DEFAULT_BODY, mentionList.suggestions)}
                              mention={mentionList}
                              toolbarOptions={toolbarOptions.options}
                              toolbarInline={toolbarOptions.inlineOption}
                              onChange={(data: any) => {
                                  updateDoData({
                                    ...doData,
                                    actionNotification: {
                                      ...doData?.actionNotification,
                                      NotifyTVAMessage: dementionify(data,mentionList.suggestions)
                                    }
                                  });
                                //add when message body change
                                
                              }}
                              wrapperClassName="htmleditor-wrapper"
                              editorClassName={'editor--with-mention automation-editor-height ' + (globalTheme.siteVariables.theme === themeNames.Dark && ' editor--with-mention--darkmode')}
                            />
                          }
                            
                            <div style={{marginTop:"10px"}}>
                              <Button style={{boxShadow:"none"}} disabled={!(doData?.actionNotification?.SendTeamsNotification &&
                                          (doData?.actionNotification?.NotifyRequester
                                          || doData?.actionNotification?.NotifyApprovers
                                          || doData?.actionNotification?.NotifySelectedUsers
                                          || doData?.actionNotification?.NotifyAffectedUser)
                                        )} content={`${GetCardName("SelectedCard")} ${GetCardName(doData.actionNotification?.CardNameTVA)}`} 
                                        onClick={()=>{openCardDialog(true)}} 
                              />
                              <Checkbox 
                                checked={doData?.actionNotification?.UpdateCardTVA} 
                                label={t('automation.automation-labels.update-message-header')}
                                disabled={!(doData?.actionNotification?.SendTeamsNotification &&
                                  (doData?.actionNotification?.NotifyRequester
                                  || doData?.actionNotification?.NotifyApprovers
                                  || doData?.actionNotification?.NotifySelectedUsers
                                  || doData?.actionNotification?.NotifyAffectedUser)
                                )}
                                onChange={(e, { checked }) => {
                                  updateDoData({
                                    ...doData,
                                    actionNotification: {
                                      ...doData?.actionNotification,
                                      UpdateCardTVA: checked
                                    }
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        
                      </div>

                    </div>

                    
                    


                    </FormGroup>
                  </div>
                  <div className={`${Styles.marginY}`}>
                    <Flex style={{clear:'both'}}>
                    <Toggle
                      defaultChecked={doData?.actionNotification?.SendEmailNotification}
                      style={doData?.actionNotification?.SendEmailNotification ? AutomationToggleStyle.Enabled : AutomationToggleStyle.Disabled}
                        onChange={(_ev: React.MouseEvent<HTMLElement>, checked?: boolean) => {
                          updateDoData({
                            ...doData,
                            actionNotification: {
                              ...doData?.actionNotification,
                              SendEmailNotification: checked
                            }
                          });
                        }}
                      />
                      <Text content={AUTOMATION_LABELS.EMAIL_NOTIFICATION} className="mr-2" color="brand" />
                    </Flex>
                    <Flex style={{fontWeight:600}}>{AUTOMATION_LABELS.NotificationRecipients}</Flex>
                    <FormGroup>
                      <FormCheckbox
                        disabled={!(doData?.actionNotification?.SendEmailNotification ?? false)}
                        label={AUTOMATION_LABELS.REQUESTER}
                        checked={doData?.actionNotification?.EmailRequester}
                        onChange={(_, checked) => {
                          updateDoData({
                            ...doData,
                            actionNotification: {
                              ...doData?.actionNotification,
                              EmailRequester: checked.checked
                            }
                          });
                        }}
                      />
                      <FormCheckbox
                        disabled={!(doData?.actionNotification?.SendEmailNotification ?? false)}
                        label={AUTOMATION_LABELS.ASSIGNEE}
                        checked={doData?.actionNotification?.EmailAssignee}
                        onChange={(_, checked) => {
                          updateDoData({
                            ...doData,
                            actionNotification: {
                              ...doData?.actionNotification,
                              EmailAssignee: checked.checked
                            }
                          });
                        }}
                      />
                      <FormCheckbox
                        disabled={!(doData?.actionNotification?.SendEmailNotification ?? false)}
                        label={AUTOMATION_LABELS.GROUP}
                        checked={doData?.actionNotification?.EmailGroup}
                        onChange={(_, checked) => {
                          updateDoData({
                            ...doData,
                            actionNotification: {
                              ...doData?.actionNotification,
                              EmailGroup: checked.checked
                            }
                          });
                        }}
                      />
                      <FormCheckbox
                        disabled={!(doData?.actionNotification?.SendEmailNotification ?? false)}
                        label={AUTOMATION_LABELS.TEAM}
                        checked={doData?.actionNotification?.EmailTeam}
                        onChange={(_, checked) => {
                          updateDoData({
                            ...doData,
                            actionNotification: {
                              ...doData?.actionNotification,
                              EmailTeam: checked.checked
                            }
                          });
                        }}
                      />
                      <FormCheckbox
                        disabled={!(doData?.actionNotification?.SendEmailNotification ?? false)}
                        label={AUTOMATION_LABELS.Approvers}
                        checked={doData?.actionNotification?.EmailApprovers}
                        onChange={(_, checked) => {
                          updateDoData({
                            ...doData,
                            actionNotification: {
                              ...doData?.actionNotification,
                              EmailApprovers: checked.checked
                            }
                          });
                        }}
                      />
                      <FormCheckbox
                        disabled={!(doData?.actionNotification?.SendEmailNotification ?? false)}
                        label={AUTOMATION_LABELS.TaskAssignee}
                        checked={doData?.actionNotification?.EmailTaskAssignee}
                        onChange={(_, checked) => {
                          updateDoData({
                            ...doData,
                            actionNotification: {
                              ...doData?.actionNotification,
                              EmailTaskAssignee: checked.checked
                            }
                          });
                        }}
                      />
                      <FormCheckbox
                        disabled={!(doData?.actionNotification?.SendEmailNotification ?? false)}
                        label={AUTOMATION_LABELS.Collaborator}
                        checked={doData?.actionNotification?.EmailCollaborator}
                        onChange={(_, checked) => {
                          updateDoData({
                            ...doData,
                            actionNotification: {
                              ...doData?.actionNotification,
                              EmailCollaborator: checked.checked
                            }
                          });
                        }}
                      />
                      <FormCheckbox
                        disabled={!(doData?.actionNotification?.SendEmailNotification ?? false)}
                        label={AUTOMATION_LABELS.AffectedUser}
                        checked={doData?.actionNotification?.EmailAffectedUser}
                        onChange={(_, checked) => {
                          updateDoData({
                            ...doData,
                            actionNotification: {
                              ...doData?.actionNotification,
                              EmailAffectedUser: checked.checked
                            }
                          });
                        }}
                      />
                      <FormCheckbox
                        disabled={!(doData?.actionNotification?.SendEmailNotification ?? false)}
                        label={AUTOMATION_LABELS.Mentioned}
                        checked={doData?.actionNotification?.EmailMentioned}
                        onChange={(_, checked) => {
                          updateDoData({
                            ...doData,
                            actionNotification: {
                              ...doData?.actionNotification,
                              EmailMentioned: checked.checked
                            }
                          });
                        }}
                      />
                      <FormCheckbox
                        disabled={!(doData?.actionNotification?.SendEmailNotification ?? false)}
                        label={<>
                          {AUTOMATION_LABELS.ExcludeFooter} <span dangerouslySetInnerHTML={{ __html: t('notification-center.exclude-footer-details', { color: globalTheme.siteVariables.colorScheme.brand.foregroundActive })}} />
                        </>}

                        checked={doData?.actionNotification?.ExcludeFooter}
                        onChange={(_, checked) => {
                          updateDoData({
                            ...doData,
                            actionNotification: {
                              ...doData?.actionNotification,
                              ExcludeFooter: checked.checked
                            }
                          });
                        }}
                      />
                      <TextInputField
                        labelClassName="mb-1 mt-1"
                        label={t('automation.automation-labels.defined-emails')}
                        placeholder={t('automation.automation-labels.enter-email')}
                        isLabelToTitleCase={false}
                        value={doData?.actionNotification?.DefinedEmails}
                        errorMessage={emailError}
                        disabled={!doData?.actionNotification?.SendEmailNotification}
                        updateValue={(value: string) => {
                          let emailList = value.split(";");
                          for (let i in emailList) {
                            let _email = emailList[i];
                            if (_email.trim() == '' || /\S+@\S+\.\S+/.test(_email)) {
                              setEmailError('');
                              setIsEmailValid(true);
                            }
                            else {
                              setEmailError(t('automation.automation-labels.invalid-email'));
                              setIsEmailValid(false);
                              break;
                            }
                          }

                          updateDoData({
                            ...doData,
                            actionNotification: {
                              ...doData?.actionNotification,
                              DefinedEmails: value
                            }
                          });
                        }}
                      />

                    </FormGroup>
                  </div>
                  <div className={Styles.marginY}>
                    <Text content={AUTOMATION_LABELS.MESSAGE} className="mr-2" color="brand" weight="semibold" />

                    <div className={Styles.marginY}>
                      <FormLabel content={t('automation.automation-notification.from')} styles={{ fontSize: '12px' }} />
                      <Dropdown
                        disabled={!doData?.actionNotification?.SendEmailNotification}
                        items={emails}
                        defaultValue={(doData.actionNotification?.MessageSender ?? "").length > 0 ? doData.actionNotification.MessageSender === "team" ? t('automation.ticket-team') : doData.actionNotification.MessageSender : defaultFromEmail}
                        placeholder="Select from"
                        checkable
                        fluid
                        getA11ySelectionMessage={{
                          onAdd: item => `${item} has been selected.`,
                        }}
                        onChange={(_e, { value }) => {
                          const d = value.toString() === t('automation.ticket-team') ? "team" : value.toString()

                          updateDoData({
                            ...doData,
                            actionNotification: {
                              ...doData?.actionNotification,
                              MessageSender: d
                            }
                          });
                        }}
                        inverted
                        style={{ backgroundColor: globalTheme.siteVariables.colorScheme.default.background }}
                      />
                    </div>
                    <div className={Styles.marginY}>
                    <LabelElement label={t('automation.automation-notification.subject')} required={true} weight="bold" styles={{ fontSize: '12px', fontWeight:'bold' }} className="pt-8 pb-2" />

                      {mentionList.suggestions.length>0 && 
                        <HtmlEditorComponent
                          readOnly={!doData?.actionNotification?.SendEmailNotification}
                          htmlString={mentionify(doData.actionNotification?.MessageSubject ?? AUTOMATION_EMAIL.DEFAULT_SUBJECT, mentionList.suggestions)}
                          mention={mentionList}
                          oneLine={true}
                          toolbarOptions={[]}
                          toolbarInline={{
                            inDropdown: false,
                            className: undefined,
                            component: undefined,
                            dropdownClassName: undefined,
                            options: []
                          }}
                          onChange={(data: any) => {
                            //add when subject changed
                            updateDoData({
                              ...doData,
                              actionNotification: {
                                ...doData?.actionNotification,
                                MessageSubject: removeHtmlTags(dementionify(data,mentionList.suggestions))
                              }
                            });

                          }}
                          wrapperClassName="editor--no-toolbar"
                          editorClassName="editor--one-liner editor--with-mention"
                          spellCheck={true}
                        />
                      }
                      
                    </div>
                    <div className={Styles.marginY}>
                      <LabelElement label={t('automation.automation-notification.body')} weight="bold" styles={{ fontSize: '12px', fontWeight:'bold' }} className="pt-8 pb-2" />
                      {mentionList.suggestions.length>0 && 
                        <HtmlEditorComponent
                          readOnly={!doData?.actionNotification?.SendEmailNotification}
                          htmlString={mentionify(doData.actionNotification?.Message ?? AUTOMATION_EMAIL.DEFAULT_BODY, mentionList.suggestions)}
                          mention={mentionList}
                          toolbarOptions={toolbarOptions.options}
                          toolbarInline={toolbarOptions.inlineOption}
                          onChange={(data: any) => {
                            //add when message body change
                            updateDoData({
                              ...doData,
                              actionNotification: {
                                ...doData?.actionNotification,
                                Message: dementionify(data,mentionList.suggestions)
                              }
                            });
                          }}
                          wrapperClassName="htmleditor-wrapper"
                          editorClassName="editor--with-mention"
                          spellCheck={true}
                        />
                      }
                      
                    </div>
                  </div>
                </>
              )}
            </>
          )}

          <Dialog id='cardListDialog' style={cardWidth}
            content={<>
              <Text content={isTVA ? t('automation.automation-labels.card-popup-title') : "Tikit"} weight="bold" size="large" style={{position: 'absolute', top: '27px', left: '10px'}} />
              <hr style={{position: 'absolute', top: '64px', width: '100%', left: '0px'}}></hr>
              <Flex column style={{flex: 1, marginTop:'30px'}} gap="gap.small">
                <Flex.Item>
                  <Flex column>
                  <Text content={AUTOMATION_LABELS.ChooseCardHeader} weight="bold" size="large" />
                  <Text content={AUTOMATION_LABELS.ChooseCardDescription}  />
                  <Text content={AUTOMATION_LABELS.ChooseCardCopy} styles={{color:'#AC5DD9'}}  />
                  </Flex>
                </Flex.Item>
                <Flex.Item>
                  <Flex column>
                  <RadioGroup
                vertical
                checkedValue={selectedCard}
                items={cardList}
                onCheckedValueChange={(e, newProps:any) => {
                   setSelectedCard(newProps.value.toString());
                   loadCardList(newProps.value);
                   setSelectedCardData(newProps["data"]);
                }}
              />
              <div style={{height:"590px", marginTop:"20px", overflowX: "auto", clear:"both"}}>
                {selectedCardData.filter(x=> (x.type.includes("tva") && isDialogTVA) || (x.type.includes("tikit") && !isDialogTVA)).map(data=>{
                  return <div style={{float:'left', marginLeft:'7px'}}>
                    {data.description!="" && 
                      t('automation.automation-labels.'+data.description)+":"
                    }
                    <AdaptiveCardElement
                      jsonString={data.card}
                      onExecuteAction={onExecuteAction}
                      clear={true}
                      containerClassName={AutomationEditorStyle.customForm} 
                      cardProps={{ styles: { height: 'fit-content', width: '500px' } }}
                      onLoad={cardLoaded}
                    />
                    
                  </div>
                })}
                
              </div>
                  </Flex>
                </Flex.Item>
              </Flex>
              <hr style={{position: 'absolute', bottom: '82px', width: '100%', left: '0px'}}></hr>
            </>}
            open={openDialog}
            headerAction={{
                icon: <CloseIcon />,
                title: 'Close',
                onClick: () =>{setOpenDialog(false);}
            }}
            cancelButton={BUTTONS.CANCEL}
            confirmButton={BUTTONS.CONFIRM}
             onCancel={()=>{setOpenDialog(false);}}
             onConfirm={(e, newProps)=>{
              if (isDialogTVA)
              {
                updateDoData({
                  ...doData,
                  actionNotification: {
                    ...doData?.actionNotification,
                    CardNameTVA: selectedCard
                  }
                });
              }
              else
              {
                updateDoData({
                  ...doData,
                  actionNotification: {
                    ...doData?.actionNotification,
                    CardNameTikit: selectedCard
                  }
                });
              }
              setOpenDialog(false);
            }}
        />

        </div>
      )}
    />)
  );
};



import React, { useState, useEffect } from 'react';
import Styles from '../styles/Lifecycle.module.css';
import { Flex, ThemePrepared, Alert, Text } from '@fluentui/react-northstar';
import { TicketLifecyclePhaseAccordion } from './TicketLifecyclePhaseAccordion';
import { EmptyData } from '../EmptyData';
import { TicketApprovalCard } from '../EditTicketApprovals/TicketApprovalCard';
import { TicketTasksDetails } from '../TicketTasks/TicketTasksList/TicketTaskCard/TicketTasksDetails';
import AdditionalInfoIcon from '../../../../svg/additional-info-icon.svg';
import { platformService } from '../../services/platform.service';
import { TicketApprovalState, toastDefault, LIFECYCLE } from '../../utils/constants';
import { toast } from 'react-toastify';
import { TicketTasksDialog } from '../TicketTasks/TicketTasksDialog/TicketTasksDialog';
import { TicketTasksListSkeleton } from '../TicketTasks/Skeletons/TicketTasksSkeleton';
import { PlatformUser } from '../../interfaces/platformuser.interface';
import { appState} from '../../../AppState';
import { updateApproval } from '../../../tikit/ticketHelper';
import { useTranslation } from 'react-i18next';
import InfoIcon from '../../../../svg/info-icon.svg';

const initialTicketTaskDetails = {
  Title: null,
  StatusId: undefined,
  AssigneeId: undefined,
  TicketId: undefined,
  Assignee: undefined,
  Status: undefined
};
interface Props {
  phaseData: TicketLifecyclePhase;
  lifecycleStatus: LifecycleStatus;
  phaseIndex: number;
  activePhaseIndex: number;
  openPhaseDefault?: boolean;
  globalTheme: ThemePrepared<any>;
  ticketData: any;
  statuses: TaskStatus[];
  analysts: PlatformUser[];
  refreshApprovalsList: (approval: Approval, approverId: number, status: TicketApprovalState, UpdatedStatus: TicketApprovalState) => void;
  reloadPhases: () => Promise<void>;
  enableTaskEdit?: boolean;
}

export const TicketLifecyclePhaseContainer = ({
  phaseData,
  phaseData: { Id, Name, Tasks, Approvals, TransitionMessage, PowerAutomateTasks },
  lifecycleStatus,
  phaseIndex,
  activePhaseIndex,
  openPhaseDefault,
  globalTheme,
  ticketData,
  statuses,
  analysts,
  refreshApprovalsList,
  reloadPhases,
  enableTaskEdit
}: Props) => {

  const api = new platformService();
  const [isAccordionOpen, setIsAccordionOpen] = useState<boolean>(openPhaseDefault);
  const [isApprovalLoading, setIsApprovalLoading] = useState<boolean>(false);
  const [toggleExpandMessage, setToggleExpandMessage] = useState<boolean>(TransitionMessage?.length > 50 ? true : false);
  
  const [phaseTasksList, setPhaseTasksList] = useState<TicketTask[]>(Tasks);
  const [phasePowerAutomateTasksList] = useState<TicketTask[]>(PowerAutomateTasks);
  const [ticketTaskDetails, setTicketTaskDetails] = useState<TicketTask>(initialTicketTaskDetails);
  const [openTicketTasksDialog, setOpenTicketTasksDialog] = useState<boolean>(false);
  const [isTaskLoading, setIsTaskLoading] = useState<boolean>(false);
  const [showUpdateBtn, setShowUpdateBtn] = useState<boolean>(false);

  const currentState = appState();
  const {t} = useTranslation();

  const updateApprovalStatus = async (approval: Approval, approverId: number, status: TicketApprovalState) => {
    try {
      setIsApprovalLoading(true);
      const ticketLifecyclePhases = await api.getTicketLifecyclePhases(`?$filter=Id eq ${approval.TicketLifecyclePhaseId}`);
      const isCurrentPhase = ticketLifecyclePhases[0]?.IsCurrent ?? true;
      if (!isCurrentPhase) {
        toast.error(t('ticket-details.edit-ticket-form.approval.error.phase-not-active'), toastDefault);
        await reloadPhases();
        setIsApprovalLoading(false);
        return;
      }
      const result = await updateApproval(api, approval, approverId, status, t);
      if (result.IsError) {
        toast.error(result.Message, toastDefault)
      }
      else {
        refreshApprovalsList(approval, approverId, status, result.UpdatedStatus);
        toast.success(result.Message, toastDefault);
      }
    } catch (e) {
      toast.error(t('ticket-details.edit-ticket-form.approval.error.update'), toastDefault);
      console.error(e);
    }
    setIsApprovalLoading(false);
  }

  const onTaskClick = (taskDetails: TicketTask) => {
    setTicketTaskDetails({ ...taskDetails });
    setShowUpdateBtn(true);
    setOpenTicketTasksDialog(true);
  };

  const onModalClose = () => {
    setOpenTicketTasksDialog(false);
    setShowUpdateBtn(false);
    setTicketTaskDetails({ ...initialTicketTaskDetails });
  };

  const getAllTicketTasksList = async (id: number) => {
    setIsTaskLoading(true);
    try {
      const phaseTasks = currentState.ticketTask.filter(l => l.TicketLifecyclePhaseId == phaseData.Id && !l.IsPowerAutomateTask);
      setPhaseTasksList(phaseTasks);
    } catch (error) {
      console.log('Error in tickets tasks:', error);
    }
    setIsTaskLoading(false);
  };

  useEffect(() => {
    if (isAccordionOpen){
      const divElement = document.getElementById(`phase-container-${phaseData.Order}`);
      if(divElement)
        divElement.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
    }
  }, [isAccordionOpen]);
  
  return (
    <>
      <TicketLifecyclePhaseAccordion
        isAccordionOpen={isAccordionOpen}
        setIsAccordionOpen={setIsAccordionOpen}
        globalTheme={globalTheme}
        phaseData={phaseData}
        lifecycleStatus={lifecycleStatus}
        activePhaseIndex={activePhaseIndex}
      />
      {isAccordionOpen && (
          <>

          {((TransitionMessage?.length ?? 0) > 0) && (
              <Alert className="mt-2 pb-2 pt-2" warning
                header={<Flex styles={{height: '100%', alignItems: 'center'}}>
                  <InfoIcon
                    width={16}
                    height={16}
                    className={`inline-block`}
                  /></Flex>}
                content={
                <>
                  <Text weight="semibold" content={t('common.transition-reason')} />
                  <Text size='small' content={<>
                        {toggleExpandMessage ? TransitionMessage.substring(0, 50) : TransitionMessage}                                              
                        {TransitionMessage?.length > 50 &&
                        <>{toggleExpandMessage ? "..." : ""} <span className={`cursor-pointer`} style={{color: globalTheme.siteVariables.colorScheme.brand.foreground}}
                        onClick={() => {setToggleExpandMessage(!toggleExpandMessage)}}
                        >{toggleExpandMessage ? LIFECYCLE.SEE_MORE : LIFECYCLE.SEE_LESS}</span></>}
                    </>} />
                </>
              }
              />
          )}

          {(phaseTasksList.length > 0) && (
            <>
            <h2 className="mt-5"><b>{t('ticket-details.edit-ticket-form.task.title')}</b></h2>
            {isTaskLoading && (<TicketTasksListSkeleton/>)}
            {!isTaskLoading && (
              <div className={`grid grid-cols-1 gap-4 lg:grid-cols-2 phaseitem-container`}>
                  {phaseTasksList.map((task) => (
                      <div key={task.Id} className={`mt-4 ${(((phaseData.IsCurrent || phaseIndex > activePhaseIndex) && (enableTaskEdit)) ?? true) ? 'cursor-pointer' : ''}`} 
                    onClick={(e) => { 
                      if(((phaseData.IsCurrent || phaseIndex > activePhaseIndex) && (enableTaskEdit)) ?? true)
                        onTaskClick(task) 
                      else
                        e.stopPropagation();
                    }}>
                      <TicketTasksDetails ticketTask={task}/>
                    </div>
                    
                  ))}
              </div>
            )} 
            </>
          )}

          {(Approvals.length > 0) && (
            <>
            <h2 className="mt-5"><b>{t('ticket-details.edit-ticket-form.approval.title')}</b></h2>
            {isApprovalLoading && (<TicketTasksListSkeleton/>)}
            {!isApprovalLoading && (
              <div className={`grid grid-cols-1 gap-4 lg:grid-cols-2 phaseitem-container mt-2`}>
                  {Approvals.map((approval) => (
                      <TicketApprovalCard 
                        approval={approval.Approval} 
                        currentUserId={currentState.currentUserId}
                        updateApprovalStatus={updateApprovalStatus} 
                        isTicketClosed={ticketData.IsClosed}
                        isLifecycleEnabled={true} 
                        activePhaseIndex={activePhaseIndex}
                        phaseIndex = {phaseIndex}
                        enableApprovalButton = {enableTaskEdit}/>
                  ))}
              </div>
            )}  
          </>
          )}

        {(phasePowerAutomateTasksList.length > 0) && (
            <>
            <h2 className="mt-5"><b>{LIFECYCLE.POWER_AUTOMATE_TASKS}</b></h2>
            {isTaskLoading && (<TicketTasksListSkeleton/>)}
            {!isTaskLoading && (
              <div className={`grid grid-cols-1 gap-4 lg:grid-cols-2 phaseitem-container`}>
                  {phasePowerAutomateTasksList.map((task) => (
                    <TicketTasksDetails key={task.Id} ticketTask={task}/>
                  ))}
              </div>
            )} 
            </>
          )}

          <TicketTasksDialog
            open={openTicketTasksDialog}
            close={() => {
              onModalClose();
            }}
            statuses={statuses}
            analysts={analysts}
            ticketId={ticketData.Id}
            ticketTitle={ticketData.Title}
            getAllTicketList={getAllTicketTasksList}
            setIsLoading={setIsTaskLoading}
            ticketTaskDetails={ticketTaskDetails}
            showUpdateBtn={showUpdateBtn}
            setShowUpdateBtn={(value: boolean) => setShowUpdateBtn(value)}
            isLifecycle={true}
            reloadPhases={reloadPhases}
            isCurrentPhase = {phaseData.IsCurrent}
          />

          </>
      )}
    </>
  );
};


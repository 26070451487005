import React, { useEffect, useState } from 'react';
import * as microsoftTeams from "@microsoft/teams-js";
import { Switch, Route, Redirect, useLocation, useParams } from 'react-router-dom';
import { Provider, themeNames } from '@fluentui/react-teams';
import AppStateProvider, { appState, useSetState } from './AppState';
import { TicketPage } from './tikit/TicketPage';
import { SettingsPage } from './settings/Settings';
import { MeetingTicket } from './tikit/meetingDisplay/MeetingTicket';
import { MeetingDisplay } from './tikit/meetingDisplay/MeetingDisplay';
import { MyWorkPage } from './tikit/MyWork/MyWork';
import { ToastContainer } from 'react-toastify';
import AddTicket from './tikit/AddTicket';
import { platformService } from './shared/services/platform.service';
import { EditTicket } from './tikit/EditTicket';
import { SecurityPage } from './security/SecurityPage';
import AddKnowledge from './kb/AddKnowledge';
import { CheckLogin } from './shared/components/CheckLogin';
import { Requests } from './tikit/Request/Requests';
import { EditRequest } from './tikit/Request/EditRequest';
import {
  AuthorizedAdminOrAnalystsRoute,
  ChangeSkeletonVersion,
  SkeletonPage
} from './routes/AuthorizedAdminOrAnalystsRoute';
import { PageNotFound } from './browser-pages/errors/404';
import { InitialPageLoader } from './shared/components/InitialPageLoader/InitialPageLoader';
import { SubscriptionExpired } from './billing/SubscriptionExpired';
import { UnauthorizedAccessPage } from './unauthorizedAccess/unauthorizedAccess';
import { SkeletonBoard, SkeletonTable } from './shared/components/Skeleton';
import { LoadingScreen } from './shared/components/LoadingScreen';
import { EditTicketSkeleton } from './shared/components/skeletonloaders/EditTicketSkeleton';
import AppKBStateProvider from './AppKBState';
import { KBMain } from './kb/KBMain';
import { BrowserAppTemplate } from './browser-pages/BrowserAppTemplate';
import { UserForm } from './user/UserForm';
import { UserTable } from './user/UserTable';
import { TenantNotFound } from './browser-pages/errors/412';
import eventBus from './shared/services/eventBus.service';
import { AddApproval } from './shared/components/EditTicketApprovals/AddApproval';
import EmailRequest from './tikit/Request/EmailRequest';
import { Route as browserRoute } from '../route-constants'
import { Alert, AlertDismissAction } from '@fluentui/react-northstar';
import { getMyWorkLastPagePathFromStorage } from './tikit/MyWork/MyWorkList/myWorkListHelper';
import { useTranslation } from 'react-i18next';
import { LocalizationHelper } from '@microsoft/mgt-element';
import { ADMINISTRATORS, AGENTS, ANALYSTS, KNOWLEDGEAGENT, SETTINGS_PAGES } from './shared/utils/constants';
import { NewAttachmentFlowPage } from './shared/components/EditTicketAttachments/NewAttachmentFlow/NewAttachmentFlowPage';
import { NewAttachmentList } from './shared/components/EditTicketAttachments/NewAttachmentFlow/NewAttachmentList';
import { GetAiFileFlow } from './ai/GetAiFileFlow';
import CreateRequest from './tikit/Request/CreateRequest';


export const checkInTeams = (): boolean => {
  // eslint-disable-next-line dot-notation
  const microsoftTeamsLib = microsoftTeams || window['microsoftTeams'];

  if (!microsoftTeamsLib) {
    return false; // the Microsoft Teams library is for some reason not loaded
  }

  return ((window.parent === window.self && (window as any).nativeInterface) || window.name === 'embedded-page-container' || window.name === 'extension-tab-frame');
};

export const DatepickerLocalizations = {
  shortDays: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
  days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
  months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
  openCalendarTitle: 'Open calendar',
  inputPlaceholder: 'Select a date...'
};

const App = () => {
  const location = useLocation();
  const appContextState = appState();
  const setContextState = useSetState();
  const [appContext, setAppContext] = useState<microsoftTeams.app.Context>();
  const [appAppearance, setAppAppearance] = useState<themeNames>(
    themeNames.Default
  );
  const [alert, setAlert] = useState({
    show: false,
    pastDue: false
  });
  let isInTeams = checkInTeams();
  const api = new platformService();
  const { t, i18n } = useTranslation();

  const getLoader = () => {
    if (location.pathname === '/') {
      return <InitialPageLoader />;
    } else if (location.pathname.includes('/tickets/')) {
      if (location.pathname === '/tickets/board') {
        return (
          <ChangeSkeletonVersion>
            <SkeletonPage>
              <SkeletonBoard />
            </SkeletonPage>
          </ChangeSkeletonVersion>
        );
      } else if (location.pathname === '/tickets/list') {
        return (
          <ChangeSkeletonVersion>
            <SkeletonPage>
              <SkeletonTable />
            </SkeletonPage>
          </ChangeSkeletonVersion>
        );
      } else if (location.pathname === '/tickets/chart') {
        return (
          <ChangeSkeletonVersion>
            <SkeletonPage>
              <LoadingScreen message={t('ticket.loading.chart')} />
            </SkeletonPage>
          </ChangeSkeletonVersion>
        );
      } else {
        return (
          <ChangeSkeletonVersion>
            <EditTicketSkeleton />
          </ChangeSkeletonVersion>
        );
      }
    } else if (location.pathname === browserRoute.myWork.pagePath) {
      return (
        <ChangeSkeletonVersion>
          <SkeletonPage>
            <SkeletonBoard />
          </SkeletonPage>
        </ChangeSkeletonVersion>
      );
    }

    return (<InitialPageLoader />);
  };

  useEffect(() => {
    let unmounted = false;
    let isDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches
    const storedTheme = localStorage.getItem('TikitTheme');
    const storedLanguages = localStorage.getItem('TikitLanguages');
    if (storedLanguages) {
      const languages = JSON.parse(storedLanguages) as Language[];
      const defaultLanguage = languages.filter(x => x.isDefault)?.[0];
      i18n.changeLanguage(defaultLanguage?.name);

      const lang = i18n.getResourceBundle(i18n.resolvedLanguage, '');
      if (lang && lang["graph-toolkit"])
        LocalizationHelper.strings = lang["graph-toolkit"];

      if (lang && lang["calendar"]) {
        const calendar = lang["calendar"];
        DatepickerLocalizations.shortDays = [
          calendar.short.sunday,
          calendar.short.monday,
          calendar.short.tuesday,
          calendar.short.wednesday,
          calendar.short.thursday,
          calendar.short.friday,
          calendar.short.saturday
        ];
        DatepickerLocalizations.days = [
          calendar.long.sunday,
          calendar.long.monday,
          calendar.long.tuesday,
          calendar.long.wednesday,
          calendar.long.thursday,
          calendar.long.friday,
          calendar.long.saturday
        ];
        DatepickerLocalizations.months = [
          calendar.month.jan,
          calendar.month.feb,
          calendar.month.mar,
          calendar.month.apr,
          calendar.month.may,
          calendar.month.june,
          calendar.month.july,
          calendar.month.aug,
          calendar.month.sept,
          calendar.month.oct,
          calendar.month.nov,
          calendar.month.dec
        ];
        DatepickerLocalizations.openCalendarTitle = calendar.openCalendarTitle;
        DatepickerLocalizations.inputPlaceholder = calendar.inputPlaceholder;
      }
    }

    if (storedTheme) {
      if (storedTheme === "system") {
        if (isDarkMode) {
          setAppAppearance(themeNames.Dark);

          setContextState(preState => ({
            ...preState,
            theme: themeNames.Dark
          }));
        } else {
          setAppAppearance(themeNames.Default);

          setContextState(preState => ({
            ...preState,
            theme: themeNames.Default
          }));
        }
      } else if (storedTheme === "light") {
        setAppAppearance(themeNames.Default);

        setContextState(preState => ({
          ...preState,
          theme: themeNames.Default
        }));
      } else {
        setAppAppearance(themeNames.Dark);

        setContextState(preState => ({
          ...preState,
          theme: themeNames.Dark
        }));
      }
    }
    /**
     * With the context properties in hand, your app has a solid understanding of what's happening around it in Teams.
     * https://docs.microsoft.com/en-us/javascript/api/@microsoft/teams-js/context?view=msteams-client-js-latest&preserve-view=true
     **/
    microsoftTeams.app.getContext().then(context => {
      localStorage.setItem('___teamsTheme', context.app.theme);
      setAppContext(context);
      setAppAppearance(initTeamsTheme(context.app.theme));
      

      setContextState(preState => ({
        ...preState,
        isSidenavCollapsed: true,
        theme: initTeamsTheme(context.app.theme)
      }));
      /**
       * Tells Microsoft Teams platform that we are done saving our settings. Microsoft Teams waits
       * for the app to call this API before it dismisses the dialog. If the wait times out, you will
       * see an error indicating that the configuration settings could not be saved.
       **/
      microsoftTeams.app.notifySuccess();
    });

    /**
     * Theme change handler
     * https://docs.microsoft.com/en-us/javascript/api/@microsoft/teams-js/microsoftteams?view=msteams-client-js-latest#registerOnThemeChangeHandler__theme__string_____void_
     **/
    microsoftTeams.app.registerOnThemeChangeHandler(theme => {
      localStorage.setItem('___teamsTheme', theme);
      setAppAppearance(initTeamsTheme(theme));
    });

    return () => {
      unmounted = true;
    };
  }, []);

  const overlicenseCheck = async () => {
    const analystLicense = await api.getLicensedAnalyst();
    setAlert(state => ({ ...state, show: (analystLicense.data.isOverage) }));
  };
  const closeOverlicense = async () => {
    setAlert(state => ({ ...state, show: false }));
  };
  const setPastDue = async () => {
    setAlert(state => ({ ...state, pastDue: true }));
  };
  const closePastDue = async () => {
    setAlert(state => ({ ...state, pastDue: false }));
  }

  const myRoles = async () => {
    const gettingRolesResponse = await api.GetMyApplicationRoles();
    const roles = gettingRolesResponse.data.value;
    return roles;
  };

  const meAuthentication = async () => {
    setContextState(preState => ({
      ...preState,
      userRoles: {
        ...preState.userRoles,
        isLoading: true,
        isUnauthorized: true
      }
    }));
    try {
      const { data } = await api.me();

      const userIdClaim = data.Claims.find((claim: any) => claim.startsWith('Cireson.Platform.UserId:'));
      const platformUserId = parseInt(userIdClaim?.split('Cireson.Platform.UserId: ')[1] ?? '0', 10);

      setContextState(preState => ({
        ...preState,
        userRoles: {
          ...preState.userRoles,
          roles: data?.Roles,
          isLoading: false,
          isUnauthorized: false
        },
        currentUserId: platformUserId
      }));
    } catch (err) {
      setContextState(preState => ({
        ...preState,
        userRoles: {
          ...preState.userRoles,
          isLoading: false
        }
      }));
      console.error(err);
    }
  };

  const loadPage = async () => {
    await meAuthentication();

    const getRoles = async () => {
      try {
        const r = await myRoles();
        sessionStorage.setItem('my_application_roles', JSON.stringify(r));
        sessionStorage.setItem('refresh_app_roles', 'false');
      } catch {
        sessionStorage.setItem('my_application_roles', '{}');
      }
      eventBus.dispatch('applicationRolesUpdated', { key: 'my_application_roles' });
    };

    const refresh = sessionStorage.getItem('refresh_app_roles');
    const roles = sessionStorage.getItem('my_application_roles');
    if (refresh === 'true' || !roles || roles === '{}' || roles === '[]') {
      await getRoles();
    } else {
      try { JSON.parse(roles); }
      catch { await getRoles(); }
    }

    await overlicenseCheck()
  };

  return (
    <>
      <Provider themeName={appAppearance} lang="en-US">
        <CheckLogin onSignedIn={loadPage} onBillingIssue={setPastDue}>
          {appContextState.userRoles.isLoading ? (
            getLoader()
          ) : appContextState.userRoles.isUnauthorized ? (
            <UnauthorizedAccessPage />
          ) : (
            <>
            {alert.show && (  
            <Alert content={
              <div style={{width: '75%', textAlign: 'center', marginLeft: 'auto', marginRight: 'auto'}}>
                ATTENTION: Your Tikit subscription is currently in READ-ONLY mode due to your subscription having more licensed agents then your current subscription allows. 
                To re-enable Tikit, please go to the <a href='/settings/billing' style={{fontWeight: 'bold', textDecoration: 'underline'}}>Billing</a> page to update your subscription details and either increase the number of agent licenses
                or re-allocate the licensed agents.
              </div>} variables={{ urgent: true }} styles={{ padding: '5px'}} dismissible={true} dismissAction={<AlertDismissAction onClick={closeOverlicense} />} />
            )}
            {alert.pastDue && (  
            <Alert content={
              <div style={{width: '75%', textAlign: 'center', marginLeft: 'auto', marginRight: 'auto'}}>
                ATTENTION: Your Tikit subscription is currently active, but we need some additional information to complete a recently attempted payment.
                Please go to the <a href='/settings/billing' style={{fontWeight: 'bold', textDecoration: 'underline'}}>Billing</a> page and select "Retry Payment" to try your payment method again or select "Manage Billing" to update your billing details.
              </div>} variables={{ urgent: true }} styles={{ padding: '5px'}} dismissible={true} dismissAction={<AlertDismissAction onClick={closePastDue} />} />
            )}
            <Switch>
              <AuthorizedAdminOrAnalystsRoute exact path="/" 
                component={appContextState.userRoles.roles.includes(KNOWLEDGEAGENT) && !appContextState.userRoles.roles.some(r => [ADMINISTRATORS, ANALYSTS, AGENTS].includes(r)) ? KnowledgePage : TicketPage} />
              <Route exact path="/expired" component={SubscriptionExpired} />
              <Route exact path="/requests" component={Requests} />
              <Route exact path="/request" component={Requests} />
              <Route exact path="/requests/:id" component={EditRequest} />
              <Route exact path="/request/:id" component={EditRequest} />
              <Route exact path="/knowledge/:type/:itemId" component={CreateRequest} />
              <Route exact path="/emailrequest" component={EmailRequest} />
              <Route exact path="/addattachment/:ticketId/:isEndUserPage" component={NewAttachmentFlowPage} />
              <Route exact path="/listattachments/:ticketId/:isEndUserPage" component={NewAttachmentList} />
              <Route exact path="/getaifile/:container/:fileName" component={GetAiFileFlow} />
              <AuthorizedAdminOrAnalystsRoute exact path="/tickets/list" component={TicketPage} />
              <AuthorizedAdminOrAnalystsRoute exact path="/tickets/board" component={TicketPage} />
              <AuthorizedAdminOrAnalystsRoute exact path="/tickets/chart" component={TicketPage} />
              <AuthorizedAdminOrAnalystsRoute exact path="/tickets/:ticketId" component={EditTicket} />
              <AuthorizedAdminOrAnalystsRoute exact path={`${browserRoute.myWork.editTicket}/:ticketId`} component={EditTicket} />
              <AuthorizedAdminOrAnalystsRoute exact path={browserRoute.myWork.list} component={MyWorkPage} />
              <AuthorizedAdminOrAnalystsRoute exact path={browserRoute.myWork.board} component={MyWorkPage} />
              <Redirect to={getMyWorkLastPagePathFromStorage(false) || browserRoute.myWork.list} from={browserRoute.myWork.pagePath} />
              <AuthorizedAdminOrAnalystsRoute exact path={browserRoute.myGroupWork.list} component={MyWorkPage} />
              <AuthorizedAdminOrAnalystsRoute exact path={browserRoute.myGroupWork.board} component={MyWorkPage} />
              <AuthorizedAdminOrAnalystsRoute exact path={`${browserRoute.myGroupWork.editTicket}/:ticketId`} component={EditTicket} />
              <Redirect to={getMyWorkLastPagePathFromStorage(true) || browserRoute.myGroupWork.list} from={browserRoute.myGroupWork.pagePath} />
              <AuthorizedAdminOrAnalystsRoute exact path="/addticket" component={AddTicket} />
              <AuthorizedAdminOrAnalystsRoute exact path="/addknowledge" component={AddKnowledge} />
              <AuthorizedAdminOrAnalystsRoute exact path="/knowledge" component={KnowledgePage} />
              <AuthorizedAdminOrAnalystsRoute exact path="/users" component={UserPage} />
              <AuthorizedAdminOrAnalystsRoute exact path="/users/:userId" component={UserPage} />
              <AuthorizedAdminOrAnalystsRoute exact path="/settings" component={SettingsPage} />
              <AuthorizedAdminOrAnalystsRoute exact path="/addapproval/:ticketId" component={AddApproval} />
              <Route exact path="/settings/security">
                <Redirect to="/settings/security/administrators" />
              </Route>
              <AuthorizedAdminOrAnalystsRoute exact path="/settings/:settingsKey" component={SettingsPage} />
              <AuthorizedAdminOrAnalystsRoute exact path="/settings/:settingsKey/:securityKey" component={SecurityPage} />
              {isInTeams && (
                <>
                  <Route exact path="/meetingticket/:ticketId" component={MeetingTicket} />
                  <Route exact path="/meetingdisplay" component={MeetingDisplay} />
                </>
              )}
              <Route exact path="/412" component={TenantNotFound} />
              <Route exact path="/404" component={PageNotFound} />
              <Redirect to="/404" />
            </Switch>
            </>
          )}
        </CheckLogin>
      </Provider>
      <ToastContainer />
    </>
  );
};

export default App;

// Possible values for theme: 'default', 'light', 'dark' and 'contrast'
function initTeamsTheme(theme: string | undefined): themeNames {
  switch (theme) {
    case 'dark':
      return themeNames.Dark;
    case 'contrast':
      return themeNames.HighContrast;
    default:
      return themeNames.Default;
  }
}

export const KnowledgePage: React.FC = () => (<BrowserAppTemplate page={<AppKBStateProvider><KBMain /></AppKBStateProvider>} active={SETTINGS_PAGES.KNOWLEDGE} hideSettingsNav={true} />);
export const UserPage: React.FC = () => {
  const query = new URLSearchParams(useLocation().search);
  let { userId } = useParams<any>();
  userId ??= query.get('id');

  return (<BrowserAppTemplate page={userId ? (<UserForm {...{ id: userId }} />) : (<UserTable />)} active={SETTINGS_PAGES.USERS} hideSettingsNav={true} />);
}
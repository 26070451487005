import { Flex } from '@fluentui/react-northstar';
import React from 'react';
import { EmptyData } from '../EmptyData';
import EmptySVG from '../../../../svg/empty.svg';
import { TicketApprovalCard } from './TicketApprovalCard';
import {ADD_APPROVALS_NOTE, TicketApprovalState} from '../../utils/constants';
import { useTranslation } from 'react-i18next';

interface Props {
  ticketApprovals: TicketApprovals[];
  currentUserId: number;
  updateApprovalStatus?: (ticketApprovalData: Approval, approverId: number, status: TicketApprovalState) => void;
  isTicketClosed: boolean;
  isLifecycleEnabled?: boolean;
  isEndUser?: boolean;
  disableButonOnUpdate?: boolean;
}

export const TicketApprovalsList = ({ticketApprovals, currentUserId, updateApprovalStatus, isTicketClosed, isLifecycleEnabled = false, disableButonOnUpdate = false, isEndUser = false }: Props) => {
  const {t} = useTranslation();  
  return (
    <div className='mt-2'>
      {ticketApprovals?.length > 0 ?
        (ticketApprovals.map((approval: TicketApprovals) => (
          <TicketApprovalCard 
            key={approval.Approval.Id}
            approval={approval.Approval}
            currentUserId={currentUserId}
            updateApprovalStatus={updateApprovalStatus}
            isTicketClosed={isTicketClosed}
            isLifecycleEnabled={isLifecycleEnabled}
            disableButonOnUpdate={disableButonOnUpdate}
          />
        ))) : 
        <Flex className='mt-5' >
          <Flex.Item grow className="pt-0 overflow-auto"></Flex.Item>
          <EmptyData
            subheaderText={isEndUser ? '' : ADD_APPROVALS_NOTE}
            headerText={t('ticket-details.edit-ticket-form.approval.empty-approval-list')}
            SVGIcon={<EmptySVG width={75} height={75} />}
          />
        </Flex>
      }
    </div>
  );
};

import {
  Alert,
  Dropdown,
  Flex,
  ProviderConsumer,
  Text,
  TextArea
} from '@fluentui/react-northstar';
import React, { useEffect, useState } from 'react';
import { threedigitized } from '../../../../tikit/ticketHelper';
import { NestedDropdown } from '../../NestedDropdown/NestedDropdown';
import TicketSVG from '../../../../../svg/ticket.svg';
import './TicketTasksDialog.css';
import { ThemeColorScheme } from '../../../common/TeamsTheme';
import { LabelElement } from '../../TicketForm';
import { PlatformUser } from '../../../interfaces/platformuser.interface';
import { useTranslation } from 'react-i18next';

interface Props {
  statuses: TaskStatus[];
  analysts: PlatformUser[];
  ticketId: number;
  ticketTitle: string;
  setFormObj: any;
  formObj: any;
  alert: any;
  isCurrentPhase: boolean;
}

export const TicketTasksForm = ({
  statuses,
  analysts,
  ticketId,
  ticketTitle,
  setFormObj,
  formObj,
  alert,
  isCurrentPhase
}: Props) => {
  const [analystsItems, setAnalystsItems] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  const { t } = useTranslation();

  const findDefaultValue = (itemsArray: any[]) => {
    return itemsArray.find(item => item.IsDefault);
  };

  useEffect(() => {
    const items = formatAssignees(analysts).map((user: any) => {
      if (user.accessibilityitemprops.id === formObj?.Assignee?.Id) {
        user.selected = true;
        setSearchQuery(formObj.Assignee.FullName);
      } else {
        user.selected = false;
      }
      return user;
    });
    setAnalystsItems([...items]);
  }, [analysts, formObj]);

  const formatAssignees = (items: any[]) => {
    return items.map((item: any) => {
      return {
        header: item['FullName'],
        accessibilityitemprops: { id: item['Id'] },
        selected: false
      };
    }).filter(u => u.accessibilityitemprops.id != -1);
  };

  return (
    <ProviderConsumer
      render={globalTheme => (
        <>
          <div className="dialog-header font-bold" style={{ marginTop: '-30px' }}>
            <Flex vAlign="center">
              <TicketSVG width={24} className={`mr-1`} />
              <Text style={{ color: globalTheme.siteVariables.colorScheme.brand.foreground }}>
                #{threedigitized(ticketId)}
              </Text>
            </Flex>
            <div className={`py-2 ticketTitle`} style={{ fontSize: '12px', ...ThemeColorScheme(globalTheme.siteVariables) }}>
              {ticketTitle}
            </div>
          </div>
          <div>
            <div className={`my-2 font-bold`}>{`${(formObj.Id > 0) ? t('ticket-details.edit-ticket-form.task.dialog.update-task') : t('ticket-details.edit-ticket-form.task.dialog.add-task')}`}</div>
            <div className={`my-2`}>
              {alert.show && (
                <Alert
                  fitted
                  {...alert.attributes}
                  content={alert.title}
                  style={{ fontSize: 12, width: '100%' }}
                />
              )}
            </div>
            <div>
              <div className={`mt-2`} style={{ marginBottom: '-0.25rem' }}>
                <LabelElement label={t('ticket-details.edit-ticket-form.task.fields.title')} required={true}>
                  <TextArea
                    fluid
                    maxLength={500}
                    required
                    style={{ height: 60 }}
                    onChange={(_e, data) => {
                      setFormObj({ ...formObj, Title: data.value });
                    }}
                    value={formObj.Title}
                  />
                </LabelElement>
              </div>

              {(isCurrentPhase) && (
                <div className={`mt-2`}>
                  <LabelElement label={t('ticket-details.edit-ticket-form.task.fields.status')}>
                    <NestedDropdown
                      items={statuses.map((item: TaskStatus) => ({
                        id: item.Id,
                        parentId: item.ParentId,
                        position: item.Position,
                        value: item.Value,
                        ...item
                      }))}
                      dataItem={formObj?.Status}
                      defaultValue={findDefaultValue(statuses)?.Value}
                      onChange={(value: any) => {
                        setFormObj({
                          ...formObj,
                          Status: value,
                          StatusId: value.Id
                        });
                      }}
                    />
                  </LabelElement>
                </div>
              )}

              <div className={`mt-2`}>
                <LabelElement label={t('ticket-details.edit-ticket-form.task.fields.assignee')}>
                  <Dropdown
                    style={{ backgroundColor: globalTheme.siteVariables.colorScheme.default.background2 }}
                    items={analystsItems}
                    search
                    fluid
                    checkable
                    clearable
                    value={analystsItems.filter(t => t.selected)}
                    searchQuery={searchQuery}
                    onSearchQueryChange={(_e, data) => {
                      setSearchQuery(data.searchQuery);
                    }}
                    onChange={(_event, p: any) => {
                      const selectedUserId = p?.value?.accessibilityitemprops?.id;
                      const assignee = analysts.find(o => o.Id == selectedUserId);
                      const analystsArray = analystsItems.map((item: any) => {
                        item.selected = item.accessibilityitemprops.id == selectedUserId;
                        return item;
                      });
                      setAnalystsItems(analystsArray);
                      setSearchQuery(p?.value?.header);
                      setFormObj({ ...formObj, AssigneeId: assignee?.Id, Assignee: assignee });
                    }}
                    noResultsMessage={
                      <div style={{ textAlign: 'center' }}>
                        <Text content={t('ticket.add-ticket.user-not-found')} />
                      </div>
                    }
                  />
                </LabelElement>
              </div>
            </div>
          </div>
        </>
      )}
    />
  );
};

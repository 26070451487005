import React, { useContext, useReducer, useState } from 'react';
import {
  Dialog,
  Alert,
  AddIcon,
  Button,
  ThemePrepared,
  Flex,
  Text
} from '@fluentui/react-northstar';
import _ from 'lodash';
import AppCSS from '../App.module.css';
import TicketForm, {
  ActionTypes,
  entityTicketModel,
  entityTicketReducer,
  TicketActions,
  TicketEntityProps,
  UnitOfWork,
} from '../shared/components/TicketForm';
import { Toast } from '../shared/components/toast/Toast';
import { AppStateValue, useSetState } from '../AppState';
import { Ticket } from '../shared/interfaces/ticket.interface';
import { PlatformUser } from '../shared/interfaces/platformuser.interface';
import { platformService } from '../shared/services/platform.service';
import { FILTERS_STRINGS } from '../shared/utils/constants';
import { ListContext } from './List/ListContextProvider';
import ListFunctions from './List/ListFunctions';
import { CustomViewContext } from './toolbar/CustomViewContextProvider';
import { toolbarButtonColor } from './ticketHelper';
import { useTranslation } from 'react-i18next';
import { FontIcon } from '@fluentui/react';
import { sendRequestToApplyLifecycle, TemplateListDialog } from '../templates/TemplateListDialog';
import FilledFileIcon from '../../../src/svg/filled-file-icon.svg';
import LifecycleIcon from '../../../src/svg/lifecycle-dialog-icon.svg';
import { getRelativeDateValue } from '../shared/components/EditTicketForm';

const api = new platformService();
interface Props {
  globalTheme: ThemePrepared<any>;
  page: string;
  isUserLicensed: boolean;
}

export const AddTicketDialog = ({ globalTheme, page, isUserLicensed }: Props) => {
  const { t } = useTranslation();
  const [showAddTicketDialog, setShowAddTicketDialog] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [openTemplateList, setOpenTemplateList] = useState(false);
  const setState = useSetState();
  const { listStates, setListStates } = useContext(ListContext);
  const { customViewStates } = useContext(CustomViewContext);
  const { _getpaginatedTicketsPromise } = ListFunctions();
  const [ticketData, dispatch] = useReducer(entityTicketReducer, entityTicketModel());
  const [templateList, setTemplateList] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState<any>({});
  const [showTemplateAlert, setShowTemplateAlert] = useState<boolean>(false);
  const [alert, setAlert] = useState({
    title: '',
    show: false,
    attributes: {},
  });

  const onOpen = async () => {
    setSelectedTemplate({});
    dispatch(TicketActions.ClearData);    
    let templates = await api.getTicketTemplates(`${FILTERS_STRINGS.FILTERS_URL_PARAMS}&$expand=Lifecycle($expand=Phases),Tags,AffectedUsers,Collaborators&$orderby=Name`);
    setTemplateList(templates.data.value);
    setAlert({ title: '', show: false, attributes: {} });
  };



  const validateRequired = () => {
    setAlert({ title: '', show: false, attributes: {} });
    if (!ticketData.Entity.Title || ticketData.Requester === null || !ticketData.Entity.TeamId) {
      setAlert({ title: t('ticket-details.edit-ticket-view.required-field-must-be-filled-out'), show: true, attributes: { danger: true } });
      return true;
    }
    return false;
  };

  const skeletonLoading = (transactionId: any, entity: any) => {
    const ticket = {
      ...entity,
      Id: transactionId,
      Requester: { Value: '' },
      Status: { Value: '' },
      Priority: { Value: '' },
      Category: { Value: '' },
      Title: `temp`,
      TicketSlas: [],
      TicketCollaborators: []
    };

    if (page === 'board') {
      setState((_state: AppStateValue) => {
        // create new reference to heap to make sure state was changed.
        const tickets: Ticket[] = _state.tickets.filter(() => true);
        tickets.unshift(ticket);

        return {
          ..._state,
          tickets: tickets,
          reloadBoardOnAddTicket: true
        };
      });
    } else{
      setListStates((prev: any) => {
        let updatedTickets = listStates.tickets;
        updatedTickets.unshift(ticket);
  
        return {
          ...prev,
          tickets: updatedTickets,
          countTickets: prev.countTickets + 1
        };
      });
    }
  };

  const removeSkeletonLoading = (transactionId: number) => {
    if (page === 'board') {
      setState((_state: AppStateValue) => {
        // create new reference to heap to make sure state was changed.
        const tickets: Ticket[] = _state.tickets.filter(
          ({ Id }) => Id !== transactionId
        );

        return {
          ..._state,
          tickets: tickets
        };
      });
    } else {
      setListStates((prev: any) => {
        // create new reference to heap to make sure state was changed.
        const tickets: Ticket[] = prev.tickets.filter(
          ({ Id }) => Id !== transactionId
        );

        return {
          ...prev,
          tickets: tickets,
        };
      });
    }
  };

  const updateBoard = async (
    ticket: Ticket,
    user: any,
    transactionId: number
  ) => {
    const _ticketsApiFunction =
      page === 'board' ? api.getTicketList(`?$orderby=CreatedDate desc`) : _getpaginatedTicketsPromise({ page: listStates.currentPage, customViewStates});
    try {
      const result = await Promise.all([
        api.getTicketSla(FILTERS_STRINGS.TICKET_SLA_FILTER),
        _ticketsApiFunction
      ]);
      const ticketsSla: TicketSla[] = result[0];

      if (page === 'board') {
        const [closed, active] = _.partition(result[1].data.value, (item) => item.Closed);
        setState((prev: AppStateValue) => {
          let thisTickets = active.map((t) => ({
            ...t,
            TicketSlas: ticketsSla.filter((sla) => sla.TicketId === t.Id),
          }));
          let thisClosed = closed.map((t) => ({
            ...t,
            TicketSlas: ticketsSla.filter((sla) => sla.TicketId === t.Id),
          }));
          
          if(ticket.Closed){
            thisClosed = thisClosed.filter(({ Id }) => Id !== transactionId);
            thisClosed.unshift(ticket);
          }else{
            thisTickets = thisTickets.filter(({ Id }) => Id !== transactionId);
            thisTickets.unshift(ticket);
          }
          return {
            ...prev,
            tickets: thisTickets,
            closed: thisClosed, 
            reloadBoardOnAddTicket: true 
          };
        });
      } else {
        const updatedTickets = result[1].data;
        setListStates((prev) => {
          const tickets: Ticket[] = prev.tickets.filter(
            ({ Id }) => Id !== transactionId
          );
          tickets.unshift(ticket);

          return {
            ...prev,
            countTickets: updatedTickets['@odata.count'],
            tickets: updatedTickets.value.map((t) => ({
              ...t,
              TicketSlas: ticketsSla.filter((sla) => sla.TicketId === t.Id),
            })),
          };
        });
      }

      setState((prev: AppStateValue) => {
        const platformUsers: PlatformUser[] = prev.platformusers.filter(
          ({ Id }) => Id !== transactionId
        );

        const platformUserIds = platformUsers.map((platformUser) => platformUser.Id);
        if (!platformUserIds.includes(user.Id)) {
          platformUsers.push(user as PlatformUser);
        }

        return {
          ...prev,
          platformUsers: platformUsers,
        };
      });
    } catch (err) {
      console.error(err);
      errorHandler(transactionId);
    }
    setIsSaving(false);
  };

  const errorHandler = (transactionId) => {
    removeSkeletonLoading(transactionId);
    Toast.error(t('ticket-details.edit-ticket-view.failed-to-create'));
    setIsSaving(false);
  };

  const attachTags = async (ticketId: number, tags: any[]) => {
    if (tags?.length > 0) {
      try {
        await UnitOfWork.AttachTagsToTicket(ticketId, tags, t);
      } catch (e) {
        console.error(e);
        Toast.error('Tags failed to attached');
      }
    }
  };

  const attachCollaborator = async (ticketId: number, collaboraterIds: any[]) => {
    if (collaboraterIds?.length > 0) {
      try {
        await UnitOfWork.AttachCollaboratorsToTicket(ticketId, collaboraterIds);
      } catch (e) {
        console.error('AttachCollaboratorToTicket error:: ', e);
        Toast.error('Collaborators failed to attached');
      }
    }
  };

  const attachAffectedusers = async (ticketId: number, affectedUsers: any[]) => {
    if (affectedUsers?.length > 0) {
      try {
        await UnitOfWork.AttachAffectedUsersToTicket(ticketId, affectedUsers);
      } catch (e) {
        Toast.error('Affected users failed to attached');
        console.error('AttachAffectedUsersToTicket error:: ', e);
      }
    }
  };

  const submit = async () => {
    if (validateRequired()) return;
    setShowAddTicketDialog(false);

    const transactionId = Math.random();
    setIsSaving(true);
    skeletonLoading(transactionId, ticketData.Entity);

    const affectedUsers = ticketData.Entity.AffectedUsers;
    const collaboratorsEmails = ticketData.Entity.Collaborators;
    delete ticketData.Entity.AffectedUsers;
    delete ticketData.Entity.Collaborators;

    try {
      const result: any = await UnitOfWork.AddTicket(ticketData);
      if (result.ticket !== null) {
        let ticketId = result.ticket.Id;
        await Promise.all([
          ((ticketData.Entity?.Tags?.length ?? 0) > 0) ? UnitOfWork.AttachTagsToTicket(ticketId, ticketData.Entity.Tags, t) : null,
          ((affectedUsers?.length ?? 0) > 0) ? UnitOfWork.AttachAffectedUsersToTicket(ticketId, affectedUsers.map(u => ({ PlatformUserId: u.Id }))) : null,
          ((collaboratorsEmails?.length ?? 0) > 0) ? UnitOfWork.AttachCollaboratorsToTicket(ticketId, collaboratorsEmails.map(u => ({ PlatformUserId: u }))) : null,
          t
        ].filter(o => o !== null));

        if(ticketData.LifecycleId){
          var lifecycleAppliedId = await sendRequestToApplyLifecycle(ticketData.LifecycleId, ticketId);
          if(lifecycleAppliedId > 0){
            ticketData.SelectedTemplate.Lifecycle.Phases.map(x => {
              x["IsCurrent"] = x.Order == 0;
            });
            result.ticket["TicketLifecycle"] = ticketData.SelectedTemplate.Lifecycle;
          }
        }

        updateBoard(result.ticket, result.requester, transactionId);
        Toast.success(t('ticket.add-ticket.ticket-added', { ticketId: result.ticket.Id }));

        if (!ticketData.SelectedTemplate.NoCustomForm && ticketData.SelectedTemplate.ClearFormAnswer == true) {
          let templateId = ticketData.SelectedTemplate.Id;
          await api.sendCustomFormToConversation({ TicketId: ticketId, TemplateId: templateId, IsShowTyping: true });
        }
      } else {
        errorHandler(transactionId);
      }
    } catch (e) {
      console.error(e);
      errorHandler(transactionId);
    }

    dispatch({action:TicketActions.ClearData});
    setSelectedTemplate({});
  };

  
  const closeApplyTemplate = () => {
    setOpenTemplateList(false);
  };

  const applyTemplate = () => {

    setOpenTemplateList(false);

    setState((_state: AppStateValue) => {

      for(let prop in ticketData.SelectedEnums)
      {
          let listItem = [];
          switch(prop){
            case TicketActions.StatusId:
              listItem = _state.ticketStatus;
              break;
            case TicketActions.CategoryId:
              listItem = _state.categories;
              break;
            case TicketActions.PriorityId:
              listItem = _state.priority;
              break;
            case TicketActions.TicketTypeId:
              listItem = _state.ticketTypes;
              break;
          }
          
          dispatch({
            action: TicketActions.SelectedEnums, 
            property:prop,
            data: listItem.find(x=>x.Id==ticketData.SelectedTemplate[prop])
          });
      }

      dispatch({action:TicketActions.TemplateId, isEntity:true, data: ticketData.SelectedTemplate[TicketActions.Id]});

      let dueDate = ticketData.SelectedTemplate[TicketActions.DueDate];
      let relativeDate = ticketData.SelectedTemplate[TicketActions.RelativeDueDate];
      if (dueDate != null || relativeDate !=null){
        if(dueDate == null && relativeDate !=null)
          dueDate = getRelativeDateValue(relativeDate).toISO();
        dispatch({action:TicketActions.DueDate, isEntity:true, data: (dueDate !=null ? new Date(dueDate) : null)});
      }

      let assignee = ticketData.SelectedTemplate[TicketActions.AssigneeId];
      if (assignee!=null)
        dispatch({action:TicketActions.AssigneeId, isEntity:true, data: assignee});

      let supportId = ticketData.SelectedTemplate[TicketActions.SupportGroupId];
      if (supportId!=null)
        dispatch({action:TicketActions.SupportGroupId, isEntity:true, data: supportId});

      let teamId = ticketData.SelectedTemplate[TicketActions.TeamId];
      if (teamId!=null)
        dispatch({action:TicketActions.TeamId, isEntity:true, data: teamId});

      let lifeCycleId = ticketData.SelectedTemplate[TicketActions.LifecycleId];
      if (lifeCycleId!=null)
        dispatch({action:TicketActions.LifecycleId, isEntity:false, data: lifeCycleId});
      
      if (ticketData.SelectedTemplate.AffectedUsers && ticketData.SelectedTemplate.AffectedUsers.length > 0) {
        const picker: any = document.getElementById('mgt-affected-users');
        if (picker) 
          picker.selectedPeople = ticketData.SelectedTemplate.AffectedUsers.map((i: any) => ({ displayName: i.FullName, userPrincipalName: i.UserName, id: i.AadObjectId }));
        
        dispatch({ action: TicketActions.AffectedUsers, isEntity: true, data: ticketData.SelectedTemplate.AffectedUsers });
      }
      
      if (ticketData.SelectedTemplate.Collaborators && ticketData.SelectedTemplate.Collaborators.length > 0)
        dispatch({ action: TicketActions.Collaborators, isEntity: true, data: ticketData.SelectedTemplate.Collaborators.map(u => u.Id) });

      if(!ticketData.SelectedTemplate.NoCustomForm){
        dispatch({action:TicketActions.CardTemplateJson, isEntity:true, data: ticketData.SelectedTemplate[TicketActions.CardResponseJson]});
        dispatch({action:TicketActions.CardAnswerJson, isEntity:true, data: ticketData.SelectedTemplate[TicketActions.CardAnswerJson]});
      }

      dispatch({action:TicketActions.ApplyTemplateNow, data: true});
      //Apply selected template for show name of the selected template on the dialog  
      setSelectedTemplate(ticketData.SelectedTemplate);
      setShowTemplateAlert(true);

      return {
        ..._state
      };
    });

  }

  const resetForm = () => {
    dispatch({action:TicketActions.ClearData});
    dispatch({action:TicketActions.ResetForm, data: true});
    setSelectedTemplate({});
    setShowTemplateAlert(false);

    let picker: any = document.getElementById('mgt-affected-users');
    if (picker) picker.selectedPeople = [];

    picker = document.getElementById('mgt-requester');
    if (picker) picker.selectedPeople = [];
  }

  const templateAlert = (lifecycleName: string) => {
    return (
      <span>
        {t('ticket-details.edit-ticket-view.change-and-reaplce-apply-template', {templateName: selectedTemplate?.Name})}
        <span className={`cursor-pointer`} style={{color: globalTheme.siteVariables.colorScheme.brand.foreground}} onClick={() => {
          setOpenTemplateList(true)
        }}>
          {t('ticket-details.edit-ticket-view.template')}
        </span>
        {t('ticket-details.edit-ticket-view.replace-template')}
      </span>
    )
  }

  return (
    <>
      <Dialog
        className={`responsive-dialog`}
        style={{ width: 600, overflowX: 'hidden' }}
        closeOnOutsideClick={false}
        cancelButton={{ content: t('ticket-details.edit-ticket-view.cancel') }}
        confirmButton={{
          content: t('ticket-details.edit-ticket-view.create'),
          primary: true,
        }}
        onConfirm={submit}
        onCancel={() => {
          setShowAddTicketDialog(false);
        }}
        open={showAddTicketDialog}
        onOpen={onOpen}
        content={
          <div className={`${AppCSS['addTicketDialogForm']}`}>
            <TicketForm type={ActionTypes.Create} data={ticketData} dispatch={dispatch}/>
          </div>
        }
        header={{
          content: (
            <>
            <Flex space='between' vAlign='center'>
              <Flex.Item>
                <h3 style={{ marginTop: 0 }}>{t('ticket-details.edit-ticket-view.new-ticket')}</h3>
              </Flex.Item>
              <Flex.Item>
                <Flex styles={{width: '80%' }}>
                  {
                    selectedTemplate?.LifecycleId && <Flex.Item>
                      <Button 
                        disabled
                        text
                        icon={<LifecycleIcon />}
                        content={selectedTemplate?.Lifecycle?.Title} 
                        className={`${AppCSS.navActionButton} ${AppCSS.lifecycleIconButton}`} 
                        style={{
                          color: globalTheme.siteVariables.colorScheme.default.foreground2,
                        }}>
                      </Button>
                    </Flex.Item>
                  }
                  <Flex.Item>
                    <Button 
                      primary
                      text
                      icon={<FilledFileIcon />}
                      content={selectedTemplate?.Name ? selectedTemplate?.Name : t('ticket-details.edit-ticket-view.apply-template')} 
                      className={`${AppCSS.navActionButton}`} 
                      style={{
                        color: globalTheme.siteVariables.colorScheme.brand.foreground,
                      }}
                      onClick={() =>setOpenTemplateList(true)}>
                    </Button>
                  </Flex.Item>
                  <Flex.Item>
                    <Button
                      primary
                      text
                      icon={<FontIcon iconName="Refresh" />}
                      content={t('ticket-details.edit-ticket-view.reset')} 
                      className={`${AppCSS.navActionButton}`} 
                      style={{
                        color: globalTheme.siteVariables.colorScheme.brand.foreground,
                      }}
                      onClick={() =>resetForm()}>
                    </Button>
                  </Flex.Item>
                </Flex>
              </Flex.Item>
            </Flex>
            <div>
              {alert.show && (
                <Alert
                  fitted
                  {...alert.attributes}
                  content={alert.title}
                  style={{
                    fontSize: 12,
                    width: '100%',
                    marginBottom: '8px'
                  }}
                />
              )}
            </div>           
            </>
            
          ),
          style: { width: 'calc(600px - 4rem)' },
        }}
        trigger={
          <Button
            primary
            text
            icon={<AddIcon size='small' />}
            title='Add'
            content={t('ticket-details.edit-ticket-view.add-ticket')}
            onClick={() => {
              setShowAddTicketDialog(true);
            }}
            className={`${AppCSS.navActionButton} mr-2`}
            disabled={!isUserLicensed || isSaving}
            style={{ color: toolbarButtonColor(isUserLicensed && !isSaving, globalTheme.siteVariables.colorScheme) }}
          />
        }
      />
      <TemplateListDialog
        isNewTicket={true}
        formState={ticketData}
        showTemplate={openTemplateList}
        isApplyingTemplateWithLifecycle={false}
        templateList={templateList}
        dispatch={dispatch}
        closeApplyTemplate={closeApplyTemplate}
        applyTemplate={applyTemplate}
        ticketApprovals={[]}
      />
    </>
  );
};

import { Box, Text, Button, ProviderConsumer, Flex, Loader, Header, Divider, SyncIcon, Tooltip, tabListBehavior, Menu, AcceptIcon, AddIcon, Dropdown, ExclamationTriangleIcon, Checkbox, ThemePrepared } from "@fluentui/react-northstar";
import React, { useEffect, useState } from "react";
import { Toolbar } from '../shared/components/Toolbar';
import './PanelFlyout.css';
import { graphService } from '../shared/services/graph.service';
import { platformService } from '../shared/services/platform.service';
import { Communication, TToolbarInteraction } from '@fluentui/react-teams';
import { Providers } from "@microsoft/mgt-element/dist/es6/providers/Providers";
import { UserAvatar } from "../shared/components/UserAvatar";
import { Breadcrumbs } from "../shared/components/BreadcrumbNavigation"
import { CheckLogin } from "../shared/components/CheckLogin";
import { Helmet } from "react-helmet";
import { ChatIcon, MailIcon } from '@fluentui/react-icons-mdl2';
import { checkInTeams } from "../App";
import { LoadingScreen } from "../shared/components/LoadingScreen";
import { OwnedDevice, ManagedDevice } from "../shared/interfaces/platformuser.interface";

import TikitApp from './../../images/tikit-app.png';
import HelpbotApp from './../../images/helpbot-app.png';
import TVAApp from './../../images/tva-app.png';
import { LICENSE_FEATURE, toastDefault, PRODUCT_LICENSE, CSS_CLASS, ADMINISTRATORS, UNAUTHORIZED_ACCESS, SOMETHING_WENT_WRONG, CONTACT_ADMIN, REFRESH_SESSION, USERS, AGENTS, ANALYSTS } from "../shared/utils/constants";
import { LinkedDevices } from "./LinkedDevices";
import { getCachedFeature, hasConsentFeature } from "../shared/cache/FeatureCache";
import { FeatureConsentNeeded } from "../shared/components/UpgradeNeeded";
import { useLocation } from "react-router-dom";
import { EmptyData } from "../shared/components/EmptyData";
import EmptySVG from './../../svg/empty.svg';
import { toast } from "react-toastify";
import { Panel, PanelType } from "@fluentui/react";
import { LicenseDistribution } from "./LicenseDistribution";
import { ThemeColorScheme } from "../shared/common/TeamsTheme";
import { toolbarButtonColor } from "../tikit/ticketHelper";
import { appState } from "../AppState";
import { t } from "i18next";
import { toTitleCase } from "../shared/utils/helper";

export interface IUserFormProperties {
  id: string,
  isUserInfoOnly?: boolean
}

export interface WipeData {
  keepEnrollmentData: boolean;
  keepUserData: boolean;
  macOsUnlockCode?: string;
  persistEsimDataPlan?: boolean;
  useProtectedWipe?: boolean;
}

export interface AppCatalog {
  id: string,
  externalId: string,
  version: string,
  teamsAppId: string,
  appInstallId: string
}

export interface ProductLicense {
  ProductLicenseId: number,
  StripeId: string,
  ProductName: string,
  Active: boolean,
  IsConsumedByUser: boolean,
  SeatCount: number,
  UsedCount: number,
  AvailableCount: number,
  IsLoading: boolean
}

export interface User {
  Id: number,
  IsExternalUser: boolean,
  BlockSignIn: boolean,
  AadObjectId: string,
  OfficePhone: string,
  FullName: string,
  GivenName: string,
  FamilyName: string,
  JobTitle: string,
  Email: string,
  MobilePhone: string,
  Office: string,
  UserName: string,
  CompanyName: string,
  Department: string,
  EmployeeId: string,
  City: string,
  StreetAddress: string,
  State: string,
  ZipCode: string,
  Country: string,
  InstalledTikit: boolean,
  InstalledHelpBot: boolean,
  InstalledTVA: boolean,
  TikitInCatalog: boolean,
  HelpbotInCatalog: boolean,
  TVAInCatalog: boolean,
  TikitInCatalogDef: AppCatalog,
  TikitInstalledDef: AppCatalog,
  TVAInCatalogDef: AppCatalog,
  TVAInstalledDef: AppCatalog,
  HelpbotInCatalogDef: AppCatalog,
  HelpbotInstalledDef: AppCatalog,
  IsCatalogForbidden: boolean,
  PlatformUserId: number
}

export const ProgressBar = (props: { percentage: number, total: number }) => {
  return (
    <div style={{ position: "relative", flexGrow: 1 }} className={`pl-1`}>
      <div style={{ width: "100%", height: "15px", border: "1px solid #ddd", borderRadius: "3px" }}>
        <div style={{ width: `${props.percentage / props.total * 100}%`, background: "rgba(98, 100, 167, 0.3)", height: "100%", position: "absolute", top: "0px", left: "4px", borderRadius: "3px" }}>

        </div>
      </div>
      <Text style={{ position: "absolute", top: "1.5px", left: "8px" }} size="smaller" content={`${(props.percentage / (1024 * 1024 * 1024)).toFixed(1)}GB of ${(props.total / (1024 * 1024 * 1024)).toFixed(0)}GB`} weight="semibold" />
    </div>
  )
}

export const UserForm = (props: IUserFormProperties) => {

  const graphAPI = new graphService();
  const platformAPI = new platformService();
  const [isLoading, setIsLoading] = useState(true);
  const [isInstallingHelpbot, _setIsInstallingHelpbot] = useState(false);
  const [isInstallingTikit, setIsInstallingTikit] = useState(false);
  const [isInstallingTVA, setIsInstallingTVA] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errCode, setErrCode] = useState(0);
  const [loadingMessage, setLoadingMessage] = useState("");
  const aadURL = "https://portal.azure.com/#blade/Microsoft_AAD_IAM/UserDetailsMenuBlade/Profile/userId/";
  const o365URL = "https://portal.office.com/Adminportal/Home#/users/:/UserDetails/";
  const intuneURL = "https://endpoint.microsoft.com/#blade/Microsoft_AAD_IAM/UserDetailsMenuBlade/Profile/userId/";
  const [areAppsLoaded, setAreAppsLoaded] = useState<boolean>(false);
  const location = useLocation();
  const [openUserInfo, setOpenUserInfo] = useState(false);
  const currentState = appState();

  let setDefaultData = (obj) => {
    Object.entries(obj).forEach(([key, value]) => {
      if (value == null || (typeof value == "string" && value.length == 0))
        obj[key] = "-- --";
    });
    return obj;
  }

  const [data, setData] = useState<User>(setDefaultData({}));

  let getAppCatalog = (x) => {
    let app: AppCatalog = setDefaultData({});
    app.id = x["id"];
    app.externalId = x["externalId"];
    app.version = x["appDefinitions"][0]["version"];
    app.teamsAppId = x["appDefinitions"][0]["teamsAppId"];
    app.appInstallId = x["appDefinitions"][0]["id"];
    return app;
  }

  let getInstalledApp = (x) => {
    let app: AppCatalog = setDefaultData({});
    app.id = x["id"];
    app.externalId = x["teamsApp"]["externalId"];
    app.version = x["teamsAppDefinition"]["version"];
    app.teamsAppId = x["teamsAppDefinition"]["teamsAppId"];
    app.appInstallId = x["teamsAppDefinition"]["id"];
    return app;
  }

  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  const onToolbarInteraction = (interaction: TToolbarInteraction) => {
    if (interaction.action === "toggle-menu") {
      setMenuOpen(!menuOpen);
    }
  }

  const fetchExtraData = async (result: any) => {
    setHasAppConsent(false);

    if (result.AadObjectId != null && !result.IsExternalUser) {
      let newData = {};
      try {
        const catalogApp = await graphAPI.getCatalogApps(result.AadObjectId);

        let apps = catalogApp.data.value || [];
        let tikitApp = apps.filter(x => x["externalId"] == window.__runtimeConfig.tikitClientId || x["id"] == window.__runtimeConfig.tikitClientId);
        let helpbotApp = apps.filter(x => x["externalId"] == window.__runtimeConfig.helpbotClientId || x["id"] == window.__runtimeConfig.helpbotClientId);
        let tvaApp = apps.filter(x => x["externalId"] == window.__runtimeConfig.tvaClientId || x["id"] == window.__runtimeConfig.tvaClientId);

        newData = {
          TikitInCatalogDef: tikitApp.map(x => { return getAppCatalog(x); })[0],
          TikitInCatalog: tikitApp.length > 0,
          HelpbotInCatalogDef: helpbotApp.map(x => { return getAppCatalog(x); })[0],
          HelpbotInCatalog: helpbotApp.length > 0,
          TVAInCatalogDef: tvaApp.map(x => { return getAppCatalog(x); })[0],
          TVAInCatalog: tvaApp.length > 0
        };

        const userApps = await graphAPI.getUserApps(result.AadObjectId);
        apps = userApps.data.value || [];
        tikitApp = apps.filter(x => x["teamsApp"]["externalId"] == window.__runtimeConfig.tikitClientId || x["teamsApp"]["id"] == window.__runtimeConfig.tikitClientId);
        helpbotApp = apps.filter(x => x["teamsApp"]["externalId"] == window.__runtimeConfig.helpbotClientId || x["teamsApp"]["id"] == window.__runtimeConfig.helpbotClientId);
        tvaApp = apps.filter(x => x["teamsApp"]["externalId"] == window.__runtimeConfig.tvaClientId || x["teamsApp"]["id"] == window.__runtimeConfig.tvaClientId);
        newData = Object.assign({
          InstalledTikit: tikitApp.length > 0,
          TikitInstalledDef: tikitApp.map(x => { return getInstalledApp(x); })[0],
          InstalledHelpBot: helpbotApp.length > 0,
          HelpbotInstalledDef: helpbotApp.map(x => { return getInstalledApp(x); })[0],
          InstalledTVA: tvaApp.length > 0,
          TVAInstalledDef: tvaApp.map(x => { return getInstalledApp(x); })[0],
          IsCatalogForbidden: false
        }, newData);
      }
      catch (e: any) {
        newData = Object.assign({ IsCatalogForbidden: (e['response'] && e['response'].status === 403) }, newData);
      }

      setData((_prevState) => setDefaultData(Object.assign(result, newData)));
    } else {
      setData((prevState) => setDefaultData(Object.assign(prevState, result)));
    }
    setAreAppsLoaded(true);
  }

  const [managedDevices, setManagedDevices] = useState<ManagedDevice[]>([]);
  const [ownedDevices, setOwnedDevices] = useState<OwnedDevice[]>([]);
  const [enableIntune, setEnableIntune] = useState<boolean>(false);
  const [hasIntuneConsent, setHasIntuneConsent] = useState<boolean>(true);
  const [hasAppConsent, setHasAppConsent] = useState<boolean>(true);
  const [allowedPermissions, setAllowedPermissions] = useState([]);
  const [notAllowedPermissions, setNotAllowedPermissions] = useState([]);
  const [productLicenses, setProductLicenses] = useState<ProductLicense[]>([]);

  const getDevices = async (AadObjectId: string) => {
    if (AadObjectId != null) {
      try {
        const managed = await graphAPI.getManagedDevices(AadObjectId);
        setManagedDevices(managed.data.value);
        const owned = await graphAPI.getOwnedDevices(AadObjectId);
        setOwnedDevices(owned.data.value);

        const { data: { value: getEffectivePermissions } } = await graphAPI.getEffectivePermissions();

        getEffectivePermissions.map((gp) => {
          gp.resourceActions.map((ra) => {
            setAllowedPermissions([...allowedPermissions, ...ra.allowedResourceActions]);
            setNotAllowedPermissions([...notAllowedPermissions, ...ra.notAllowedResourceActions]);
          });
        });
      } catch (e) {
        //console.log(e)
      }
    }
  }

  const updateLicensesDetail = () => getLicenses();

  const getLicenses = async () => {
    try {

      const fetchResults = await Promise.all([
        platformAPI.getTenantLicenses(),
        platformAPI.getUserLicenses(`?$expand=PlatformUser&$filter=PlatformUserId eq ${data.Id}`)
      ]);

      const productLicenses = fetchResults[0].data.value;
      const userLicenses = fetchResults[1].data.value;
      const userLicensesData = productLicenses.map((prod: ProductLicense) => {
        prod.IsConsumedByUser = userLicenses.some(x => x.ProductLicenseId == prod.ProductLicenseId);
        prod.AvailableCount = prod.SeatCount - prod.UsedCount;
        prod.AvailableCount = (prod.AvailableCount < 0) ? 0 : prod.AvailableCount;
        return prod;
      });

      setProductLicenses(userLicensesData);
    } catch (e) {
      //console.log(e)
    }
  }

  const fetchData = async () => {
    setLoadingMessage(t('users.user-form.getting-user-data'));
    try {
      const fetchResults = await Promise.all([
        platformAPI.getUser(props.id),
        getCachedFeature(LICENSE_FEATURE.IntuneConnector),
        hasConsentFeature('INTUNE_CONNECTOR')]);

      const apiResult = fetchResults[0];
      const result = apiResult.data;

      setEnableIntune(fetchResults[1]);
      setHasIntuneConsent(fetchResults[2]);

      setData((prevState) => {
        return setDefaultData(Object.assign(prevState, result));
      });
      setIsError(false);

      setIsLoading(false);
    }
    catch (e: any) {
      setIsError(true);
      setErrCode((e['response'] && e['response'].status) || 500);
    }
  };

  const loadPage = () => {
    fetchData();
  }

  const saveData = () => {
    platformAPI.syncUser(props.id).then((val) => {
      const value = val.data;
      setData(value);
      setIsLoading(false);
      setIsGettingUsers(false);
    });
  };

  const getUserLicensesData = (prod: ProductLicense, productId: string, isConsumed: boolean) => {
    if (prod.StripeId == productId) {
      prod.IsConsumedByUser = !isConsumed;
      prod.UsedCount = prod.IsConsumedByUser ? prod.UsedCount + 1 : prod.UsedCount - 1;
      prod.AvailableCount = prod.SeatCount - prod.UsedCount;
      prod.AvailableCount = (prod.AvailableCount < 0) ? 0 : prod.AvailableCount;
      prod.IsLoading = false;
    }
    return prod;
  }

  const addOrRemoveUserLicense = (platformUserId: number, productId: string, isConsumed: boolean) => {
    platformAPI.addOrRemoveUserLicense(platformUserId, isConsumed).then((val) => {
      const error = val.data.error;
      if (error.length > 0) toast.error(error, toastDefault);
      else {
        setProductLicenses((prevState: ProductLicense[]) => (prevState.map((prod: ProductLicense) => getUserLicensesData(prod, productId, isConsumed))));
        toast.success("License(s) updated successfully", toastDefault);
      }
    }).catch(error => {
      console.error(error);
    });
  }

  const errConfig = {
    fields: {
      title: (errCode == 401) ? UNAUTHORIZED_ACCESS : SOMETHING_WENT_WRONG,
      desc: CONTACT_ADMIN
    }
  }

  const getNavigation = (): Breadcrumbs => {
    const items: Breadcrumbs = {
      breadcrumbs: []
    };

    if (location.pathname.indexOf('/settings/') >= 0)
      items.breadcrumbs.push({ title: t('users.user-form.settings'), link: '/settings' })

    items.breadcrumbs.push({ title: t('users.user-form.users'), link: (items.breadcrumbs.length == 0) ? '/users' : '/settings/users' });
    items.breadcrumbs.push({ title: data.FullName, link: "" });
    return items;
  };

  const setMenuItems = () => {
    const analystOrAdmin: boolean = (currentState?.userRoles?.roles?.includes(ANALYSTS) ?? false) || (currentState?.userRoles?.roles?.includes(ADMINISTRATORS) ?? false);
    let items = [{
      key: 'userinfo',
      content: t('users.tabs.user-info'),
    }];

    if (analystOrAdmin) {
      items.push({
        key: 'linkeddevices',
        content: t('users.tabs.linked-devices'),
      })
      items.push({
        key: 'licenses',
        content: t('users.tabs.licenses'),
      })
    }

    return items;

  }
  const items = setMenuItems();

  const inputItems = [
    'User Info',
    'Linked Devices',
    'Licenses'
  ]

  enum MenuTabs {
    UserInfo = 0,
    LinkedDevices = 1,
    TikitApps = 3,
    Licenses = 2
  }

  const [menu, setMenu] = useState<number>(MenuTabs.UserInfo);
  const [isGettingDevices, setIsGettingDevices] = useState<boolean>(true);
  const [isGettingApps, setIsGettingApps] = useState<boolean>(true);
  const [isGettingUsers, setIsGettingUsers] = useState<boolean>(false);
  const [isGettingLicenses, setIsGettingLicenses] = useState<boolean>(true);
  const [isUserLicensed, setIsUserLicensed] = useState(false);
  const isUserAdmin: boolean = (currentState?.userRoles?.roles?.includes(ADMINISTRATORS) ?? false);
  const isAgent: boolean = (currentState?.userRoles?.roles?.includes(AGENTS) ?? false);
  const isAnalyst: boolean = (currentState?.userRoles?.roles?.includes(ANALYSTS) ?? false);


  const updateDevices = () => {
    getDevices(data.AadObjectId).then(() => {
      setIsGettingDevices(false);
    });
  }

  const updateApps = () => {
    platformAPI.syncUser(props.id).then((val) => {
      const value = val.data;
      fetchExtraData(value).then(() => {
        setIsGettingApps(false);
      }).catch(error => {
        console.error(error);
      });
    });
  }

  const updateLicenses = () => {
    getLicenses().then(() => {
      setIsGettingLicenses(false);
    });
  }

  useEffect(() => {
    if (menu === MenuTabs.LinkedDevices) {
      if (isGettingDevices) {
        updateDevices();
      }
    }
    else if (menu === MenuTabs.TikitApps) {
      if (isGettingApps) {
        updateApps();
      }
    }
    else if (menu === MenuTabs.Licenses) {
      if (isGettingLicenses) {
        updateLicenses();
      }
    }
  }, [menu])

  useEffect(() => {
    (async () => setIsUserLicensed((await getCachedFeature(PRODUCT_LICENSE.AnalystAccess))))();
  }, []);

  const getBoxHeight = () => {
    return checkInTeams() ? "100vh" : "calc(100vh - 80px)"
  }

  const onUpdateUserClick = () => {
    if (isUserLicensed) {
      setIsGettingUsers(true);
      saveData();
    }
  }

  const getContent = (isTrue: boolean) => isTrue ? "Yes" : "No";
  const getPointerClass = () => (isUserLicensed && isUserAdmin) ? 'cursor-pointer' : 'cursor-default';

  const installTikitApp = () => {
    if (isUserLicensed) {
      setIsInstallingTikit(true);
      graphAPI.installUserApp(data.AadObjectId, data.TikitInCatalogDef.teamsAppId).then((result) => {
        if ((result && result["status"] == 201)) {
          setData((prevState) => {
            let newData = { InstalledTikit: true, TikitInstalledDef: data.TikitInCatalogDef }
            return Object.assign(prevState, newData);
          });
        }
        setIsInstallingTikit(false);
      });
    }
  }

  const isInstallTikit = (dataValue: User): string => {
    if (dataValue.InstalledTikit) {
      return `v.${data.TikitInstalledDef.version}`;
    } else {
      return `No`;
    }
  };

  const isInstallHelpBot = (data: User): string => {
    if (data.InstalledHelpBot) {
      return `v.${data.HelpbotInstalledDef.version}`;
    } else {
      return `No`;
    }
  };

  const isInstallTVA = (data: User): string => {
    if (data.InstalledTVA) {
      return `v.${data.TVAInstalledDef.version}`;
    } else {
      return `No`;
    }
  };

  const installTvaApp = () => {
    if (isUserLicensed) {
      setIsInstallingTVA(true);
      graphAPI.installUserApp(data.AadObjectId, data.TVAInCatalogDef.teamsAppId).then((result) => {
        if ((result && result["status"] == 201)) {
          setData((prevState) => {
            let newData = { InstalledTVA: true, TVAInstalledDef: data.TVAInCatalogDef };
            return Object.assign(prevState, newData);
          });
        }
        setIsInstallingTVA(false);
      });
    }
  }

  const updateTikitApp = () => {
    if (isUserLicensed) {
      setIsInstallingTikit(true);
      graphAPI.updateUserApp(data.AadObjectId, data.TikitInCatalogDef.appInstallId).then((result) => {
        if ((result && result["status"] == 204)) {
          setData((prevState) => {
            prevState["TikitInstalledDef"]["version"] = data.TikitInCatalogDef.version;
            return prevState;
          });
        }
        setIsInstallingTikit(false);
      });
    }
  }

  const updateTvaApp = () => {
    if (isUserLicensed) {
      setIsInstallingTVA(true);
      graphAPI.updateUserApp(data.AadObjectId, data.TVAInCatalogDef.appInstallId).then((result) => {
        if ((result && result["status"] == 204)) {
          setData((prevState) => {
            prevState["TVAInstalledDef"]["version"] = data.TVAInCatalogDef.version;
            return prevState;
          });
        }
        setIsInstallingTVA(false);
      });
    }
  }

  const updateUserLicense = (license: ProductLicense) => {
    if (isUserLicensed) {
      setProductLicenses((prevState: ProductLicense[]) => {
        const userLicensesData = prevState.map((prod: ProductLicense) => {
          if (prod.StripeId == license.StripeId)
            prod.IsLoading = true;
          return prod;
        });
        return userLicensesData;
      });
      setTimeout(() => {
        addOrRemoveUserLicense(data.Id, license.StripeId, license.IsConsumedByUser);
      }, 1000);
    }
  }

  const getMenuRender = (menu: number, globalTheme: ThemePrepared<any>) => {
    const menuObj = {
      [MenuTabs.UserInfo]: menuTabUserInfo(),
      [MenuTabs.LinkedDevices]: menuTabLinkedDevices(globalTheme),
      [MenuTabs.TikitApps]: menuTabTikitApps(globalTheme),
      [MenuTabs.Licenses]: menuTabLicenses(globalTheme),
    }

    return menuObj[menu]
  }

  return (
    <CheckLogin onSignedIn={loadPage}>
      <Helmet>
        <title>{toTitleCase(USERS)} - Tikit</title>
      </Helmet>
      {(isLoading) && <LoadingScreen message={loadingMessage} />}
      {(!isLoading) &&
        (isError ? (<>
          <Communication {...errConfig} />
          {errCode === 401 && (<Button content={REFRESH_SESSION} primary onClick={e => { Providers.globalProvider.login(); }} />)}
        </>)
          : (
            <ProviderConsumer render={(globalTheme) => (
              <>
                {(!props.isUserInfoOnly) && (
                  <>
                    <Toolbar onInteraction={onToolbarInteraction} globalTheme={globalTheme} title={`Settings > Users > ${data.FullName}`} breadcrumbs={getNavigation()}
                      {...(!data.IsExternalUser) && {
                        addNew: true
                      }}
                    />
                  </>
                )}
                <Flex>
                  <Helmet>
                    <title>{data.FullName} - Tikit</title>
                  </Helmet>
                  <Flex.Item >
                    <Box style={{
                      width: "100%",
                      height: getBoxHeight(),
                      padding: '20px',
                      paddingBottom: "10vh",
                      overflow: 'auto'
                    }}>
                      <div style={{ width: "100%" }}>
                        <Flex vAlign="center" className="mb-8 flex-col md:flex-row">
                          <Flex.Item>
                            <Box className={`mx-4`}>
                              <Box><UserAvatar {...{ idOrUpn: data.AadObjectId ?? data.UserName, avatarProps: { name: data.FullName, size: "largest" } }}></UserAvatar></Box>
                            </Box>
                          </Flex.Item>
                          <Flex.Item>
                            <Box className={`break-words w-full md:w-1/2 text-center md:text-left`}>
                              <Box><Text size="largest" content={data.FullName} /></Box>
                              <Box>
                                <Flex className={`justify-center md:justify-start`}>
                                  <Box>
                                    <Tooltip content={t('ticket-details.edit-ticket-form.requester-card.send-email')} trigger={
                                      <a href={`mailto:${data.Email}`}>
                                        <MailIcon style={{ color: globalTheme.siteVariables.colorScheme.brand.foreground }} />
                                      </a>} />
                                  </Box>
                                  <Box>
                                    <Tooltip content={t('ticket-details.edit-ticket-form.requester-card.message-in-team')} trigger={
                                      <a target="_blank" className={`ml-2`} href={`https://teams.microsoft.com/l/chat/0/0?users=${data.Email}`}>
                                        <ChatIcon style={{ color: globalTheme.siteVariables.colorScheme.brand.foreground }} />
                                      </a>
                                    } />
                                  </Box>
                                </Flex>
                              </Box>
                              {((isAgent) || (isAnalyst) || (isUserAdmin) ) && (!(data.IsExternalUser)) && <Box style={{ marginTop: "5px" }}>
                                {(data.AadObjectId != "-- --") && (
                                  <>
                                    <Text content={t('users.user-form.azure-active-directory')} title={`${aadURL}${data.AadObjectId}`} color={"brand"} onClick={(e) => {
                                      window.open(`${aadURL}${data.AadObjectId}`, '_blank');
                                    }} styles={{
                                      paddingRight: "5px",
                                      cursor: "pointer",
                                      "&:hover": {
                                        textDecoration: "underline"
                                      }
                                    }} />
                                    &bull;
                                    <Text content="365" title={`${o365URL}${data.AadObjectId}`} color={"brand"} onClick={(e) => {
                                      window.open(`${o365URL}${data.AadObjectId}`, '_blank');
                                    }} styles={{
                                      paddingRight: "5px",
                                      paddingLeft: "5px",
                                      cursor: "pointer",
                                      "&:hover": {
                                        textDecoration: "underline"
                                      }
                                    }} />
                                    &bull;
                                    <Text content="Intune" title={`${intuneURL}${data.AadObjectId}`} color={"brand"} onClick={(e) => {
                                      window.open(`${intuneURL}${data.AadObjectId}`, '_blank');
                                    }} styles={{
                                      paddingRight: "5px",
                                      paddingLeft: "5px",
                                      cursor: "pointer",
                                      "&:hover": {
                                        textDecoration: "underline"
                                      }
                                    }} />
                                  </>
                                )}
                                {(data.AadObjectId == "-- --") && (
                                  <Text error content={t('users.user-form.update-user-to-get-more-details-and-actions')} />
                                )}
                              </Box>}
                            </Box>
                          </Flex.Item>
                        </Flex>
                        <div className={'hidden md:block'}>
                          <Menu
                            defaultActiveIndex={menu}
                            items={items}
                            underlined
                            primary
                            accessibility={tabListBehavior}
                            aria-label="User "
                            onActiveIndexChange={(e, p: any) => {
                              setMenu(p.activeIndex)
                            }}
                          />
                        </div>
                        <div className={`md:hidden`}>
                          <Dropdown
                            inverted
                            fluid
                            items={inputItems}
                            placeholder={inputItems[menu]}
                            onChange={(e, p) => {
                              setMenu(p.highlightedIndex)
                            }}
                          />
                          <Divider />
                        </div>
                        {getMenuRender(menu, globalTheme)}
                      </div>
                    </Box>
                  </Flex.Item>
                </Flex>
              </>)} />
          )
        )
      }
    </CheckLogin>
  )

  function menuTabUserInfo() {
    return (
      <>
        {(isGettingUsers) && (
          <Loader label={t('users.user-form.getting-users-data')} className={`pt-10`} />
        )}
        {(!isGettingUsers) && (
          <>
            <section>
              <Button
                onClick={() => onUpdateUserClick()}
                disabled={!isUserLicensed}
                content={t('users.update')}
                primary icon={<SyncIcon />}
                className={`ml-3`} />
              <div>
                <Header className={`px-3 pt-2 my-4`} as="h3" content={<Text weight="semibold" size="large" content={"Identity"} />} />
                <div className={`px-3`}>
                  <Flex className={`my-6 flex-col md:flex-row`}>
                    <Box className={`w-64`}><Text content={t('users.user-form.user-name')} weight="semibold" /></Box>
                    <Box className={`w-1/2`}><Text content={data.UserName} /></Box>
                  </Flex>
                  <Flex className={`my-6 flex-col md:flex-row`}>
                    <Box className={`w-64`}><Text content={t('users.user-form.name')} weight="semibold" /></Box>
                    <Box className={`w-1/2`}><Text content={data.FullName} /></Box>
                  </Flex>
                  <Flex className={`my-6 flex-col md:flex-row`}>
                    <Box className={`w-64`}><Text content={t('users.user-form.first-name')} weight="semibold" /></Box>
                    <Box className={`w-1/2`}><Text content={data.GivenName} /></Box>
                  </Flex>
                  <Flex className={`my-6 flex-col md:flex-row`}>
                    <Box className={`w-64`}><Text content={t('users.user-form.last-name')} weight="semibold" /></Box>
                    <Box className={`w-1/2`}><Text content={data.FamilyName} /></Box>
                  </Flex>
                </div>
              </div>

              <Divider />
              <div>
                <Header className={`px-3 pt-2 my-4 rounded-md`} as="h3" content={<Text weight="semibold" size="large" content={t('users.user-form.job-info')} />} />
                <div className={`px-3`}>
                  <Flex className={`my-6 flex-col md:flex-row`}>
                    <Box className={`w-64`}><Text content={t('users.user-form.job-title')} weight="semibold" /></Box>
                    <Box className={`w-1/2`}><Text content={data.JobTitle} /></Box>
                  </Flex>
                  <Flex className={`my-6 flex-col md:flex-row`}>
                    <Box className={`w-64`}><Text content={t('users.user-form.company-name')} weight="semibold" /></Box>
                    <Box className={`w-1/2`}><Text content={data.CompanyName} /></Box>
                  </Flex>
                  <Flex className={`my-6 flex-col md:flex-row`}>
                    <Box className={`w-64`}><Text content={t('users.user-form.department')} weight="semibold" /></Box>
                    <Box className={`w-1/2`}><Text content={data.Department} /></Box>
                  </Flex>
                  <Flex className={`my-6 flex-col md:flex-row`}>
                    <Box className={`w-64`}><Text content={t('users.user-form.employee-id')} weight="semibold" /></Box>
                    <Box className={`w-1/2`}><Text content={data.EmployeeId} /></Box>
                  </Flex>
                </div>
              </div>
              <Divider />
              <div>
                <Header className={`px-3 pt-2 my-4 rounded-md`} as="h3" content={<Text weight="semibold" size="large" content={t('users.user-form.settings')} />} />
                <div className={`px-3`}>
                  <Flex className={`my-6 flex-col md:flex-row`}>
                    <Box className={`w-64`}><Text content={t('users.user-form.block-sign-in')} weight="semibold" /></Box>
                    <Box className={`w-1/2`}><Text content={getContent(data.BlockSignIn)} /></Box>
                  </Flex>
                  <Flex className={`my-6 flex-col md:flex-row`}>
                    <Box className={`w-64`}><Text content={t('users.user-form.is-external-user')} weight="semibold" /></Box>
                    <Box className={`w-1/2`}><Text content={getContent(data.IsExternalUser)} /></Box>
                  </Flex>
                </div>
              </div>
              <Divider />
              <div>
                <Header className={`px-3 pt-2 my-4 rounded-md`} as="h3" content={<Text weight="semibold" size="large" content={t('users.user-form.contact-info')} />} />
                <div className={`px-3`}>
                  <Flex className={`my-6 flex-col md:flex-row`}>
                    <Box className={`w-64`}><Text content={t('users.user-form.email-address')} weight="semibold" /></Box>
                    <Box className={`w-1/2`}><Text content={data.Email} /></Box>
                  </Flex>
                  <Flex className={`my-6 flex-col md:flex-row`}>
                    <Box className={`w-64`}><Text content={t('users.user-form.street-address')} weight="semibold" /></Box>
                    <Box className={`w-1/2`}><Text content={data.StreetAddress} /></Box>
                  </Flex>
                  <Flex className={`my-6 flex-col md:flex-row`}>
                    <Box className={`w-64`}><Text content={t('users.user-form.state')} weight="semibold" /></Box>
                    <Box className={`w-1/2`}><Text content={data.State} /></Box>
                  </Flex>
                  <Flex className={`my-6 flex-col md:flex-row`}>
                    <Box className={`w-64`}><Text content={t('users.user-form.country')} weight="semibold" /></Box>
                    <Box className={`w-1/2`}><Text content={data.Country} /></Box>
                  </Flex>
                  <Flex className={`my-6 flex-col md:flex-row`}>
                    <Box className={`w-64`}><Text content={t('users.user-form.office')} weight="semibold" /></Box>
                    <Box className={`w-1/2`}><Text content={data.Office} /></Box>
                  </Flex>
                  <Flex className={`my-6 flex-col md:flex-row`}>
                    <Box className={`w-64`}><Text content={t('users.user-form.city')} weight="semibold" /></Box>
                    <Box className={`w-1/2`}><Text content={data.City} /></Box>
                  </Flex>
                  <Flex className={`my-6 flex-col md:flex-row`}>
                    <Box className={`w-64`}><Text content={t('users.user-form.zip')} weight="semibold" /></Box>
                    <Box className={`w-1/2`}><Text content={data.ZipCode} /></Box>
                  </Flex>
                  <Flex className={`my-6 flex-col md:flex-row`}>
                    <Box className={`w-64`}><Text content={t('users.user-form.office-phone')} weight="semibold" /></Box>
                    <Box className={`w-1/2`}><Text content={data.OfficePhone} /></Box>
                  </Flex>
                  <Flex className={`my-6 flex-col md:flex-row`}>
                    <Box className={`w-64`}><Text content={t('users.user-form.mobile-phone')} weight="semibold" /></Box>
                    <Box className={`w-1/2`}><Text content={data.MobilePhone} /></Box>
                  </Flex>
                </div>
              </div>
            </section>
          </>
        )}
      </>
    )
  }

  function menuTabLinkedDevices(globalTheme: ThemePrepared<any>) {
    return (
      <>
        {(isGettingDevices) && (
          <Loader label={t('users.device-form.getting-devices')} className={`pt-10`} />
        )}
        {(!isGettingDevices) && (
          <>
            <section>
              {hasIntuneConsent && <Button onClick={() => { setIsGettingDevices(true); updateDevices(); }} content={t('users.update')} primary disabled={!isUserLicensed} icon={<SyncIcon />} className={`ml-3`} />}
              <LinkedDevices isUserLicensed={isUserLicensed} enableIntune={enableIntune} hasIntuneConsent={hasIntuneConsent} ownedDevices={ownedDevices} managedDevices={managedDevices} userId={data.AadObjectId} allowedPermissions={allowedPermissions} globalTheme={globalTheme} />
            </section>
          </>
        )}
      </>
    )
  }

  function menuTabTikitApps(globalTheme: ThemePrepared<any>) {
    return (
      <>
        {!hasAppConsent && (<FeatureConsentNeeded />)}
        {(isGettingApps) && (
          <Loader label="Getting apps..." className={`pt-10`} />
        )}
        {(!isGettingApps) && (
          <>
            <section>
              {hasAppConsent && <Button onClick={() => { setIsGettingApps(true); updateApps(); }} content="Update" primary disabled={!isUserLicensed} icon={<SyncIcon />} className={`ml-3`} />}
              {(data.AadObjectId != "-- --") && (
                <>
                  <div className={`pl-2`}>
                    <Header className={`px-3 pt-2 my-4 rounded-md`} as="h3" content={<Text weight="semibold" size="large" content={"Teams App"} />} />
                    <div className={`w-full px-3`}>
                      {installingTikit(globalTheme)}
                      {installingHelpbot()}
                      {installingTva(globalTheme)}
                    </div>
                  </div>
                </>
              )}
            </section>
          </>
        )}
      </>
    )
  }
  function installingTikit(globalTheme: ThemePrepared<any>) {
    if (isInstallingTikit) {
      return (
        <Loader inline size="smallest" label="Installing Tikit..." labelPosition="end" />
      )
    }
    else {
      return (
        <Flex className={`my-6 `} vAlign={"center"}>
          <img src={TikitApp} className={'rounded mr-2'} />
          <Box className={`w-64`}>
            <div>
              <Text content="Tikit" weight="semibold" />
            </div>
            <div className={`w-1/2`}>
              <Text size="smaller" content={isInstallTikit(data)} />
              {(data.InstalledTikit && data.TikitInCatalogDef.version != undefined && data.TikitInstalledDef.version != data.TikitInCatalogDef.version) && (
                <div className={CSS_CLASS.STATUS_PILL_OTHERS} style={{ marginLeft: "5px" }}>
                  <Text weight="bold" size="smaller">New {data.TikitInCatalogDef.version}</Text>
                </div>
              )}
            </div>
          </Box>
          {(!data.IsCatalogForbidden && !data.InstalledTikit && data.TikitInCatalog) && (
            <Text disabled={!isUserLicensed} className={`${getPointerClass()}`}
              content={
                <Flex vAlign={"center"}>
                  <AddIcon />
                  <span className="pl-2">Install</span>
                </Flex>
              }
              onClick={(_e: any) => installTikitApp()}
              styles={{
                "&:hover": { textDecoration: isUserLicensed && "underline" },
                color: toolbarButtonColor(isUserLicensed, globalTheme.siteVariables.colorScheme)
              }}
            />
          )}
          {(data.IsCatalogForbidden) && (<Text content={<Flex vAlign="center">
            <ExclamationTriangleIcon />
            <span className="pl-2">Access Denied</span>
          </Flex>} />)}
          {(data.InstalledTikit && data.TikitInCatalog) && (
            <Text content={
              <Flex vAlign={"center"}>
                <AcceptIcon />
                <span className="pl-2">Installed</span>
              </Flex>
            } color={"green"} />
          )}
          {(data.InstalledTikit && data.TikitInCatalogDef.version != undefined && data.TikitInstalledDef.version != data.TikitInCatalogDef.version) && (
            <Text content="Update Now" disabled={!isUserLicensed} className={`${getPointerClass()}`}
              onClick={(_e: any) => updateTikitApp()}
              styles={{
                "&:hover": { textDecoration: isUserLicensed && "underline" },
                color: toolbarButtonColor(isUserLicensed, globalTheme.siteVariables.colorScheme)
              }}
            />
          )}
          {(!data.TikitInCatalog && areAppsLoaded) && (
            <Text error content="Tikit is not in your organization's catalog." />
          )}
          {(!areAppsLoaded) && (
            <Loader size="small" />
          )}
        </Flex>
      )
    }
  }
  function installingHelpbot() {
    if (isInstallingHelpbot) {
      return (
        <Loader inline size="smallest" label="Installing Helpbot..." labelPosition="end" />
      )
    }
    else {
      return (
        <>
          <Flex className={`my-6`} vAlign="center">
            <img src={HelpbotApp} className={'rounded mr-2'} />
            <Box className={`w-64`}>
              <div>
                <Text content="Helpbot" weight="semibold" />
              </div>
              <div className={`w-1/2`}>
                <Text content={isInstallHelpBot(data)} size={"smaller"} />
                {(data.InstalledHelpBot && data.HelpbotInCatalogDef.version != undefined && data.HelpbotInstalledDef.version != data.HelpbotInCatalogDef.version) && (
                  <div className={CSS_CLASS.STATUS_PILL_OTHERS} style={{ marginLeft: "5px" }}>
                    <Text weight="bold" size="smaller">New {data.HelpbotInCatalogDef.version}</Text>
                  </div>
                )}
              </div>
            </Box>
            {(data.IsCatalogForbidden && data.HelpbotInCatalog) && (<Text content={<Flex vAlign="center">
              <ExclamationTriangleIcon />
              <span className="pl-2">Access Denied</span>
            </Flex>} />)}
            {(data.InstalledHelpBot && data.HelpbotInCatalog) && (
              <Text content="Installed, please consider migrating user to the Tikit Virtual Agent" size="small" />
            )}
            {(!data.HelpbotInCatalog && areAppsLoaded) && (
              <Text error content="Helpbot is not in your organization's catalog." />
            )}
            {(!areAppsLoaded) && (
              <Loader size="small" />
            )}
          </Flex>
        </>
      )
    }
  }
  function installingTva(globalTheme: ThemePrepared<any>) {
    if (isInstallingTVA) {
      return (
        <Loader inline size="smallest" label="Installing TVA..." labelPosition="end" />
      )
    }
    else {
      return (
        <>
          <Flex className={`my-6`} vAlign="center">
            <img src={TVAApp} className={'rounded mr-2'} />
            <Box className={`w-64`}>
              <div>
                <Text content="TVA" weight="semibold" />
              </div>
              <div className={`w-1/2`}>
                <Text content={isInstallTVA(data)} size={"smaller"} />
                {(data.InstalledTVA && data.TVAInCatalogDef.version != undefined && data.TVAInstalledDef.version != data.TVAInCatalogDef.version) && (
                  <div className={CSS_CLASS.STATUS_PILL_OTHERS} style={{ marginLeft: "5px" }}>
                    <Text weight="bold" size="smaller">New {data.TVAInCatalogDef.version}</Text>
                  </div>
                )}
              </div>
            </Box>
            {(data.IsCatalogForbidden && data.TVAInCatalog) && (<Text content={<Flex vAlign="center">
              <ExclamationTriangleIcon />
              <span className="pl-2">Access Denied</span>
            </Flex>} />)}
            {(!data.IsCatalogForbidden && !data.InstalledTVA && data.TVAInCatalog) && (
              <Text disabled={!isUserLicensed} className={`${getPointerClass()}`}
                content={
                  <Flex vAlign={"center"}>
                    <AddIcon />
                    <span className="pl-2">Install</span>
                  </Flex>
                }
                onClick={(_e: any) => installTvaApp()}
                styles={{
                  "&:hover": { textDecoration: isUserLicensed && "underline" },
                  color: toolbarButtonColor(isUserLicensed, globalTheme.siteVariables.colorScheme)
                }}
              />
            )}
            {(data.InstalledTVA && data.TVAInCatalog) && (
              <Text content={
                <Flex vAlign={"center"}>
                  <AcceptIcon />
                  <span className="pl-2">Installed</span>
                </Flex>
              } color={"green"} />
            )}
            {(data.InstalledTVA && data.TVAInCatalogDef.version != undefined && data.TVAInstalledDef.version != data.TVAInCatalogDef.version) && (
              <Text content="Update Now" disabled={!isUserLicensed} className={`${getPointerClass()}`}
                onClick={(_e: any) => updateTvaApp()}
                styles={{
                  "&:hover": { textDecoration: isUserLicensed && "underline" },
                  color: toolbarButtonColor(isUserLicensed, globalTheme.siteVariables.colorScheme)
                }}
              />
            )}
            {(!data.TVAInCatalog && areAppsLoaded) && (
              <Text error content="TVA is not in your organization's catalog." />
            )}
            {(!areAppsLoaded) && (
              <Loader size="small" />
            )}
          </Flex>
        </>
      )
    }
  }

  function menuTabLicenses(globalTheme: ThemePrepared<any>) {
    return (
      <>
        {(isGettingLicenses) && (
          <Loader label={t('billing.license.getting-licenses')} className={`pt-10`} />
        )}
        {(!isGettingLicenses) && (
          <>
            <section>
              {productLicenses.length > 0 && (
                <>
                  <div className={`pl-2`}>
                    <Header className={`px-3 pt-2 my-4 rounded-md`} as="h3" content={<Text weight="semibold" size="large" content={t('users.tabs.licenses')} />} />
                    <div className={`w-full px-3`}>
                      {productLicenses.map((license: ProductLicense) => {
                        return (<>
                          <Checkbox label={<strong>{license.ProductName}</strong>} checked={license.IsConsumedByUser}
                            onChange={() => updateUserLicense(license)}
                            disabled={(license.AvailableCount == 0 && !license.IsConsumedByUser) || license.IsLoading || !isUserLicensed || !isUserAdmin}
                          />
                          <div className={`pl-8`}>
                            {(!license.IsLoading) && (<Text color={(isUserLicensed && isUserAdmin) && 'brand'} content={t('billing.license.available', { available: license.AvailableCount, seatCount: license.SeatCount })} onClick={() => (isUserLicensed && isUserAdmin) && setOpenUserInfo(true)} className={`${getPointerClass()}`} />)}
                            {(license.IsLoading) && (<Loader inline size="smallest" label={t('billing.license.updating')} labelPosition="end" />)}
                          </div>
                          <Panel
                            isLightDismiss
                            isOpen={openUserInfo}
                            onDismiss={() => setOpenUserInfo(false)}
                            onOuterClick={() => setOpenUserInfo(true)}
                            closeButtonAriaLabel="Close"
                            type={PanelType.medium}
                            className={`panelFlyout`}
                            style={{ width: '100%', ...ThemeColorScheme(globalTheme.siteVariables) }}
                          >
                            <LicenseDistribution routeName={"users"} licenseName={license.ProductName} updateLicensesDetail={updateLicensesDetail} />
                          </Panel>
                        </>)
                      })
                      }
                    </div>
                  </div>
                </>
              )}
              {productLicenses.length === 0 && (
                <div className='mt-10 flex justify-center w-full h-96'>
                  <EmptyData
                    headerText={t('billing.license.no-records')}
                    subheaderText=''
                    SVGIcon={
                      <EmptySVG
                        width={200}
                        height={200}
                        className='mb-2'
                      />
                    }
                  />
                </div>
              )}
            </section>
          </>
        )}
      </>
    )
  }
}
import { Button, CloseIcon, ProviderConsumer as FluentUIThemeConsumer, Loader, } from '@fluentui/react-northstar';
import React from 'react';
import { getFileIcon, getTruncatedFilename } from '../../EditTicketAttachments/NewAttachmentFlow/NewAttachmentFlowContent';

interface Props {
    onRemoveFile: (index: number) => void;
    file: File;
    id: number;
    isRemoveDisabled: boolean;
    isUploading: boolean;
}

const FileAttachments: React.FC<Props> = ({ isUploading, file, onRemoveFile, isRemoveDisabled, id }: Props) => {

    const removeFile = (index: number) => {
        onRemoveFile(index);
    }


    const extension = file.name.split(".").pop();

    return (
        <FluentUIThemeConsumer
            render={globalTheme => (
                <>
                    <div className={`flex justify-between items-center my-1 py-2 pl-2 border border-tneutral-200 mr-2 mb-2 shadow-sm rounded bg-tneutral-100`} key={id}>
                        {isUploading && <Loader size='smaller' />}
                        {!isUploading && getFileIcon(extension, file, globalTheme)}
                        <span className='px-2 whitespace-nowrap'>{getTruncatedFilename(file.name)}</span>
                        {!isUploading && <Button disabled={isRemoveDisabled} iconOnly text icon={<CloseIcon size='small' />} onClick={() => removeFile(id)} />}
                    </div>
                </>
            )} />
    )
}

export default FileAttachments;
import React, { useEffect, useState } from "react";
import { TextArea, Text, ProviderConsumer as FluentUIThemeConsumer, Flex, Dropdown } from "@fluentui/react-northstar";
import { LifecycleTask } from "../utils/typings";
import { PlatformUser } from "../../shared/interfaces/platformuser.interface";
import { ThemeColorScheme } from "../../shared/common/TeamsTheme";
import '../styles/Styles.css';
import { LIFECYCLE } from '../../../components/shared/utils/constants'

interface Props{
    taskData: LifecycleTask,
    setTaskData: (data: LifecycleTask) => void;
    userList: PlatformUser[];
}

export const LabelElement = (props: any) => {
  return (
    <div>
      <Flex>
        <Text content={props.label} style={{ display: 'block' }} />
        {props.required && (
          <span style={{ color: 'red', marginLeft: 2 }}>*</span>
        )}
      </Flex>
      <div>{props.children}</div>
    </div>
  );
};

export const PhaseTaskForm = ({taskData,setTaskData, userList}:Props) => {
    const [assigneeItems, setAssigneeItems] = useState([]);
    const [searchText, setSearchText] = useState('');
  
    useEffect(() => {
      const items = userList.map((data: any) => {
        if (data.Id === taskData?.Assignee?.Id) {
          data.selected = true;
          setSearchText(taskData.Assignee.FullName);
        } else {
          data.selected = false;
        }
        data.header = data.FullName;
        return data;
      });
      setAssigneeItems([...items]);
    }, [userList, taskData]);

    return (
      <FluentUIThemeConsumer
      render={globalTheme => (
        
        <div
          className="min-h-32"
          style={{
            color: 'var(--mgt-theme-default-foreground)',
            ...ThemeColorScheme(globalTheme.siteVariables)
          }}
        >
          <div >
            <LabelElement label={LIFECYCLE.TITLE} required={true}>
                <TextArea
                    maxLength={500}
                    required
                    fluid
                    value={taskData.Name}
                    onChange={(_e, p) => {
                      setTaskData({
                        ...taskData,
                        Name: p.value
                      });
                  }}
                />
            </LabelElement>
          </div>

          <div className={`mt-2`}>
            <LabelElement label={LIFECYCLE.ASSIGNEE}>
              <Dropdown
                style={{ backgroundColor: globalTheme.siteVariables.colorScheme.default.background2 }}
                items={assigneeItems}
                search
                fluid
                checkable
                clearable
                className={`assignee-drapdown-field`}
                value={assigneeItems.filter(t => t.selected)}
                searchQuery={searchText}
                onSearchQueryChange={(_e, data) => {
                  setSearchText(data.searchQuery);
                }}
                onChange={(_event, p: any) => {
                  const assigneesArray = assigneeItems.map((item: any) => {
                    if (item.Id == p?.value?.Id) {
                      item.selected = true;
                    } else {
                      item.selected = false;
                    }
                    return item;
                  });
                  setAssigneeItems(assigneesArray);
                  setSearchText(p?.value?.header);
                  setTaskData({
                    ...taskData,
                    AssigneeId: p?.value?.Id,
                    Assignee: p?.value
                  });
                }}
                noResultsMessage={
                  <div style={{ textAlign: 'center' }}>
                    <Text content={LIFECYCLE.USER_NOT_FOUND} />
                  </div>
                }
              />
            </LabelElement>
          </div>
        </div>)} />
    ) 
};
import React, { useEffect, useState } from 'react';
import { Flex, Dialog, CloseIcon, Button } from '@fluentui/react-northstar';
import { CheckLogin } from '../../CheckLogin';
import { TicketTasksForm } from './TicketTasksForm';
import { platformService } from '../../../services/platform.service';
import { Toast } from '../../toast/Toast';
import './TicketTasksDialog.css';
import { getCachedFeature } from '../../../cache/FeatureCache';
import { LICENSE_FEATURE } from '../../../utils/constants';
import { PlatformUser } from '../../../interfaces/platformuser.interface';
import { useTranslation } from 'react-i18next';
import { appState, useSetState } from '../../../../AppState';

interface Props {
  open: boolean;
  close: () => void;
  statuses: TaskStatus[];
  analysts: PlatformUser[];
  ticketId: number;
  ticketTitle: string;
  getAllTicketList: (id: number) => void;
  setIsLoading: (value: boolean) => void;
  ticketTaskDetails: any;
  setShowUpdateBtn: (value: boolean) => void;
  showUpdateBtn: boolean;
  isLifecycle?: boolean;
  reloadPhases?: () => Promise<void>;
  isCurrentPhase?: boolean;
}

interface FormObjectValues {
  Title: string;
  Id?: number;
  StatusId: number;
  AssigneeId: number;
  TicketId?: number;
  Status?: any;
  Assignee?: any
}

const initialProp = {
  Title: null,
  StatusId: undefined,
  AssigneeId: undefined,
  TicketId: undefined
};

export const TicketTasksDialog = ({
  open,
  close,
  statuses,
  analysts,
  ticketId,
  ticketTitle,
  getAllTicketList,
  setIsLoading,
  ticketTaskDetails,
  showUpdateBtn,
  setShowUpdateBtn,
  reloadPhases,
  isLifecycle = false,
  isCurrentPhase = true
}: Props) => {
  const api = new platformService();
  const [formObj, setFormObj] = useState<FormObjectValues>(ticketTaskDetails);
  const [initialFormObj, setInitialFormObj] = useState<FormObjectValues>(ticketTaskDetails);
  const [isFormUpdated, setIsFormUpdated] = useState<boolean>(false);
  const [alert, setAlert] = useState({
    title: '',
    show: false,
    attributes: {}
  });

  const { t } = useTranslation(); 
  const currentState = appState();
  const setAppState = useSetState();

  const renderPages = () => (
    <TicketTasksForm
      statuses={statuses}
      analysts={analysts}
      ticketId={ticketId}
      ticketTitle={ticketTitle}
      setFormObj={setFormObj}
      formObj={formObj}
      alert={alert}
      isCurrentPhase = {isCurrentPhase}
    />
  );

  const onClickCreateTask = async _e => {
    try {
      setIsLoading(true);
      formObj.TicketId = ticketId;
      const data = {
        Title: formObj.Title,
        TicketId: formObj.TicketId,
        StatusId: formObj.Status.Id,
        AssigneeId: formObj.AssigneeId || null
      };
      if (formValidation(formObj)) {
        setAlert({
          title: t('ticket-details.edit-ticket-form.task.dialog.fill-required'),
          show: true,
          attributes: { danger: true }
        });
        return false;
      }
      modalClose();
      await api.createTicketTask(data);
      setFormObj(initialProp);
      getAllTicketList(ticketId);
      Toast.success(t('ticket-details.edit-ticket-form.task.success.create-success'));
    } catch (error) {
      console.log('Error ', error);
      Toast.error(t('ticket-details.edit-ticket-form.task.error.fail-to-create'));
    }
  };

  const onClickUpdateTask = async (_e: any) => {
    try {
      setIsLoading(true);
      const params = `/TicketTask/${formObj.Id}`;
      const data = {
        Title: formObj.Title,
        TicketId: formObj.TicketId,
        StatusId: formObj.StatusId,
        AssigneeId: formObj.AssigneeId || null
      };
      if (formValidation(formObj)) {
        setAlert({
          title: t('ticket-details.edit-ticket-form.task.dialog.fill-required'),
          show: true,
          attributes: { danger: true }
        });
        return false;
      }
      modalClose();
      const isTicketTaskEnabled = await getCachedFeature(LICENSE_FEATURE.TicketTasks);
      if(isTicketTaskEnabled) {
        await api.updateTicketTask(params, data); 
        setFormObj(initialProp);
        
        const newStatus = currentState.taskStatus.filter(x=>x.Id==formObj.StatusId);
        const newAssignee = currentState.platformusers.filter(x=> x.Id==formObj.AssigneeId);
        
        setAppState(prevState => ({
          ...prevState,
          ticketTask: prevState.ticketTask.map(
            task => (task.Id === formObj.Id ? Object.assign(task, { 
              StatusId: formObj.StatusId, 
              Title: formObj.Title, 
              AssigneeId: formObj.AssigneeId,
              Status: newStatus[0],
              Assignee: newAssignee[0],
            }) : task))
        }));


        getAllTicketList(ticketId);
        
        Toast.success(t('ticket-details.edit-ticket-form.task.success.update-success'));
      } else {
        Toast.error(t('ticket-details.edit-ticket-form.task.error.fail-to-update'));
      }
    } catch (error) {
      console.log("Error: ", error);
      Toast.error(t('ticket-details.edit-ticket-form.task.error.fail-to-update'));
    } finally {
      setShowUpdateBtn(false);
      setIsLoading(false);
    }
  };

  const formValidation = (formData: FormObjectValues) => {
    let propertiesList = Object.keys(initialFormObj);
    for (let property of propertiesList) {
      if ((property === "Title" || property === "StatusId") && !formData[property]) {
        return true;
      }
    }
  }

  const modalClose = () => {
    setAlert({ title: '', show: false, attributes: {} });
    close();
  }

  useEffect(() => {
    setFormObj(ticketTaskDetails);
    setInitialFormObj(ticketTaskDetails)
  }, [ticketTaskDetails]);

  useEffect(() => {
    setIsFormUpdated(checkIfFormUpdated());
  }, [formObj]);

  const checkIfFormUpdated = () => {
    let isDirty = false;
    let propertiesList = Object.keys(initialFormObj);

    for (let property of propertiesList) {
      let updatedPropertyValue: any = formObj[property];
      let initialPropetyValue: any = initialFormObj[property];
      if (updatedPropertyValue?.toString() !== initialPropetyValue?.toString()) {
        isDirty = true;
        break;
      }
    }
    return isDirty;
  };

  return (
    <CheckLogin>
      <Dialog
        className={`taskDialog`}
        content={renderPages()}
        open={open}
        headerAction={{
          icon: <CloseIcon />,
          title: t('common.buttons.close'),
          onClick: modalClose
        }}
        footer={
          <Flex gap="gap.small" className={``}>
            <Flex.Item push>
              <Button
                content={t('common.buttons.cancel')}
                onClick={() => {
                  modalClose();
                }}
              />
            </Flex.Item>
            {showUpdateBtn ? (
              <Button
                className="dialog-btn"
                disabled={!isFormUpdated}
                content={t('common.buttons.update')}
                primary
                onClick={onClickUpdateTask}
              />
            ) : (
              <Button
                className="dialog-btn"
                disabled={false}
                content={t('common.buttons.create')}
                primary
                onClick={onClickCreateTask}
              />
            )}
          </Flex>
        }
      />
    </CheckLogin>
  );
};

import React, { useState } from 'react';
import { ThemePrepared, Flex, ChevronEndIcon, ChevronDownIcon, Text } from '@fluentui/react-northstar';
import LifecycleTaskIcon from "../../../../svg/lifecycle-task-icon.svg";
import LifecycleApprovalIcon from "../../../../svg/approvals-lifecycle.svg";
import LifecyclePowerAutomateTaskIcon from "../../../../svg/lifecycle-powerautomate-icon.svg";
import { LIFECYCLE, LIFECYCLE_STATUS_GUID } from '../../utils/constants';
import { useTranslation } from 'react-i18next';

interface Props {
    isAccordionOpen: boolean;
    setIsAccordionOpen: (data: boolean) => void;
    globalTheme: ThemePrepared<any>;
    phaseData: TicketLifecyclePhase;
    lifecycleStatus: LifecycleStatus;
    activePhaseIndex: number;
}

export const TicketLifecyclePhaseAccordion = ({
    isAccordionOpen,
    setIsAccordionOpen,
    globalTheme,
    phaseData,
    lifecycleStatus,
    activePhaseIndex
}: Props) => {

    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const tasksCount = phaseData.Tasks.length;
    const approvalsCount = phaseData.Approvals.length;
    const powerAutomateTasksCount = phaseData.PowerAutomateTasks.length;
    const isLifecycleCompleted = lifecycleStatus.Guid == LIFECYCLE_STATUS_GUID.COMPLETED;
    const isLifecycleFailed = lifecycleStatus.Guid == LIFECYCLE_STATUS_GUID.FAILED;

    const { t } = useTranslation();

    const getExtraClass = () => {
        
        if(phaseData.IsCurrent){
            if(isLifecycleCompleted) return "completed-lifecyle-accordion lifecyle-accordion";
            else if(isLifecycleFailed) return "failed-lifecyle-accordion lifecyle-accordion";
            else return "inprogress-lifecyle-accordion lifecyle-accordion";
        }
    }

  return (
    <>
      <Flex vAlign="center" space="between"
        className={`mt-7 p-4 cursor-pointer ${getExtraClass()} ${isAccordionOpen ? "lifecyle-accordion-open" : ""}`}
        style={{ 
            borderBottom: '1px solid',
            borderBottomColor: isAccordionOpen ? `${globalTheme.siteVariables.colorScheme.brand.foreground1}` : 'transparent',
            backgroundColor: `${phaseData.Order < activePhaseIndex ? 'var(--past-phase-bg)' : 'var(--notstarted-lifecyle-bg)'}`,
            color: 'black'
        }}
        onClick={() => setIsAccordionOpen(!isAccordionOpen)}
      >
        <Flex.Item>
          <Flex gap="gap.small" vAlign="center">
            {isAccordionOpen ? <ChevronEndIcon /> : <ChevronDownIcon />}
            <Flex style={{ fontWeight: 400, fontSize: '16px', lineHeight: '24px', alignItems: "center" }} gap="gap.small">
              <Text weight="semibold" content={phaseData.Name}/>
              {((activePhaseIndex > phaseData.Order && phaseData.LastCompletionDate && !phaseData.IsCurrent) || isLifecycleCompleted) && (
                <Text weight="semibold" size="smaller" className={"text-green-600"} content={<div> {' '}&bull;{' '}<em>Completed</em></div>}/>
              )}
              {(isLifecycleFailed && phaseData.IsCurrent) && (
                <Text weight="semibold" size="smaller" className={"error-pill"} content={<div> {' '}&bull;{' '}<em>Failed</em></div>}/>
              )}
            </Flex>
          </Flex>
        </Flex.Item>
        <Flex.Item>
          <div>
          <Flex>
            {tasksCount > 0 && (
              <Flex.Item>
                <Flex style={{alignItems: 'center'}}>
                  <LifecycleTaskIcon
                    width={14}
                    height={14}
                    className="inline-block"
                  />
                  <div>&nbsp;{tasksCount} {t('ticket-details.edit-ticket-form.task.task')}{tasksCount > 1 ? 's' : ''}</div>
                </Flex>
              </Flex.Item>
            )}
            {approvalsCount > 0 && (
              <Flex.Item>
                <Flex className={`ml-2`} style={{alignItems: 'center'}}>
                  <LifecycleApprovalIcon
                    width={14}
                    height={14}
                    className={`inline-block`}
                  />
                  <div>&nbsp;{approvalsCount} {t('ticket-details.edit-ticket-form.approval.approval')}{approvalsCount > 1 ? 's' : ''}</div>
                </Flex>
              </Flex.Item>
            )}
            {powerAutomateTasksCount > 0 && (
              <Flex.Item>
                <Flex className={`ml-2`} style={{alignItems: 'center'}}>
                  <LifecyclePowerAutomateTaskIcon
                    width={14}
                    height={14}
                    className="inline-block"
                  />
                  <div>&nbsp;{powerAutomateTasksCount} {LIFECYCLE.FLOW}{powerAutomateTasksCount > 1 ? 's' : ''}</div>
                </Flex>
              </Flex.Item>
            )}
          </Flex>
          </div>
        </Flex.Item>
      </Flex>
    </>
  );

};

import React, { useEffect } from 'react';
import { AppStateContext, useSetState } from '../AppState';
import { Nav } from './browser-components/Nav';
import { SideNav } from './browser-components/SideNav';
import { checkInTeams } from '../App';
import { mergeStyleSets } from '@fluentui/react';

const classNames = mergeStyleSets({
  browserMain: {
    maxWidth: 'calc(100% - 221px)'
  },
  browserMainExpanded: {
    maxWidth: 'calc(100% - 48px)'
  }
});

interface Props {
  page: JSX.Element;
  active: string;
  settingsPage?: string;
  hideSettingsNav?: boolean;
}

export const BrowserAppTemplate: React.FC<Props> = (props: Props) => {
  const setState = useSetState();
  useEffect(() => {
    setState(state => {
      var newstate = {
        ...state,
        isLoading: true
      };
      return newstate;
    });
  }, []);

  return (
    <AppStateContext.Consumer>
      {state => {
        return (
          <>
            {checkInTeams() && (
              <>
                <div className={'w-full'}>
                  <div className={'flex'}>
                    <SideNav
                      active={props.active}
                      settingsPage={props.settingsPage}
                      hideSettingsNav={props.hideSettingsNav}
                    />
                    <div className={`flex-grow overflow-hidden max-w-full`}>
                      {props.page}
                    </div>
                  </div>
                </div>
              </>
            )}

            {!checkInTeams() && (
              <>
                <Nav />
                <div className={'w-full'}>
                  <div className={'flex'}>
                    <div className={`hidden md:flex`}>
                      <SideNav
                        active={props.active}
                        settingsPage={props.settingsPage}
                        hideSettingsNav={props.hideSettingsNav}
                      />
                    </div>

                    <div
                      className={`flex-grow overflow-hidden ${
                        state.isSidenavCollapsed
                          ? classNames.browserMainExpanded
                          : classNames.browserMain
                      } max-w-full`}
                    >
                      {props.page}
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        );
      }}
    </AppStateContext.Consumer>
  );
};

import React, { useState, useContext, useEffect } from 'react';
import {
  Flex,
  Divider,
  Text,
  Box,
  SiteVariablesPrepared,
  Button,
  ThemePrepared,
  Tooltip,
} from '@fluentui/react-northstar';
import { useHistory, useLocation } from 'react-router-dom';
import { Route } from '../../../route-constants';
import { MyWorkBoardSvg, MyWorkListSvg } from '../../../svg';
import { ToolbarTheme } from '@fluentui/react-teams/lib/esm/components/Toolbar/ToolbarTheme';
import './myWork.css';
import { FontIcon, Toggle, mergeStyleSets } from '@fluentui/react';
import { DEFAULT_API_STATUS, MyWorkListViewType } from '../../shared/utils/constants';
import useMyWorkListFunctions from './MyWorkList/useMyWorkListFunctions';
import { MyWorkContext } from './MyWorkContextProvider';
import { getSelectedTabFromStorage, getShowClosedFromStorage, setSelectedTabInStorage, setShowClosedInStorage } from './MyWorkList/myWorkListHelper';
import { useTranslation } from 'react-i18next';

const classNames = mergeStyleSets({
  msToggleBtn: {
    marginBottom: '0 !important'
  }
});

interface WorkItemToolbarI {
  globalTheme: ThemePrepared<any>;
  setApiStatus: React.Dispatch<React.SetStateAction<APIStatus>>;
}

const MyWorkToolbar = ({
  globalTheme,
  setApiStatus,
}: WorkItemToolbarI) => {
  const history = useHistory();
  const location = useLocation();
  const { myWorkStates, setMyWorkStates } = useContext(MyWorkContext);
  const { getpaginatedMyWorkList } = useMyWorkListFunctions();
  const isMyGroupWork = location.pathname.includes(Route.myGroupWork.pagePath);
  const isMyGroupWorkListView = location.pathname === Route.myGroupWork.list;
  const isMyWorkListView = location.pathname === Route.myWork.list;
  const { t } = useTranslation();

  const btns = [
    { path: isMyGroupWork ? Route.myGroupWork.list : Route.myWork.list, iconComponent: MyWorkListSvg, label:  isMyGroupWork ? t('my-work.toolbar.group-list') : t('my-work.toolbar.list')},
    { path: isMyGroupWork ? Route.myGroupWork.board : Route.myWork.board, iconComponent: MyWorkBoardSvg, label:  isMyGroupWork ? t('my-work.toolbar.group-board') : t('my-work.toolbar.board') },
  ];

  const [toggleBtns, setToggleBtns] = useState(btns);

  useEffect(() => {
    setToggleBtns([...btns]);
  }, [isMyGroupWork]);
  

  const onClickShowClosedToggle = (checked: boolean) => {
    setShowClosedInStorage(isMyGroupWork, checked);

    setMyWorkStates(prev => ({
      ...prev,
      showClosed: checked,
    }));

    if(isMyWorkListView || isMyGroupWorkListView){
      getpaginatedMyWorkList({ selectedTabNumber: getSelectedTabFromStorage(isMyGroupWork) ?? myWorkStates.myWorkList.selectedTabId, showClosed: checked });
    }
  }
  
  const showClosed = getShowClosedFromStorage(isMyGroupWork) ?? myWorkStates.showClosed;

  const getIconColor = (isSelected: boolean) => {
    return isSelected ? globalTheme.siteVariables.colorScheme.brand.foreground : globalTheme.siteVariables.colorScheme.default.foreground;
  }

  return (
    <ToolbarTheme globalTheme={globalTheme}>
      <Flex vAlign='center'>
        <Flex.Item grow>
          <Box
            variables={({ colorScheme }: SiteVariablesPrepared) => ({
              backgroundColor: colorScheme.default.background2,
            })}
          >
            <>
              <Flex className='justify-between' style={{ height: '48px' }}>
                <Flex>
                  <Flex.Item grow>
                    <>
                      <Text
                        content={isMyGroupWork ? t('my-work.toolbar.my-group-work') : t('my-work.toolbar.my-work')}
                        weight='bold'
                        styles={{
                          marginLeft: '.75rem',
                          marginRight: '.5rem',
                          alignItems: 'center',
                          display: 'inline-flex',
                          height: '48px',
                        }}
                      />
                      <Divider
                        vertical
                        color='gray'
                        style={{
                          height: '40px',
                          marginTop: '4px',
                          marginRight: '.7em',
                        }}
                      />
                      {[Route.myWork.list, Route.myGroupWork.list].includes(location.pathname) ? (
                        <ListFilterTabs
                          {...{ globalTheme }}
                        />
                      ) : (
                        <></>
                      )}
                      <Button
                        text
                        icon={<FontIcon iconName={'Refresh'} />}
                        title={t('my-work.toolbar.refresh')}
                        content={t('my-work.toolbar.refresh')}
                        onClick={() => {
                          setMyWorkStates(prev =>({
                            ...prev,
                            myWorkList: {
                              ...prev.myWorkList,
                              currentPage: 1,
                            }
                          }))
                          setApiStatus(DEFAULT_API_STATUS as APIStatus);
                        }}
                        className={`mt-2`}
                        style={{
                          color:
                            globalTheme.siteVariables.colorScheme.brand
                              .foreground,
                          marginLeft: -10,
                        }}
                      />
                    </>
                  </Flex.Item>
                </Flex>
                <Flex
                  className='space-x-4'
                  styles={{
                    marginRight: '16px',
                    marginLeft: '4px',
                    alignItems: 'center',
                    display: 'inline-flex',
                  }}
                >
                  <Toggle
                    label={t('my-work.toolbar.show-closed')}
                    styles={{
                      label: {
                        color: globalTheme.siteVariables.colorScheme.default.foreground,
                        wordBreak: 'normal !important'
                      },
                    }}
                    inlineLabel
                    onChange={(_ev: React.MouseEvent<HTMLElement>, checked?: boolean) => { onClickShowClosedToggle(checked); }}
                    {...(showClosed && { 
                      defaultChecked: true,
                      style: {
                        background:
                          globalTheme.siteVariables.colorScheme.brand
                            .foreground,
                        opacity: 1,
                      },
                    })}
                    className={`${classNames.msToggleBtn} mobile-wordWrap`}
                    checked={showClosed}
                  />
                  <Divider
                    vertical
                    color='gray'
                    style={{
                      height: '40px',
                      marginTop: '4px',
                      marginRight: '.7em',
                    }}
                  />
                  {toggleBtns.map((value) => {
                    const matchedpath = location.pathname === value.path;
                    return (
                      <Tooltip
                        content={value.label}
                        trigger={
                          <div
                            className={`cursor-pointer`}
                            onClick={() => history.push(value.path)}
                          >
                            <value.iconComponent style={{ fill: getIconColor(matchedpath) }} />
                          </div>
                        }
                      />
                      
                    );
                  })}
                </Flex>
              </Flex>
            </>
          </Box>
        </Flex.Item>
      </Flex>
    </ToolbarTheme>
  );
};

export default MyWorkToolbar;

interface IListFilterTabsProps {
  globalTheme: ThemePrepared<any>;
}
interface IListFilterTabs {
  title: string;
  value: string;
  count_rows: number;
  selected: boolean;
}

const ListFilterTabs = ({
  globalTheme
}: IListFilterTabsProps) => {
  const { foreground, borderActive } =
    globalTheme.siteVariables.colorScheme.brand;
    const { t } = useTranslation();

  const { getpaginatedMyWorkList } = useMyWorkListFunctions();
  const { myWorkStates, setMyWorkStates } = useContext(MyWorkContext);
  const isMyGroupWork = location.pathname.includes(Route.myGroupWork.pagePath);

  const titleObj = {
    [MyWorkListViewType.All]: t('my-work.toolbar.tabs.all'),
    [MyWorkListViewType.Tickets]: t('my-work.toolbar.tabs.tickets'),
    [MyWorkListViewType.Tasks]: t('my-work.toolbar.tabs.tasks'),
    [MyWorkListViewType.Approvals]: t('my-work.toolbar.tabs.approvals'),
  }

  const listBtnsTitle = Object.values(MyWorkListViewType)
    .filter((value) => typeof value === 'string')
    .map((value) => value.toString());
  const [listFilterBtns, setListFilterBtns] = useState<IListFilterTabs[]>(
    listBtnsTitle.map((value) => ({
      title: titleObj[MyWorkListViewType[value]],
      value: value,
      count_rows: 0,
      selected: false,
    }))
  );

  const onClickFilterTab = (selectedTab: IListFilterTabs) => {
    setSelectedTabInStorage(isMyGroupWork, MyWorkListViewType[selectedTab.value]);

    setListFilterBtns((prev) => {
      return prev.map((filterBtn) =>
        filterBtn.value === selectedTab.value
          ? { ...filterBtn, selected: true }
          : { ...filterBtn, selected: false }
      );
    });

    setMyWorkStates(prev => ({
      ...prev,
      sortOrder: {
        ...prev.sortOrder,
      },
      myWorkList: {
        ...prev.myWorkList,
        selectedTabId: MyWorkListViewType[selectedTab.value],
        currentPage: 1,        
      }
    }));
    getpaginatedMyWorkList({ selectedTabNumber: MyWorkListViewType[selectedTab.value] });
  };

  return (
    <>
      {listFilterBtns.map((tabFilter) => {
        const selected = MyWorkListViewType[getSelectedTabFromStorage(isMyGroupWork) ?? myWorkStates.myWorkList.selectedTabId] === tabFilter.value;

        return (
          <div
            className='list_filter_btn'
            style={{
              color: selected ? foreground : globalTheme.siteVariables.colorScheme.default.foreground,
              ...(selected && {
                borderBottom: `2px solid ${foreground}`,
              }),
            }}
            onClick={() => onClickFilterTab(tabFilter)}
          >
            {tabFilter.title}
            <span
              className='list_filter_btn_badge'
              style={{ backgroundColor: borderActive, opacity: selected ? 1 : 0.7 }}
            >
              {myWorkStates.myWorkList.dataCount[MyWorkListViewType[tabFilter.value]]}
            </span>
          </div>
        );
      })}
      <Divider
        vertical
        color='gray'
        style={{
          height: '40px',
          marginTop: '4px',
          marginRight: '.7em',
        }}
      />
    </>
  );
};

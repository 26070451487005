
import html2markdown from '@notable/html2markdown';
import { cloneDeep } from 'lodash';
import { platformService } from '../services/platform.service';
import { ARRANGED_SETTING_KEYS, SETTINGS_PAGES } from '../utils/constants';


const toObject = <T>(value: string) : T => {
    let objectParsed = {} as T;

    try {
        objectParsed =  JSON.parse(value) as T;
    } catch {
        objectParsed = {} as T;
    }

    return objectParsed;
};

export const hexToRgbA = (hex: string, opacity: string) => {
    var c;
    if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
        c= hex.substring(1).split('');
        if(c.length== 3){
            c= [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c= '0x'+c.join('');
        return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+`,${opacity})`;
    }
    throw new Error('Bad Hex');
}


export const CommonHelper = {
    toObject: toObject
}

export const getBodyHeight = (isInTeams: boolean) => {
    if (isInTeams) {
        return "calc(100vh - 52px)";
    } else {
        return "calc(100vh - 110px)"
    }
}



export const getTicketTokenMentionList = async (props: MentionProps, reloadTicketPropertyList=false) => {
    if (reloadTicketPropertyList){
        const api = new platformService();
        const {data:{value}} = await api.getEntityProperties()
        props.ticketPropertyList = value;
    }
    else if (props.ticketPropertyList==null){
        props.ticketPropertyList=[];
    }

    let suggestions = [
        { text: 'Requester First Name', value: '{Requester First Name}}', url: '{Requester.FirstName}}' },
        { text: 'Requester Last Name', value: '{Requester Last Name}}', url: '{Requester.LastName}}' },
        { text: 'Latest Comment', value: '{Latest Comment}}', url: '{LatestComment}}' },
        { text: 'Request', value: '{Request}}', url: '{Request}}' },
        { text: 'Ticket Id', value: '{Ticket Id}}', url: '{TicketId}}' },
        { text: 'Template Name', value: '{Template Name}}', url: '{TemplateName}}' },
        { text: 'Group', value: '{Group}}', url: '{Group}}' }
    ];

    if (props.additionSuggestions!=null){
        props.additionSuggestions.forEach((item:any)=>{
            suggestions.push({ text: item.text, value: `{${item.text}}}`, url: `{${item.value}}}` })
        });
    }

    if (props.ticketPropertyList!=null){
        props.ticketPropertyList.forEach((item:any) => {
            let text = item.replace(/([A-Z][a-z])/g,' $1').replace(/(\d)/g,' $1');
            if (text.startsWith(" ")) text = text.substring(1);
            suggestions.push({ text: text, value: `{${text}}}`, url: `{${item}}}` });
        });
    }

    suggestions.push({text: "Agent URL", value:'{Agent URL}}',url:'{AgentURL}}'});
    suggestions.push({text: "Requester URL", value:'{Requester URL}}',url:'{RequesterURL}}'});

    let mention = {
        separator: ' ',
        trigger: '{',
        suggestions: suggestions.sort((a, b) => a.text < b.text ? -1 : a.text > b.text ? 1 : 0)
    }

    return mention;

}

export const ConvertTokenValue = (suggestions: MensionSuggestion[], value:string) => {
    let temp = JSON.stringify(html2markdown(value));
    temp = temp.substring(1,temp.length-1);
    return temp;
}

export const GetArrangeSettingsKey = (isNotificationCenter) => {
    let cloneKeys = cloneDeep(ARRANGED_SETTING_KEYS);
    if (!isNotificationCenter){
        let index = cloneKeys.findIndex(x=>x==SETTINGS_PAGES.NOTIFICATION_CENTER)
        if (index>=0){
            cloneKeys.splice(index,1,SETTINGS_PAGES.EMAIL_CONNECTOR);
        }
    }
    return cloneKeys;
}
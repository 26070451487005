import React, { useState } from 'react';
import Styles from '../styles/Lifecycle.module.css';
import { Flex, Button, AddIcon, ThemePrepared, Grid } from '@fluentui/react-northstar';
import { cloneDeep } from 'lodash';
import { PhaseTask } from './PhaseTask';
import { KeyValuePair, LifecycleTask, RelationshipData } from '../utils/typings';
import { PhaseTaskDialog } from './PhaseTaskDialog';
import { getNewGuid } from '../utils/helper';
import { PlatformUser } from '../../shared/interfaces/platformuser.interface';
import { LIFECYCLE } from '../../../components/shared/utils/constants'

interface Props {
  data: LifecycleTask[];
  updateData: (data: LifecycleTask[]) => void;
  globalTheme: ThemePrepared<any>;
  phaseData: RelationshipData;
  userList: PlatformUser[];
  showHeading?: boolean;
}

export const PhaseTaskSection = ({
  data,
  updateData,
  globalTheme,
  phaseData,
  userList,
  showHeading
}: Props) => {
  const [openDialog, setOpenDialog] = useState(false);


  const initialTaskData: LifecycleTask = {
    Id: 0,
    Name: '',
    Guid: '',
    LifecyclePhaseId: phaseData.Id,
    AssigneeId: null,
    Assignee: null,
  }

  let dataClone = cloneDeep(data);

  const updateTaskData = (editData: KeyValuePair[], index: number) => {
    editData.forEach(pair => {
      if(pair.key == "RemoveItem")
        dataClone.splice(index, 1);
      else
        dataClone[index][pair.key] = pair.value;
    });    
    updateData(dataClone);
  }

  const saveNewTask = (newTask: LifecycleTask) => {
    newTask.Guid = getNewGuid();
    data.push(newTask);
    updateData(data);
  }

  let taskAndapprovalBorderStyle = 'mb-4 mt-4';
  if(showHeading){
      taskAndapprovalBorderStyle = Styles.taskAndapprovalBorder;
  }

  return (
    <>
     <div className={taskAndapprovalBorderStyle}>
        <Flex space={`between`} vAlign={'center'}>
            <Flex.Item><h2 className={Styles.SubHeading2} style={{marginBottom: '0px'}}>{LIFECYCLE.TASKS}</h2></Flex.Item>
          <Flex.Item>
            <Button
              icon={<AddIcon 
                  styles={{color: globalTheme.siteVariables.colorScheme.brand.foreground}} 
                  />}
              text
              primary
              content={<span 
                  style={{color: globalTheme.siteVariables.colorScheme.brand.foreground}}
                  >{LIFECYCLE.ADD_TASK}</span>}
                onClick={() => {
                  setOpenDialog(true);
                }}
              />
          </Flex.Item>
        </Flex>
        <div className={`${Styles.TicketsContainer}`}>
          <Grid columns={data.length === 1 ? 1: 2}>
            {data.map((item, index) => {
              return (
                <div key={item.Id}>
                  <PhaseTask 
                    data={item}
                    dataIndex={index}
                    updateData={updateTaskData}
                    globalTheme={globalTheme}
                    userList={userList}
                  />
                </div>)
            })}
          </Grid>
        </div>
        <PhaseTaskDialog
                open={openDialog}
                onCancel={() => setOpenDialog(false)}
                saveTask={saveNewTask}
                initData={initialTaskData}
                userList={userList}
            />        
    </div>
    </>
  );

};

import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import * as microsoftTeams from "@microsoft/teams-js";
import { Divider, ProviderConsumer as FluentUIThemeConsumer, Button } from '@fluentui/react-northstar';
import { Communication } from '@fluentui/react-teams';
import _ from 'lodash';
import { platformService } from '../../shared/services/platform.service';
import { ConsentState, SignedInConsentState } from '../../shared/services/signInState.service';
import MyWorkBoard from './MyWorkBoard/MyWorkBoard';
import { SkeletonBoard, SkeletonMyyWorkHeader, SkeletonTable } from '../../shared/components/Skeleton';
import { Providers } from '@microsoft/mgt-element/dist/es6/providers/Providers';
import { BrowserAppTemplate } from '../../browser-pages/BrowserAppTemplate';
import { DEFAULT_API_STATUS, DONE, ERROR, LOADING, ADMINISTRATORS, AGENTS, ANALYSTS, UNAUTHORIZED_ACCESS,SOMETHING_WENT_WRONG, CONTACT_ADMIN, LICENSE_FEATURE } from '../../shared/utils/constants';
import { Helmet } from 'react-helmet';
import { CheckLogin } from '../../shared/components/CheckLogin';
import { mergeStyleSets } from '@fluentui/react';
import { checkInTeams } from '../../App';
import { appState, useSetState } from '../../AppState';
import { Route } from '../../../route-constants';
import MyWorkList from './MyWorkList/MyWorkList';
import MyWorkToolbar from './MyWorkToolbar';
import { loadFilters } from '../ticketHelper';
import { graphService } from '../../shared/services/graph.service';
import { TicketProp } from '../../shared/components/table/TicketListBoard';
import MyWorkContextProvider, { DEFAULT_MY_WORK_VALUES, MyWorkContext } from './MyWorkContextProvider';
import { setMyWorkLastPagePathInStorage } from './MyWorkList/myWorkListHelper';
import './myWork.css'
import { useTranslation } from 'react-i18next';
import { UpgradeNeeded } from '../../shared/components/UpgradeNeeded';
import { getCachedFeature } from '../../shared/cache/FeatureCache';
import { PlatformUser } from '../../shared/interfaces/platformuser.interface';

const classNames = mergeStyleSets({
  Padding0: {
    padding: '0px !important'
  },
  tikitBoardBody: {
    height: 'calc(100% - 60px) !important',
    top: '-18px',
    position: 'relative',

    '.details-list-table .ms-DetailsHeader-cell': {
        paddingLeft: '28px !important'
    }
  }
});

// css won't work on nested shadow root, by doing this it will allow us to change the
// style of nested shadow root elements.

(function retryNotExist() {
  if (!document.querySelector('mgt-tasks')) {
    return setTimeout(retryNotExist);
  }

  let mgtTaskSelector = document.querySelector('mgt-tasks').shadowRoot;
  mgtTaskSelector
    .querySelectorAll('.TaskTitle')
    .forEach(x =>
      x.setAttribute(
        'style',
        'text-overflow: ellipsis;overflow: hidden;white-space: initial;'
      )
    );

  if (!mgtTaskSelector.querySelector('mgt-arrow-options')) {
    return setTimeout(retryNotExist);
  }

  let mgtArrowSelector =
    mgtTaskSelector.querySelector('mgt-arrow-options').shadowRoot;

  mgtArrowSelector
    .querySelector('span.Header')
    .setAttribute(
      'style',
      '--theme-primary-color: var(--mgt-theme-background-brand-hover)'
    );

  let mgtMenuArrowSelector =
    mgtTaskSelector.querySelector('mgt-arrow-options').shadowRoot;

  let menuOption = mgtMenuArrowSelector.querySelector(
    'div.Menu > div.MenuOption .CurrentValue'
  );
  menuOption.setAttribute(
    'style',
    'color: var(--mgt-theme-background-brand-hover)'
  );
})();

export const MyWork = () => {
  const api = new platformService();
  const currentState = appState();
  const setState = useSetState();
  const graphAPI = new graphService();
  const [isSignedIn] = SignedInConsentState.useIsSignedIn();
  const history = useHistory();
  const [apiStatus, setApiStatus] = useState<APIStatus>(DEFAULT_API_STATUS as APIStatus);
  const [isWorkEnabled, setWorkEnabled] = useState<boolean>(true);
  const [isGroupWorkEnabled, setGroupEnabled] = useState<boolean>(true);
  const { setMyWorkStates } = useContext(MyWorkContext);
  const { t } = useTranslation();

  const errConfig = {
    fields: {
      title:
        apiStatus.errCode === 401
          ? UNAUTHORIZED_ACCESS
          : SOMETHING_WENT_WRONG,
      desc: CONTACT_ADMIN
    }
  };

  const getPropsFromSate = () => {
    return {
      platformusers: currentState.platformusers,
      ticketStatus: currentState.ticketStatus,
      priority: currentState.priority,
      categories: currentState.categories,
      taskStatus: currentState.taskStatus
    };
  }

  const getTicketsList = useCallback(async () => {
    try {
      const [enabled, groupEnabled] = await Promise.all([getCachedFeature(LICENSE_FEATURE.MyWork), getCachedFeature(LICENSE_FEATURE.MyGroupWork)]);
      setWorkEnabled(enabled);
      setGroupEnabled(groupEnabled);

      const result = (currentState?.ticketStatus?.length > 0) ? getPropsFromSate() : await loadFilters(api, graphAPI, currentState, setState);

      setApiStatus({ ...apiStatus, status: DONE });
    } catch (error) {
      setApiStatus({
        ...apiStatus,
        status: ERROR,
        errCode: error['response']?.status
      });
    }
  }, [isSignedIn]);

  useEffect(() => {
    if (isSignedIn === ConsentState.SignedIn && apiStatus.status === LOADING) {
      const hasAccess = currentState.userRoles.roles.includes(ADMINISTRATORS) || currentState.userRoles.roles.includes(ANALYSTS) || currentState.userRoles.roles.includes(AGENTS);
      if(!hasAccess) { 
        setApiStatus({ ...apiStatus, status: ERROR, errCode: 401 }) }
      else{
        microsoftTeams.app.getContext().then((context: microsoftTeams.app.Context) => {
          const ticketId = context.page.subPageId;
          if (ticketId) history.push(`/tickets/${ticketId}`);
        });

        getTicketsList();
      }
    }
  }, [isSignedIn, apiStatus.status]);

  const isBoardView = [Route.myWork.board, Route.myGroupWork.board].includes(location.pathname);
  const isMyGroupWork = location.pathname.includes(Route.myGroupWork.pagePath);

  useEffect(() => {
    setMyWorkLastPagePathInStorage(isMyGroupWork, location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    return () => {
      setApiStatus(DEFAULT_API_STATUS  as APIStatus);
      setMyWorkStates(DEFAULT_MY_WORK_VALUES);
    }
  }, [isMyGroupWork]);

  const screenHeight = isBoardView ? 'height--40' : 'height--45'
  
  return (
    <CheckLogin>
      <FluentUIThemeConsumer
        render={globalTheme => (
          <>
            <Helmet>
              <title>{isMyGroupWork ? t('tab-title.my-group-work') : t('tab-title.my-work')} - {t('tab-title.postfix')}</title>
            </Helmet>
            <div className={!checkInTeams() ? screenHeight : `h-screen`}>
              {apiStatus.status === LOADING ? (
                <>
                  <SkeletonMyyWorkHeader />
                  <Divider size={1} className={classNames.Padding0} />
                  <PageLoader board={isBoardView} />
                </>
              ) : !isGroupWorkEnabled && isMyGroupWork ? (
                <UpgradeNeeded toolbarText={t('tab-title.my-group-work')} headerText={t('my-group-work.access.no-access')} subheaderText={t('my-group-work.access.upgrade-plan')} />
              ) : !isWorkEnabled && !isMyGroupWork ? (
                <UpgradeNeeded toolbarText={t('tab-title.my-work')} headerText={t('my-work.access.no-access')} subheaderText={t('my-work.access.upgrade-plan')} /> 
              ) : apiStatus.status === ERROR ? (
                <>
                  <Communication {...errConfig} />
                  {apiStatus.errCode === 401 && (<Button content="Refresh Session" primary onClick={_e => { Providers.globalProvider.login(); }} />)}
                </>
              ) : (
                <>
                  <MyWorkToolbar {...{ globalTheme, setApiStatus }}/>
                  <div className={classNames.tikitBoardBody}>
                    <Divider size={1} className={classNames.Padding0} />
                    {isBoardView ? (<MyWorkBoard />) : (<MyWorkList />)}
                  </div>
                </>
              )}
            </div>
          </>
        )}
      />
    </CheckLogin>
  );
};

export const MyWorkPage = () => {
  const location = useLocation();
  const isMyGroupWork = location.pathname.includes(Route.myGroupWork.pagePath);
  
  return (
    <MyWorkContextProvider>
      <BrowserAppTemplate page={<MyWork />} active={isMyGroupWork ? 'mygroupwork' : 'mywork'} hideSettingsNav={true}/>
    </MyWorkContextProvider>
  )
};

const PageLoader = ({ board }: { board: boolean }) => {
  return board ? <SkeletonBoard /> : <SkeletonTable />;
};

export interface MyWorkListDataI extends Omit<TicketProp, 'Status'> {
  ItemType: MyWorkListDataType;
  TicketTitle: string;
  TypeName: string;
  TypeId: number;
  Status: { Id: number, Value: string };
  LastModified?: string;
  LifecycleName: string;
  LifecyclePhaseName:  string;
  LifeCycleStatus: LifecycleStatus;
  TicketId: number;
}

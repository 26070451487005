import React, { useEffect, useState } from 'react';
import { ChevronDownIcon, ChevronEndIcon, Flex } from '@fluentui/react-northstar';
import { ApprovalsDialog } from './ApprovalsDialog';

import ApprovalsIcon from '../../../../svg/add-approvals.svg';
import './approvals-ticket.css';
import { PlatformUser } from '../../interfaces/platformuser.interface';
import { useHistory } from 'react-router-dom';
import { appState, useSetState } from '../../../AppState';
import { MyWorkListViewType } from '../../utils/constants';
import { useTranslation } from 'react-i18next';
interface Props {
    ticketApprovalsCount:number;
    isApprovalsOpen: boolean;
    isFormDisabled: boolean;
    primaryButtonColor: string;
    setIsApprovalsOpen: (value: boolean) => void;
    addTicketApprovals?: (ticketApprovalsData: Approval) => void;
    setApproversList?: (value: PlatformUser[]) => void;
    approversList?: PlatformUser[];
    isEndUser?: boolean;
    isLoading?: boolean;
}

export const EditTicketApprovals = ({
    ticketApprovalsCount,
    isApprovalsOpen,
    isFormDisabled,
    primaryButtonColor,
    setIsApprovalsOpen,
    addTicketApprovals,
    setApproversList,
    approversList = [],
    isEndUser = false,
    isLoading = false
}: Props) => {
    const {t} = useTranslation();
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const currentState = appState();
    const setAppState = useSetState();
      
    const isDisabled = () => isLoading || isFormDisabled;
    
    const onAddApprovalClick = (e: any) => {
        if (!isDisabled() && !isEndUser) {
            e.stopPropagation();
            setOpenDialog(true);
        }
    }

    useEffect(() => {
        const { isRouteFromMyWork, selectedTaskOrApproval } = currentState;

        if (isRouteFromMyWork && selectedTaskOrApproval?.type === MyWorkListViewType.Approvals) {
            setAppState((prev) => ({ ...prev, isRouteFromMyWork: false }));
            setIsApprovalsOpen(true);
        }
    }, []);

    return (
        <>
            <Flex vAlign="center" space="between"
                className={`mt-7 pb-4 cursor-pointer`}
                style={{ borderBottom: isApprovalsOpen ? `1px solid ${primaryButtonColor}` : 'none' }}
                onClick={() => setIsApprovalsOpen(!isApprovalsOpen)}
            >
                <Flex gap="gap.small" vAlign="center">
                    {isApprovalsOpen ? <ChevronEndIcon /> : <ChevronDownIcon />}
                    <Flex className="font-normal text-base">
                        {t('ticket-details.edit-ticket-form.approval.title')} ({ticketApprovalsCount ? ticketApprovalsCount : 0})
                    </Flex>
                </Flex>
                {(!isEndUser && isApprovalsOpen) && (
                    <div className={`hidden md:flex items-center ${!isDisabled() ? 'approvals-active' : 'approvals-disabled'}`}
                        onClick={e => onAddApprovalClick(e)}>
                        <ApprovalsIcon className="add-approvals"/>&nbsp; {t('ticket-details.edit-ticket-form.approval.add-approval')}
                    </div>
                )}
            </Flex>
            {!isEndUser && <ApprovalsDialog
                open={openDialog}
                onCancel={() => setOpenDialog(false)}
                addTicketApprovals={addTicketApprovals}
                approversList={approversList}
                setApproversList={setApproversList}
            />}
        </>
    );
};
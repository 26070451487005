import React, { useEffect, useState } from "react";
import { Flex, Dialog, CloseIcon, Button, Alert } from "@fluentui/react-northstar";
import { ApprovalsForm } from "./ApprovalsForm";
import { PlatformUser } from "../../interfaces/platformuser.interface";
import { TicketApprovalState } from "../../utils/constants";
import { useTranslation } from 'react-i18next';

interface Props {
  open: boolean;
  onCancel: () => void;
  addTicketApprovals: (ticketApprovalsData: Approval) => void;
  approversList: PlatformUser[];
  setApproversList: (value: PlatformUser[]) => void;
}
export const ApprovalsDialog = ({ open, onCancel, addTicketApprovals, approversList, setApproversList }: Props) => {
  const {t} = useTranslation();
  const [ticketApprovalData, setTicketApprovalData] = useState<Approval>({
    Id: 0,
    AdditionalDetails: '',
    Title: '',
    IsRequiredByAll: null,
    Approvers: [],
    TicketApprovals: [],
    ApprovalState:TicketApprovalState.Pending
  });
  
  const closeDialog = () => {
    setAlert({ title: '', show: false, attributes: {} });
    setTicketApprovalData({
      Id: 0,
      AdditionalDetails: '',
      Title: '',
      IsRequiredByAll: null,
      Approvers: [],
      TicketApprovals: [],
      ApprovalState:TicketApprovalState.Pending
    });
    setApproversList([]);
    onCancel();
  }
  const [alert, setAlert] = useState({
    title: '',
    show: false,
    attributes: {}
  });
  const validateRequired = () => {
    setAlert({ title: '', show: false, attributes: {} });
    if (ticketApprovalData.Title  === '' || approversList.length==0) {
      setAlert({
        title: t('ticket-details.edit-ticket-form.approval.error.field-required'),
        show: true,
        attributes: { danger: true }
      });
      return true;
    }
    return false;
  };
  const primaryClick = async () => {
    if (validateRequired()) return;
    saveData();
    onCancel();
  }
  useEffect(() => {
    if (open) {
      setAlert({ title: '', show: false, attributes: {} });
    }
  }, [open]);
  
  const renderPages = () => {
    return <ApprovalsForm
      ticketApprovalData={ticketApprovalData}
      setTicketApprovalData={setTicketApprovalData}
      setApproversList={setApproversList}
    />
  }

  const saveData = () => {
    addTicketApprovals(ticketApprovalData);
    setTicketApprovalData({
      Id: 0,
      AdditionalDetails: '',
      Title: '',
      IsRequiredByAll: null,
      Approvers: [],
      TicketApprovals: [],
      ApprovalState:TicketApprovalState.Pending
    });
  }

  return (
    <Dialog className="relate-ticket-dialog"
      content={renderPages()}
      open={open}
      headerAction={{
        icon: <CloseIcon />,
        title: t('ticket-details.edit-ticket-form.approval.dialog.button.close'),
        onClick: closeDialog
      }}
      header={{
        content: (
          <div className="w-full">
            <h3 className="mt-0">{t('ticket-details.edit-ticket-form.approval.add-approval')}</h3>
            {alert.show && (
              <Alert
                fitted
                {...alert.attributes}
                content={alert.title}
                className="w-full text-xs"
              />
            )}
          </div>
        ),        
      }}
      footer={
        <Flex gap="gap.small">
          <>
            <Flex.Item push>
              <Button content={t('ticket-details.edit-ticket-form.approval.dialog.button.cancel')} onClick={closeDialog} />
            </Flex.Item>
            <Button className="dialog-btn"
              content={t('ticket-details.edit-ticket-form.approval.dialog.button.add')}
              primary onClick={primaryClick}
            />
          </>
        </Flex>
      }
    />
  )
};